// src/components/StatusTag.jsx

import React from "react";
import { Tag } from "antd";
import PropTypes from "prop-types";
import { getStatusColor } from "../utils/getModuleStatus";

const StatusTag = ({ status }) => {
  const color = getStatusColor(status);
  return <Tag color={color}>{status}</Tag>;
};

StatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusTag;
