// Reminders.jsx
import React, { useMemo, useState, useEffect } from "react";
import { useReminders } from "../../Contexts/useSpecificData";
import { useUser } from "@clerk/clerk-react";
import {
  Card,
  Switch,
  DatePicker,
  Typography,
  Divider,
  Timeline,
  Collapse,
  Tag,
  Row,
  Col,
  Descriptions,
  Badge,
  Button,
  Input,
  Select,
  message,
} from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
import HeaderText from "../../Styled/HeaderText";
import { updateById } from "../../Functions/updateById";
import { TABLES, ARIA_MODULES } from "../../constants";
import { SearchInput } from "../../Styled/SearchInput";

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

// Create role-to-module mapping dynamically from ARIA_MODULES
const roleModuleMap = Object.fromEntries(
  Object.values(ARIA_MODULES).map(({ role, module }) => [role, module])
);

// Helper functions
const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatType = (string) => {
  if (!string) return "";
  const result = string.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

// Separate ReminderCard component
function ReminderCard({ reminder, refreshReminders, userName }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedNotes, setEditedNotes] = useState(reminder.notes || "");

  // Local state for 'active' and 'date'
  const [localActive, setLocalActive] = useState(reminder.active);
  const [localDate, setLocalDate] = useState(
    reminder.date ? dayjs(reminder.date, "MM/DD/YYYY") : null
  );

  // Debounced update function
  const debouncedUpdateReminder = useMemo(
    () =>
      debounce(async (reminderId, changes, revertChanges) => {
        try {
          // Prepare history entry
          const historyEntry = {
            date: dayjs().format("MM/DD/YYYY HH:mm:ss"),
            user: userName,
            changes: {},
          };

          // Record old values
          if ("date" in changes) {
            historyEntry.changes.date = {
              old: reminder.date,
              new: changes.date,
            };
          }
          if ("active" in changes) {
            historyEntry.changes.active = {
              old: reminder.active,
              new: changes.active,
            };
          }
          if ("notes" in changes) {
            historyEntry.changes.notes = {
              old: reminder.notes || "",
              new: changes.notes || "",
            };
          }

          // Update the reminder's history
          const updatedHistory = reminder.history
            ? [...reminder.history, historyEntry]
            : [historyEntry];

          // Update the reminder in the backend
          await updateById(
            TABLES.REMINDERS,
            { ...changes, history: updatedHistory },
            reminder.id
          );

          // Refresh reminders to fetch the latest data
          await refreshReminders();
          message.success("Reminder updated successfully.");
        } catch (error) {
          console.error("Error updating reminder:", error);
          message.error("Failed to update reminder.");
          if (revertChanges) {
            // Revert Local State if Update Fails
            revertChanges();
          }
        }
      }, 500),
    [reminder, userName, refreshReminders]
  );

  // Clean up debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedUpdateReminder.cancel();
    };
  }, [debouncedUpdateReminder]);

  // Handle Toggle Event with Optimistic UI Update
  const handleToggleActive = (checked) => {
    const previousState = localActive;
    setLocalActive(checked);
    debouncedUpdateReminder(reminder.id, { active: checked }, () => {
      setLocalActive(previousState);
    });
  };

  // Handle Date Change with Optimistic UI Update
  const handleDateChange = (date) => {
    const newDate = date ? date.format("MM/DD/YYYY") : null;
    const previousDate = localDate;
    setLocalDate(date);
    debouncedUpdateReminder(reminder.id, { date: newDate }, () => {
      setLocalDate(reminder.date ? dayjs(reminder.date, "MM/DD/YYYY") : null);
    });
  };

  const handleSaveNotes = () => {
    debouncedUpdateReminder(reminder.id, { notes: editedNotes });
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditedNotes(reminder.notes || "");
    setIsEditing(false);
  };

  return (
    <Col key={reminder.id} span={8}>
      <Card
        bodyStyle={{ padding: 0 }}
        title={
          <>
            <Tag color="geekblue">{capitalizeFirstLetter(reminder.module)}</Tag>
            <Tag color="blue">{formatType(reminder.type)}</Tag>
          </>
        }
        extra={
          <Switch
            checked={localActive}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={handleToggleActive}
          />
        }
      >
        <div style={{ padding: 16 }}>
          <Text strong>Reminder Date:</Text>{" "}
          <DatePicker
            value={localDate}
            format="MM/DD/YYYY"
            onChange={handleDateChange}
            style={{ width: "100%", marginTop: 8 }}
          />
        </div>
        <Descriptions bordered column={1} size="small" style={{ margin: 16 }}>
          {reminder.body &&
            reminder.body.map((item, idx) => (
              <Descriptions.Item key={idx} label={item.key}>
                {item.value}
              </Descriptions.Item>
            ))}
        </Descriptions>

        <div style={{ padding: 16 }}>
          <Text strong>Notes:</Text>
          {!isEditing ? (
            <div>
              <Text>{reminder.notes || "No Notes Available"}</Text>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => setIsEditing(true)}
                style={{ paddingLeft: 0 }}
              />
            </div>
          ) : (
            <div>
              <Input.TextArea
                value={editedNotes}
                onChange={(e) => setEditedNotes(e.target.value)}
                rows={3}
                placeholder="Enter notes here..."
                style={{ marginTop: 8 }}
              />
              <Button
                type="link"
                icon={<CheckOutlined />}
                onClick={handleSaveNotes}
              >
                Save
              </Button>
              <Button
                type="link"
                icon={<CloseOutlined />}
                onClick={handleCancelEdit}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>

        {reminder.history && reminder.history.length > 0 && (
          <>
            <Divider />
            <div style={{ padding: 16 }}>
              <Collapse>
                <Panel header="History" key="1">
                  <Timeline style={{ marginTop: 16 }}>
                    {reminder.history.map((entry, index) => (
                      <Timeline.Item key={index}>
                        <Text>
                          {dayjs(entry.date, "MM/DD/YYYY HH:mm:ss").format(
                            "MM/DD/YYYY HH:mm:ss"
                          )}{" "}
                          - {entry.user}
                        </Text>
                        <ul style={{ margin: 0, paddingLeft: 20 }}>
                          {Object.entries(entry.changes).map(
                            ([field, change], idx) => (
                              <li key={idx}>
                                <Text>
                                  <strong>
                                    {capitalizeFirstLetter(field)}
                                  </strong>{" "}
                                  changed from <em>{String(change.old)}</em> to{" "}
                                  <em>{String(change.new)}</em>
                                </Text>
                              </li>
                            )
                          )}
                        </ul>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </Panel>
              </Collapse>
            </div>
          </>
        )}
      </Card>
    </Col>
  );
}

function Reminders() {
  // Fetch data using custom hooks
  const { data: reminders = [], refresh: refreshReminders } = useReminders();
  const { user } = useUser();

  // Extract user information and roles
  const roles = user?.publicMetadata?.roles || [];
  const userName =
    (user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.emailAddress) || "Unknown User";

  // Determine modules accessible by the user based on roles
  const userModules = useMemo(
    () => roles.map((role) => roleModuleMap[role]).filter(Boolean),
    [roles]
  );

  // Filter reminders by user's modules
  const userReminders = useMemo(
    () => reminders.filter((reminder) => userModules.includes(reminder.module)),
    [reminders, userModules]
  );

  // Split reminders into active and inactive subsets
  const [activeReminders, inactiveReminders] = useMemo(() => {
    const active = [];
    const inactive = [];
    userReminders.forEach((reminder) => {
      if (reminder.active) {
        active.push(reminder);
      } else {
        inactive.push(reminder);
      }
    });
    return [active, inactive];
  }, [userReminders]);

  // Local state for immediate UI updates
  const [localActiveReminders, setLocalActiveReminders] =
    useState(activeReminders);
  const [localInactiveReminders, setLocalInactiveReminders] =
    useState(inactiveReminders);

  // Update local reminders when active/inactive reminders change
  useEffect(() => {
    setLocalActiveReminders(activeReminders);
    setLocalInactiveReminders(inactiveReminders);
  }, [activeReminders, inactiveReminders]);

  // State for filtering and searching reminders
  const [filter, setFilter] = useState("active"); // 'active', 'inactive', 'all'
  const [searchQuery, setSearchQuery] = useState("");

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((query) => {
        setSearchQuery(query.trim().toLowerCase());
      }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    debouncedSearch(query);
  };

  // Determine which reminders to display based on filter and search query
  const filteredReminders = useMemo(() => {
    let remindersToFilter = [];
    if (filter === "active") {
      remindersToFilter = localActiveReminders;
    } else if (filter === "inactive") {
      remindersToFilter = localInactiveReminders;
    } else {
      remindersToFilter = [...localActiveReminders, ...localInactiveReminders];
    }

    // Additional search filtering
    if (searchQuery !== "") {
      remindersToFilter = remindersToFilter.filter((reminder) => {
        const typeMatch =
          reminder.type && reminder.type.toLowerCase().includes(searchQuery);
        const moduleMatch =
          reminder.module &&
          reminder.module.toLowerCase().includes(searchQuery);
        const bodyMatch =
          reminder.body &&
          reminder.body.some((item) =>
            item.value.toLowerCase().includes(searchQuery)
          );
        const notesMatch =
          reminder.notes && reminder.notes.toLowerCase().includes(searchQuery);
        const dateMatch =
          reminder.date &&
          dayjs(reminder.date, "MM/DD/YYYY")
            .format("MM/DD/YYYY")
            .toLowerCase()
            .includes(searchQuery);

        return typeMatch || moduleMatch || bodyMatch || notesMatch || dateMatch;
      });
    }

    return remindersToFilter;
  }, [filter, localActiveReminders, localInactiveReminders, searchQuery]);

  return (
    <div>
      {/* Header and Filter/Search Row */}
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: 16, marginTop: 16 }}
      ></Row>

      {/* Display Counts Based on Filter */}
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        {filter === "active" && (
          <Col span={6}>
            <Badge
              offset={[12, 4]}
              count={localActiveReminders.length}
              showZero
            >
              <HeaderText text="Active Reminders" />
            </Badge>
          </Col>
        )}
        {filter === "inactive" && (
          <Col span={6}>
            <Badge
              offset={[12, 4]}
              count={localInactiveReminders.length}
              showZero
            >
              <HeaderText text="Inactive Reminders" />
            </Badge>
          </Col>
        )}
        {filter === "all" && (
          <Col span={6}>
            <Badge
              offset={[12, 4]}
              count={
                localActiveReminders.length + localInactiveReminders.length
              }
              showZero
            >
              <HeaderText text="All Reminders" />
            </Badge>
          </Col>
        )}
        <Col span={12} align="middle">
          <Select
            value={filter}
            onChange={handleFilterChange}
            style={{ width: 200, marginRight: 16 }}
            align="left"
          >
            <Option value="active">Active Reminders</Option>
            <Option value="inactive">Inactive Reminders</Option>
            <Option value="all">All Reminders</Option>
          </Select>

          <SearchInput
            placeholder="Search Reminders"
            allowClear
            onChange={handleSearchChange}
            style={{ width: 300 }}
          />
        </Col>
        <Col span={6}></Col>
      </Row>

      <Divider />

      {/* Render Reminders Based on Filter and Search */}
      {filteredReminders.length > 0 ? (
        <Row gutter={[16, 16]}>
          {filteredReminders.map((reminder) => (
            <ReminderCard
              key={reminder.id}
              reminder={reminder}
              refreshReminders={refreshReminders}
              userName={userName}
            />
          ))}
        </Row>
      ) : (
        <Row justify="center">
          <Col>
            <Text type="secondary">No reminders to display.</Text>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Reminders;
