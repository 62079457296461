import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat"; // Import advancedFormat
import { formatCommas, formatMoney } from "../../Formatters/helpers";

// Extend dayjs with advancedFormat to support ordinal dates
dayjs.extend(advancedFormat);

/**
 * Generate HTML for an Outbound PO (Purchase Order).
 * Displays shipment details, freight agent info, freight items, weight, total, and notes.
 */
export default function generateHtmlOutboundPurchaseOrder({
  origin,
  destination,
  items = [],
  customDescription,
  projectName,
  pickupDate,
  amount,
  tripDistance,
  shipmentNumber,
  freightAgentCompanyName,
  freightAgentName,
  freightAgentEmail,
  freightAgentPhone,
  poNumber,
}) {
  // Format today's date
  const today = dayjs().format("MM/DD/YYYY");
  const shipDate = pickupDate ? pickupDate : "Not Set";

  // If origin/destination ends with ", USA", remove it (optional cleanup)
  if (origin && origin.endsWith(", USA")) {
    origin = origin.replace(/,\s*USA$/, "");
  }
  if (destination && destination.endsWith(", USA")) {
    destination = destination.replace(/,\s*USA$/, "");
  }

  // Helper to format ft/in dimension
  function formatDimension(ftVal, inVal) {
    const ftText = ftVal !== undefined ? `${ftVal}ft` : "?ft";
    const inValue = Number.isFinite(inVal) ? inVal : 0;
    const inText = inValue === 0 ? "" : ` ${inValue}in`;
    return ftText + inText;
  }

  // Build table rows for the items
  const itemRowsHtml = items
    .map((item) => {
      const {
        type,
        description = "N/A",
        weight,
        length,
        width,
        height,
        lengthIn,
        widthIn,
        heightIn,
      } = item;

      const weightString =
        weight !== undefined ? `${formatCommas(weight)} lbs` : "?";

      const dimensionString = [
        formatDimension(length, lengthIn),
        formatDimension(width, widthIn),
        formatDimension(height, heightIn),
      ].join(" x ");

      const typeString = type || "N/A";

      return `
        <tr>
          <td class="type-cell">${typeString}</td>
          <td class="description-cell">${description}</td>
          <td class="dimensions-cell">${dimensionString}</td>
          <td class="weight-cell">${weightString}</td>
        </tr>
      `;
    })
    .join("");

  // Calculate total (gross) weight
  const totalWeight = items.reduce((acc, item) => acc + (item.weight ?? 0), 0);
  const formattedGrossWeight = `${formatCommas(totalWeight)} lbs`;

  // Notes table (only if customDescription is present)
  const notesTableHtml = customDescription
    ? `
      <table class="notes-table">
        <thead>
          <tr><th>Notes</th></tr>
        </thead>
        <tbody>
          <tr><td>${customDescription}</td></tr>
        </tbody>
      </table>
    `
    : "";

  // Return full HTML string
  return `
    <html>
      <head>
        <style>
          /* Calibri Font */
          @import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;500;600&display=swap');

          /* Container for PDF preview */
          .pdf-preview {
            font-family: 'Calibri', sans-serif;
            margin: 0;
            padding: 0;
            position: relative;
          }
          .pdf-preview .po-body {
            font-size: 12px;
            padding: 20px;
          }

          /* Header Section */
          .pdf-preview .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 16px;
          }
          .pdf-preview .left-header {
            width: 50%;
          }
          .pdf-preview .right-header {
            width: 50%;
            text-align: right;
          }
          .pdf-preview .logo img {
            max-width: 280px;
            height: auto;
          }
          .pdf-preview .po-title {
            font-size: 22px;
            font-weight: 600;
          }

          /* Divider */
          .pdf-preview .divider {
            width: 100%;
            height: 2px;
            background-color: #ED8293;
            margin: 8px 0;
          }

          /* Small details table for date + PO number */
          .pdf-preview .details-table {

            float: right;    /* keep to the right side */
            border-collapse: collapse;
          }
          .pdf-preview .details-table td {
            font-size: 12px;
            padding: 2px 4px;
            vertical-align: middle;
          }
          .pdf-preview .label-column {
            text-align: right;
            white-space: nowrap;
            font-weight: 500;
            padding-right: 8px;
            box-sizing: border-box;
          }
          .pdf-preview .value-column {
            text-align: left;
            padding-left: 8px;
            box-sizing: border-box;
            min-width: 70px; /* so the date/PO align nicely */
          }

          /* Shipment Details & Freight Agent Info containers */
          .half-width-container {
            display: flex;
            width: 100%;
            margin-bottom: 8px;
          }
          .left-half {
            width: 60%;
          }
          .right-half {
            width: 40%;
          }

          /* Info table (Shipment details, addresses, etc.) */
          .info-table {
            border-collapse: collapse;
            width: 100%;
            font-size: 12px;
          }
          .info-table td.label {
            width: 100px;
            font-weight: 500;
            vertical-align: top;
          }
          .right-half .info-table td.label {
            width: 80px !important;
          }
          .info-table td.value {
            text-align: left;
          }

          /* Items Table (showing the freight items) */
          .items-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
          }
          .items-table th,
          .items-table td {
            border: 2px solid black;
            padding: 8px;
            font-size: 12px;
            text-align: left; /* left-align all item cells */
          }
          .items-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-transform: uppercase;
          }
          .type-cell { width: 20%; }
          .description-cell { width: 20%; }
          .dimensions-cell { width: 40%; }
          /* Weight cell fixed width so it aligns with gross weight below */
          .weight-cell {
            width: 125px;
            min-width: 125px;
          }

          /* Gross Weight & Total Tables */
          .gross-weight-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
          }
          .gross-weight-table th,
          .gross-weight-table td {
            border: 2px solid black;
            padding: 8px;
            font-size: 12px;
          }
          /* Label cell is flexible */
          .gross-weight-table th.label-cell {
            text-align: center;
            background-color: #f0f0f0;
            font-weight: 600;
            text-transform: uppercase;
          }
          /* Value cell exactly matches .weight-cell's 125px */
          .gross-weight-table td.value-cell {
            text-align: left;
            width: 125px;
            min-width: 125px;
          }

          /* Notes Table */
          .notes-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
          }
          .notes-table th,
          .notes-table td {
            border: 2px solid black;
            padding: 8px;
            font-size: 12px;
            text-align: left;
          }
          .notes-table th {
            font-weight: 600;
            text-transform: uppercase;
          }

        </style>
      </head>
      <body>
        <div class="pdf-preview">
          <div class="po-body">

            <!-- Header Section -->
            <div class="header">
              <!-- Left Logo -->
              <div class="left-header">
                <div class="logo">
                  <img
                    src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png"
                    alt="BMarko Logo"
                  />
                </div>
              </div>

              <!-- Right Title + Today's Date + PO Number -->
              <div class="right-header">
                <div class="po-title">Freight</div>
                <div class="po-title" style="margin-bottom:8px;">
                  Purchase Order
                </div>
                <!-- Single table with two rows: Date + PO Number -->
                <table class="details-table">
                  <tr>
                    <td class="label-column">Today's Date:</td>
                    <td class="value-column">${today}</td>
                  </tr>
                  <tr>
                    <td class="label-column">PO Number:</td>
                    <td class="value-column">${poNumber || ""}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="divider"></div>

            <!-- Top Info: Shipment Details and Freight Agent Info -->
            <div class="half-width-container">
              <div class="left-half">
                <table class="info-table">
                  <tr>
                    <td class="label">Shipment ID:</td>
                    <td class="value">${shipmentNumber || "N/A"}</td>
                  </tr>
                  <tr>
                    <td class="label">Project:</td>
                    <td class="value">${projectName || "Untitled Project"}</td>
                  </tr>
                  <tr>
                    <td class="label">Pick Up Date:</td>
                    <td class="value">${shipDate}</td>
                  </tr>
                  <tr>
                    <td class="label">Pick Up Address:</td>
                    <td class="value">${origin || "N/A"}</td>
                  </tr>
                  <tr>
                    <td class="label">Drop Off Address:</td>
                    <td class="value">${destination || "N/A"}</td>
                  </tr>
                </table>
              </div>
              <div class="right-half">
                <table class="info-table">
                  ${
                    freightAgentCompanyName
                      ? `<tr>
                          <td class="label">Freight Agent:</td>
                          <td class="value">${freightAgentCompanyName}</td>
                        </tr>`
                      : ""
                  }
                  ${
                    freightAgentName
                      ? `<tr>
                          <td class="label">Name:</td>
                          <td class="value">${freightAgentName}</td>
                        </tr>`
                      : ""
                  }
                  ${
                    freightAgentEmail
                      ? `<tr>
                          <td class="label">Email:</td>
                          <td class="value">${freightAgentEmail}</td>
                        </tr>`
                      : ""
                  }
                  ${
                    freightAgentPhone
                      ? `<tr>
                          <td class="label">Phone:</td>
                          <td class="value">${freightAgentPhone}</td>
                        </tr>`
                      : ""
                  }
                </table>
              </div>
            </div>

            <div class="divider"></div>

            <!-- Freight Items Table -->
            <table class="items-table">
              <thead>
                <tr>
                  <th class="type-cell">Type</th>
                  <th class="description-cell">Description</th>
                  <th class="dimensions-cell">Dimensions</th>
                  <th class="weight-cell">Weight</th>
                </tr>
              </thead>
              <tbody>
                ${
                  itemRowsHtml ||
                  `<tr>
                     <td colspan="4" style="text-align:left;">No items</td>
                   </tr>`
                }
              </tbody>
            </table>

            <!-- Notes + Gross Weight + Total -->
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-top:16px;
              "
            >
              <!-- Notes block -->
              <div style="flex: .75; margin-right: 16px;">
                ${notesTableHtml}
              </div>

              <!-- Gross Weight + Total block -->
              <div style="width: 40%;">
                <table class="gross-weight-table">
                  <tbody>
                    <tr>
                      <th class="label-cell">Gross Weight</th>
                      <td class="value-cell">${formattedGrossWeight}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="gross-weight-table">
                  <tbody>
                    <tr>
                      <th class="label-cell">Total</th>
                      <td
                        class="value-cell"
                        style="font-weight:bold; font-size:15px; text-align:center;""
                      >
                        ${formatMoney(amount)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div> <!-- .po-body -->
        </div> <!-- .pdf-preview -->
      </body>
    </html>
  `;
}
