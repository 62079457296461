import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom"; // <-- useLocation if needed
import { Input, Table, Row, Col, Button, Tag } from "antd";
import { usePurchaseOrders, useVendors } from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { Loader } from "../../Styled/Loader";
import { ArrowLeftOutlined } from "@ant-design/icons";

const ReceiveItemPurchase = () => {
  const { id } = useParams(); // Get PO ID from URL
  const navigate = useNavigate();
  const location = useLocation(); // If you need this for fallback

  const { data: purchaseOrders } = usePurchaseOrders();
  const { data: vendors } = useVendors();

  const [searchText, setSearchText] = useState("");
  // If you came here from the previous screen with selectedPO in `location.state`
  // we’ll try that first; otherwise, we’ll do the fallback approach below.
  const [selectedPO, setSelectedPO] = useState(
    location.state?.selectedPO || null
  );

  // Fallback if user refreshed or navigated directly
  useEffect(() => {
    if (!selectedPO && purchaseOrders) {
      const foundPO = purchaseOrders.find((po) => po.id === id);
      if (foundPO) {
        setSelectedPO(foundPO);
      }
    }
  }, [selectedPO, purchaseOrders, id]);

  // Update document title
  useEffect(() => {
    document.title = `Receiving - ${selectedPO?.poNumber || ""}`;
  }, [selectedPO]);

  // Navigate to item details, passing state
  const handleRowClick = (record) => {
    // record is the clicked row from your Table
    navigate(
      `/receiving/receive/purchase-orders/${id}/item/${record.itemNumber}`,
      {
        state: {
          selectedPO: selectedPO, // pass entire PO
          selectedLine: record, // pass the clicked line
        },
      }
    );
  };

  // Table columns
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
  ];

  // Filter items by quantity and search
  const filteredItems = selectedPO?.lines
    ?.filter(
      (line) =>
        line.qtyOrdered !== line.qtyReceived &&
        line.qtyReceived < line.qtyOrdered
    )
    .filter(
      (line) =>
        line.itemDescription.toLowerCase().includes(searchText.toLowerCase()) ||
        line.category.toLowerCase().includes(searchText.toLowerCase())
    );

  return (
    <>
      {selectedPO ? (
        <>
          {/* Header */}
          <Row justify="center" style={{ marginBottom: 16 }}>
            <Col span={24}>
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(`/receiving/receive/purchase-orders`)}
              >
                Back
              </Button>
            </Col>

            <Col>
              <div
                style={{ fontWeight: 600, fontSize: 18, textAlign: "center" }}
              >
                {selectedPO.poNumber}
              </div>
              <Col
                style={{ marginTop: 8, marginBottom: 8 }}
                span={24}
                align="center"
              >
                <Tag color="blue">
                  {selectedPO?.shipToAddress.split(",")[0]}
                </Tag>
              </Col>
              <div style={{ textAlign: "center" }}>
                {getVendorName(selectedPO.vendor, vendors)}
              </div>
            </Col>
          </Row>

          {/* Search bar and items table */}
          <Input.Search
            placeholder="Search items"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: 16 }}
          />
          <Table
            columns={columns}
            dataSource={filteredItems}
            rowClassName="clickable-row"
            rowKey="itemNumber"
            size="small"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            pagination={false} // Remove pagination
          />
        </>
      ) : (
        <Loader /> // Loading state if no selectedPO yet
      )}
    </>
  );
};

export default ReceiveItemPurchase;
