// src/components/ManufacturingLocations.jsx

import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Card, Button, message, Modal } from "antd";
import HeaderText from "../../Styled/HeaderText";
import { SearchInput } from "../../Styled/SearchInput";
import {
  GoogleMap,
  MarkerF,
  OverlayView, // Reverted to OverlayView
  OverlayViewF,
  useLoadScript,
} from "@react-google-maps/api"; // Ensure correct import
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import { useUser } from "@clerk/clerk-react";
import { useManufacturingLocations } from "../../Contexts/useSpecificData";
import AddLocationModal from "../../Components/ManufacturingLocations/AddLocationModal";
import { Loader } from "../../Styled/Loader";

const { confirm } = Modal;

const libraries = ["places"];

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: 37.0902,
  lng: -95.7129,
};

const defaultZoom = 5;

const ManufacturingLocations = ({ viewOnly = false }) => {
  // 1. Added viewOnly prop with default value false
  console.log("ManufacturingLocations component rendered");

  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [editingLocation, setEditingLocation] = useState(null);
  const [mapRef, setMapRef] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const { user } = useUser();
  const { data: manufacturingLocations, loading: locationsLoading } =
    useManufacturingLocations();

  useEffect(() => {
    console.log("Manufacturing Locations Data:", manufacturingLocations);
  }, [manufacturingLocations]);

  const filteredLocations = useMemo(() => {
    if (!manufacturingLocations) {
      console.log("THERE IS NOTHING HERE");
      return [];
    }
    let locations = manufacturingLocations.filter((loc) => !loc.deleted);
    if (searchTerm.trim() !== "") {
      const lowercasedValue = searchTerm.toLowerCase();
      locations = locations.filter(
        (loc) =>
          loc.nickname.toLowerCase().includes(lowercasedValue) ||
          loc.address.toLowerCase().includes(lowercasedValue)
      );
    }
    console.log("Filtered Locations:", locations);
    return locations;
  }, [manufacturingLocations, searchTerm]);

  useEffect(() => {
    console.log("Filtered Locations Updated:", filteredLocations);
    if (mapRef && filteredLocations.length > 0) {
      if (filteredLocations.length === 1) {
        // 2. Check if only one location matches
        mapRef.setCenter({
          lat: filteredLocations[0].latitude,
          lng: filteredLocations[0].longitude,
        });
        mapRef.setZoom(20); // Moderate zoom level for single location
      } else {
        const bounds = new window.google.maps.LatLngBounds();
        filteredLocations.forEach((loc) => {
          bounds.extend({ lat: loc.latitude, lng: loc.longitude });
        });
        mapRef.fitBounds(bounds);
      }
    } else if (mapRef) {
      mapRef.setCenter(defaultCenter);
      mapRef.setZoom(defaultZoom);
    }
  }, [filteredLocations, mapRef]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const showAddModal = () => {
    setEditingLocation(null);
    setIsAddModalVisible(true);
  };

  const showEditModal = (location) => {
    setEditingLocation(location);
    setIsAddModalVisible(true);
  };

  const closeModal = () => {
    setIsAddModalVisible(false);
    setEditingLocation(null);
  };

  const handleAddOrEditLocation = (updatedLocation) => {
    console.log("Adding or Editing Location:", updatedLocation);
    // Optionally, refresh the locations here if needed
    // For example, you might re-fetch data or update local state
  };

  const handleDelete = (location) => {
    confirm({
      title: "Are you sure you want to delete this location?",
      content: `Nickname: ${location.nickname}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await updateById(
            TABLES.MANUFACTURING_LOCATIONS,
            { deleted: true },
            location.id
          );
          message.success("Location deleted successfully!");
        } catch (error) {
          console.error("Error deleting location:", error);
          message.error("Failed to delete location.");
        }
      },
    });
  };

  const onMapLoad = (map) => {
    setMapRef(map);
    // Removed bounds setting from here to avoid conflicts
    // The useEffect will handle bounds when filteredLocations are updated
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded || locationsLoading) return <Loader />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <HeaderText text="Manufacturing Locations" />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchInput
          placeholder="Search Manufacturing Locations"
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: "70%" }}
        />
        {!viewOnly && ( // 3. Conditionally render Add Location button
          <Button type="primary" onClick={showAddModal}>
            Add Location
          </Button>
        )}
      </Col>
      <Col span={24}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          onLoad={onMapLoad}
          center={defaultCenter}
          zoom={defaultZoom}
        >
          {filteredLocations.map((location) => {
            if (
              typeof location.latitude !== "number" ||
              typeof location.longitude !== "number"
            ) {
              console.warn(
                `Invalid coordinates for location ID: ${location._id}`
              );
              return null;
            }

            return (
              <React.Fragment key={location._id}>
                <MarkerF
                  position={{ lat: location.latitude, lng: location.longitude }}
                  title={location.nickname}
                />
                <OverlayViewF
                  position={{ lat: location.latitude, lng: location.longitude }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} // Use OverlayView
                  getPixelPositionOffset={() => ({ x: 0, y: 30 })}
                >
                  <div
                    className="box-shadow"
                    style={{
                      background: "white",
                      color: "black",
                      padding: "2px 5px",
                      borderRadius: "3px",
                      fontSize: "12px",
                      width: "fit-content",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      transform: "translate(-50%, -100%)",
                    }}
                  >
                    {location.nickname}
                  </div>
                </OverlayViewF>
              </React.Fragment>
            );
          })}
        </GoogleMap>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {filteredLocations.map((location) => (
            <Col key={location._id} xs={24} sm={12} md={8} lg={6}>
              <Card
                title={location.nickname}
                extra={
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Map
                  </a>
                }
                actions={
                  !viewOnly
                    ? [
                        // 4. Conditionally render Edit and Delete actions
                        <Button
                          type="link"
                          onClick={() => showEditModal(location)}
                          key="edit"
                        >
                          Edit
                        </Button>,
                        <Button
                          type="link"
                          danger
                          onClick={() => handleDelete(location)}
                          key="delete"
                        >
                          Delete
                        </Button>,
                      ]
                    : [] // No actions in viewOnly mode
                }
              >
                <p>
                  <strong>Address:</strong> {location.address}
                </p>
                <p>
                  <strong>Submitted By:</strong> {location.submittedBy.userName}{" "}
                  ({location.submittedBy.userEmail})
                </p>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
      {!viewOnly && ( // 5. Conditionally render AddLocationModal
        <AddLocationModal
          visible={isAddModalVisible}
          onClose={closeModal}
          onAdd={handleAddOrEditLocation}
          location={editingLocation}
        />
      )}
    </Row>
  );
};

export default ManufacturingLocations;
