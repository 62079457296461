import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  InputNumber,
  Upload,
  message,
  Row,
  Col,
  Switch,
  Table,
  Tag,
} from "antd";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { updateById } from "../../Functions/updateById";
import { s3Upload } from "../../Functions/s3Upload";
import { TABLES, SLACK_CHANNELS } from "../../constants";
import { sendSlackMessage } from "../../Functions/sendSlackMessage";
import { Loader } from "../../Styled/Loader";
import dayjs from "dayjs";
import { useUser } from "@clerk/clerk-react";
import { getProjectName } from "../../Formatters/getProjectName";

const ReceiveItemDetailPurchase = () => {
  const { id, itemNumber } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); // <-- We'll check if state was passed
  const { data: purchaseOrders, refresh: refreshPurchaseOrders } =
    usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();
  const [loading, setLoading] = useState(false);

  // 1) Attempt to get data from navigation state
  //    (e.g. { selectedPO, selectedLine })
  console.log("Navigation State:", location.state); // <-- Log it here

  const [selectedPO, setSelectedPO] = useState(
    location.state?.selectedPO || null
  );
  const [selectedItem, setSelectedItem] = useState(
    location.state?.selectedLine || null
  );

  const [qtyReceived, setQtyReceived] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [receivedOffSite, setReceivedOffSite] = useState(false);

  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress;

  // 2) If user refreshed or came directly, location.state might be undefined,
  //    so we do a fallback lookup here.
  useEffect(() => {
    if (!selectedPO && purchaseOrders) {
      const foundPO = purchaseOrders.find((po) => po.id === id);
      if (foundPO) {
        setSelectedPO(foundPO);
        console.log("Fallback found PO:", foundPO);

        // If we didn't get selectedLine from state,
        // try to find the line from the URL param itemNumber
        if (!selectedItem) {
          const foundItem = foundPO.lines.find(
            (line) => line.itemNumber === itemNumber
          );
          setSelectedItem(foundItem);
        }
      }
    }
  }, [selectedPO, selectedItem, purchaseOrders, id, itemNumber]);

  // Slack channel selection
  const shipToAddress = selectedPO?.shipToAddress || "";
  let slackChannel;
  if (shipToAddress.toLowerCase().includes("6544 warren")) {
    slackChannel =
      process.env.REACT_APP_STAGE === "_dev"
        ? SLACK_CHANNELS.NORCROSS_RECEIVING_DEV
        : SLACK_CHANNELS.NORCROSS_RECEIVING_PROD;
  } else {
    slackChannel =
      process.env.REACT_APP_STAGE === "_dev"
        ? SLACK_CHANNELS.RECEIVING_DEV
        : SLACK_CHANNELS.RECEIVING_PROD;
  }

  // Navigation handler
  const handleBack = () => {
    // Optionally pass state back, if you want to preserve it
    // or just navigate
    navigate(`/receiving/receive/purchase-orders/${id}`);
  };

  // Max Quantity
  const handleMaxQty = () => {
    if (selectedItem) {
      setQtyReceived(
        Number(selectedItem.qtyOrdered) - Number(selectedItem.qtyReceived)
      );
    }
  };

  // Update fileList
  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleRemove = (file) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  // Retry helper (if needed)
  const retryAsync = async (fn, retries = 3, delay = 1000) => {
    try {
      return await fn();
    } catch (error) {
      if (retries === 1) throw error;
      await new Promise((resolve) => setTimeout(resolve, delay));
      return retryAsync(fn, retries - 1, delay * 2);
    }
  };

  // Submit handler
  const handleSubmit = async () => {
    setLoading(true);

    // Basic validation
    if (
      Number(qtyReceived) <= 0 ||
      (!receivedOffSite && fileList.length === 0)
    ) {
      message.error(
        "Please enter a valid quantity and upload at least one photo, or mark as received off site."
      );
      setLoading(false);
      return;
    }

    // The logic to be retried
    const submit = async () => {
      // 1) Upload files to S3 (only if not off site)
      let uploadedUrls = [];
      if (!receivedOffSite) {
        uploadedUrls = await Promise.all(
          fileList.map((file, index) => {
            const fileName = `${selectedPO.poNumber}_${
              selectedItem.itemNumber
            }_image_${index + 1}_${new Date().toISOString()}`;
            return s3Upload(file.originFileObj, fileName);
          })
        );
      }

      // 2) Update the PO line
      const updatedItem = {
        ...selectedItem,
        qtyReceived: Number(selectedItem.qtyReceived) + Number(qtyReceived),
        images: [...(selectedItem.images || []), ...uploadedUrls],
        receivedDate: new Date().toISOString(),
        receivedBy: userEmail,
      };
      const updatedLines = selectedPO.lines.map((line) =>
        line.itemNumber === selectedItem.itemNumber ? updatedItem : line
      );

      const updatedPO = {
        ...selectedPO,
        lines: updatedLines,
      };

      await updateById(TABLES.PURCHASE_ORDERS, updatedPO, selectedPO.id);

      // 3) Slack message
      const slackMessage = {
        channel: slackChannel,
        text: `${
          receivedOffSite ? "[OFFSITE] " : ""
        }*New Items Received :truck: :arrow_right: :house: ${
          selectedPO.poNumber
        }*`,
        blocks: [
          {
            type: "header",
            text: {
              type: "plain_text",
              text: `${
                receivedOffSite ? "[OFFSITE] " : ""
              }New Items Received :truck: :arrow_right: :house: ${
                selectedPO.poNumber
              }`,
              emoji: true,
            },
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: `*Address:*\n${selectedPO.shipToAddress}`,
              },
            ],
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: `*Vendor:*\n${getVendorName(selectedPO.vendor, vendors)}`,
              },
              {
                type: "mrkdwn",
                text: `*Category:*\n${selectedItem.category}`,
              },
            ],
          },
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: `*Item:*\n${selectedItem.itemDescription}`,
            },
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: `*Quantity Received:*\n${qtyReceived}`,
              },
              {
                type: "mrkdwn",
                text: `*Project:*\n${getProjectName(
                  selectedPO.projectNumber,
                  projects
                )}`,
              },
            ],
          },
          ...uploadedUrls.map((url, index) => ({
            type: "image",
            image_url: url,
            alt_text: `Image ${index + 1}`,
            title: {
              type: "plain_text",
              text: `Image ${index + 1}: ${
                selectedItem.itemNumber
              } - ${dayjs().format("MM-DD-YYYY")} - ${selectedPO.poNumber}`,
            },
          })),
        ],
      };
      await sendSlackMessage(slackMessage);

      // 4) Check if all lines are received
      const allLinesReceived = updatedLines.every(
        (line) => Number(line.qtyOrdered) === Number(line.qtyReceived)
      );

      // If so, send a "completed" message
      if (allLinesReceived) {
        await sendSlackMessage({
          channel: slackChannel,
          text: `:ballot_box_with_check: PO #${selectedPO.poNumber} has been completed! :ballot_box_with_check:`,
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: `:ballot_box_with_check: PO #${selectedPO.poNumber} has been completed! :ballot_box_with_check:`,
                emoji: true,
              },
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Address:*\n${selectedPO.shipToAddress}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Vendor:*\n${getVendorName(
                    selectedPO.vendor,
                    vendors
                  )}`,
                },
                {
                  type: "mrkdwn",
                  text: `*Number of Items:*\n${selectedPO.lines.length}`,
                },
              ],
            },
          ],
        });
      }

      // 5) Refresh purchase orders
      await refreshPurchaseOrders();

      // 6) Decide where to navigate
      const navigationPath = allLinesReceived
        ? `/receiving/receive/purchase-orders/`
        : `/receiving/receive/purchase-orders/${id}`;

      return navigationPath;
    };

    // Try the submission, with retries
    try {
      const navigationPath = await retryAsync(submit, 3, 1000);
      message.success("Item received successfully.");
      navigate(navigationPath);
    } catch (error) {
      setLoading(false);
      console.error("Error receiving item:", error);
      message.error(
        "Failed to receive item after multiple attempts. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  // Disable submit if no quantity or no photos (unless offsite)
  const isSubmitDisabled =
    qtyReceived <= 0 ||
    (!receivedOffSite && fileList.length === 0 && qtyReceived > 0);

  // Show loader if we still haven't found PO/item or are in loading state
  if (!selectedPO || !selectedItem || loading) {
    return <Loader />;
  }

  // If this line item is fully received already
  const qtyRemaining = selectedItem.qtyOrdered - selectedItem.qtyReceived;
  if (qtyRemaining <= 0) {
    return (
      <div style={{ textAlign: "center", marginTop: 100 }}>
        <h2>This line item has been fully received.</h2>
        <Button type="primary" onClick={handleBack}>
          Back to Purchase Orders
        </Button>
      </div>
    );
  }

  // Normal UI
  return (
    <>
      <Row justify="center" style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Button icon={<ArrowLeftOutlined />} onClick={handleBack}>
            Back
          </Button>
        </Col>
        <Col>
          <div style={{ fontWeight: 600, fontSize: 18, textAlign: "center" }}>
            {selectedPO.poNumber}
          </div>
          <Col
            style={{ marginTop: 8, marginBottom: 8 }}
            span={24}
            align="center"
          >
            <Tag color="blue">{selectedPO?.shipToAddress.split(",")[0]}</Tag>
          </Col>
          <div style={{ textAlign: "center" }}>
            {getVendorName(selectedPO.vendor, vendors)}
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Switch
              checked={receivedOffSite}
              onChange={(checked) => setReceivedOffSite(checked)}
            />
            <span style={{ marginLeft: 8 }}>Received Off Site</span>
          </div>
        </Col>
        {receivedOffSite && (
          <Col span={24} style={{ textAlign: "center" }}>
            <div
              style={{
                textAlign: "center",
                marginTop: 8,
                fontStyle: "italic",
                opacity: 0.7,
              }}
            >
              Items received off site will not require photos.
            </div>
          </Col>
        )}
      </Row>

      <Card
        title={`Line ${selectedItem.lineNumber}`}
        extra={
          <Tag style={{ fontSize: 14, fontWeight: 600 }} color="blue">
            Qty Remaining: {qtyRemaining}
          </Tag>
        }
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: 8 }}
      >
        <Table
          bordered
          showHeader={false}
          pagination={false}
          size="small"
          dataSource={[
            { key: "1", label: "Item #", value: selectedItem.itemNumber },
            { key: "2", label: "Category", value: selectedItem.category },
            {
              key: "3",
              label: "Item",
              value: selectedItem.itemDescription,
            },
            { key: "4", label: "UOM", value: selectedItem.uom },
            {
              key: "5",
              label: "Qty Ordered",
              value: selectedItem.qtyOrdered,
            },
            {
              key: "6",
              label: "Qty Received",
              value: selectedItem.qtyReceived,
            },
          ]}
          columns={[
            { dataIndex: "label", key: "label", width: 120 },
            { dataIndex: "value", key: "value" },
          ]}
        />

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={12}>
            <InputNumber
              min={0}
              max={qtyRemaining}
              value={qtyReceived}
              type="number"
              onChange={setQtyReceived}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <Button
              onClick={handleMaxQty}
              type="primary"
              style={{ width: "100%" }}
            >
              Receive Max Qty
            </Button>
          </Col>
        </Row>

        {qtyReceived > 0 && (
          <Row style={{ marginTop: 16 }}>
            <Col span={24}>
              <Upload
                className="custom-upload"
                listType="picture-card"
                fileList={fileList}
                onChange={handleUploadChange}
                onRemove={handleRemove}
                beforeUpload={() => false} // prevent auto-upload
                accept="image/*,text/plain"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                  removeIcon: (
                    <span style={{ color: "red" }}>
                      <CloseOutlined />
                    </span>
                  ),
                }}
              >
                {fileList.length < 5 && "+ Upload"}
              </Upload>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{ marginTop: 16, width: "100%" }}
              disabled={isSubmitDisabled}
              loading={loading}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ReceiveItemDetailPurchase;
