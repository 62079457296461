// src/hooks/useCurrentUser.js
import { useUser } from "@clerk/clerk-react";

export default function useCurrentUser() {
    const { user } = useUser();
  const userName = user?.fullName;
    const userEmail = user?.primaryEmailAddress?.emailAddress;
    const userFirstName = user?.firstName;
    const userLastName = user?.lastName;
    const userId = user?.id;
  return { userName, userEmail, userFirstName, userLastName, userId };
}
