// HelpDrawer.js
import React, { useState, useRef, useEffect } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  Upload,
  message,
  Typography,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useUser } from "@clerk/clerk-react";
import { addItemsToTable } from "../../Functions/addAria";
import { TABLES, SLACK_CHANNELS } from "../../constants";
import { sendSlackMessage } from "../../Functions/sendSlackMessage";
import { s3Upload } from "../../Functions/s3Upload";
import { dataURIToBlob } from "../../Formatters/dataURIToBlob";
import { FaFileUpload } from "react-icons/fa";

const { TextArea } = Input;
const { Text } = Typography;

const slackChannel =
  process.env.REACT_APP_STAGE === "_dev"
    ? SLACK_CHANNELS.TICKETS_DEV
    : SLACK_CHANNELS.TICKETS_PROD;

/**
 * Function to format the datetime as YYYYMMDD_HHmmss
 */
const formatDateTime = (date) => {
  const pad = (n) => n.toString().padStart(2, "0");
  return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(
    date.getDate()
  )}_${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
};

/**
 * Function to sanitize strings for filenames
 */
const sanitizeString = (str) => {
  return str.replace(/[^a-z0-9]/gi, "_").toLowerCase();
};

/**
 * Function to parse the User Agent string and extract browser name and version
 */
const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  let browserName = "Unknown";
  let browserVersion = "Unknown";

  if (ua.indexOf("Chrome") > -1 && ua.indexOf("Edg") === -1) {
    browserName = "Chrome";
    browserVersion = ua.substring(
      ua.indexOf("Chrome/") + 7,
      ua.indexOf(" Safari/")
    );
  } else if (ua.indexOf("Firefox") > -1) {
    browserName = "Firefox";
    browserVersion = ua.substring(ua.indexOf("Firefox/") + 8);
  } else if (ua.indexOf("Safari") > -1 && ua.indexOf("Chrome") === -1) {
    browserName = "Safari";
    browserVersion = ua.substring(
      ua.indexOf("Version/") + 8,
      ua.indexOf(" Safari/")
    );
  } else if (ua.indexOf("Edg") > -1) {
    browserName = "Edge";
    browserVersion = ua.substring(
      ua.indexOf("Edg/") + 4,
      ua.indexOf(" Chrome/")
    );
  }
  // Add more browsers if needed

  return `${browserName} ${browserVersion}`;
};

/**
 * Function to format Slack message blocks with separate sections for System Info and Errors/Warnings
 */
const createSlackMessageBlocks = (ticketData, uploadedUrls) => {
  const { userEmail, issueDescription, currentURL, systemInfo, logs } =
    ticketData;

  const logsToShow = logs.slice(-5); // Show the last 5 errors/warnings

  // Create a section block for attached files if any URLs exist
  const attachedFilesBlock =
    uploadedUrls.length > 0
      ? {
          type: "section",
          text: {
            type: "mrkdwn",
            text:
              "*Attached Files:*\n" +
              uploadedUrls
                .map((url, index) => `<${url}|File ${index + 1}>`)
                .join("\n"),
          },
        }
      : null;

  return [
    {
      type: "header",
      text: {
        type: "plain_text",
        text: `New Support Ticket from ${userEmail || "Anonymous"}`,
        emoji: true,
      },
    },
    {
      type: "section",
      fields: [
        {
          type: "mrkdwn",
          text: `*Issue Description:*\n${issueDescription}`,
        },
        {
          type: "mrkdwn",
          text: `*Current URL:*\n<${currentURL}|${currentURL}>`,
        },
      ],
    },
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text:
          `*System Information:*\n` +
          `Browser: ${systemInfo.userAgent}\n` +
          `Platform: ${systemInfo.platform}\n` +
          `Screen Resolution: ${systemInfo.screenResolution}`,
      },
    },
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text:
          `*Errors/Warnings (last 5):*\n` +
          (logsToShow.length > 0
            ? logsToShow
                .map(
                  (log) =>
                    `${log.timestamp} - ${log.type.toUpperCase()}: ${
                      log.message
                    }\nStack Trace: ${log.stackTrace}`
                )
                .join("\n\n")
            : "No errors or warnings available."),
      },
    },
    // Include the attached files block only if there are uploaded URLs
    ...(attachedFilesBlock ? [attachedFilesBlock] : []),
  ];
};


const HelpDrawer = ({ visible, onClose, screenshot, logs }) => {
  const [form] = Form.useForm();
  const { user } = useUser();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const textAreaRef = useRef(null);

  /**
   * Handle paste event in the TextArea
   */
  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    if (!items) return;

    const images = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        const file = item.getAsFile();
        if (file) {
          images.push(file);
        }
      }
    }

    if (images.length > 0) {
      e.preventDefault(); // Prevent default paste behavior

      const newFiles = images.map((file, index) => ({
        uid: `pasted-${Date.now()}-${index}`,
        name: file.name || `pasted-image-${index}.png`,
        status: "done",
        url: URL.createObjectURL(file),
        originFileObj: file,
      }));

      setFileList((prevList) => [...prevList, ...newFiles]);
      message.success("Screenshot pasted successfully!");
    }
  };

  /**
   * Clean up object URLs when fileList changes or component unmounts
   */
  useEffect(() => {
    // Revoke object URLs when files are removed or component unmounts
    return () => {
      fileList.forEach((file) => {
        if (file.url) {
          URL.revokeObjectURL(file.url);
        }
      });
    };
  }, [fileList]);

  /**
   * Function to upload files and screenshot to S3 with specific naming convention
   */
  const uploadFilesToS3 = async (issueDescription, userEmail) => {
    try {
      const datetime = formatDateTime(new Date());
      const sanitizedIssue = sanitizeString(issueDescription.substring(0, 10)); // first 10 chars
      const sanitizedEmail = sanitizeString(userEmail);

      // Upload all files
      const uploadedUrls = await Promise.all(
        fileList.map(async (file, index) => {
          const fileName = `screenshot_${sanitizedIssue}_${sanitizedEmail}_${datetime}_${
            index + 1
          }${file.name ? `_${sanitizeString(file.name)}` : ""}.png`;
          return await s3Upload(file.originFileObj, fileName);
        })
      );

      // Upload the screenshot passed from the parent
      if (screenshot) {
        const fileName = `screenshot_${sanitizedIssue}_${sanitizedEmail}_${datetime}_main.png`;
        const screenshotBlob = dataURIToBlob(screenshot); // Convert to Blob if necessary
        const screenshotUrl = await s3Upload(screenshotBlob, fileName);
        uploadedUrls.push(screenshotUrl); // Add screenshot URL to the list
      }

      return uploadedUrls;
    } catch (error) {
      console.error("Error uploading files:", error);
      throw new Error("File upload failed");
    }
  };

  /**
   * Form submission handler
   */
  const onFinish = async (values) => {
    setLoading(true);

    const systemInfo = {
      userAgent: getBrowserInfo(), // Simplified browser info
      platform: navigator.platform,
      language: navigator.language,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      appVersion: navigator.appVersion,
      cookiesEnabled: navigator.cookieEnabled,
      browserOnline: navigator.onLine,
      hardwareConcurrency: navigator.hardwareConcurrency,
      status: "Open",
      browserInfo: {
        vendor: navigator.vendor,
        product: navigator.product,
        productSub: navigator.productSub,
      },
    };

    const currentURL = window.location.href;

    const formData = {
      userId: user?.id || "Anonymous",
      userEmail: user?.primaryEmailAddress?.emailAddress || "Not Provided",
      issueDescription: values.issueDescription,
      systemInfo,
      currentURL,
      logs, // All captured errors/warnings
      timestamp: new Date().toISOString(),
    };

    try {
      const uploadedUrls = await uploadFilesToS3(
        formData.issueDescription,
        formData.userEmail
      );

      const savedItem = await addItemsToTable(TABLES.TICKETS, {
        ...formData,
        uploadedFiles: uploadedUrls,
      });

      const blocks = createSlackMessageBlocks(savedItem, uploadedUrls);

      await sendSlackMessage({
        channel: slackChannel,
        text: `New support ticket from ${savedItem.userEmail || "Anonymous"}`,
        blocks,
      });

      message.success("Issue reported successfully!");
      form.resetFields();
      setFileList([]);
      setLoading(false);
      onClose(); // Close the drawer and clear logs in parent component
    } catch (error) {
      console.error("Error submitting support ticket:", error);
      message.error("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  /**
   * Handle file removal from the Upload component
   */
  const handleRemove = (file) => {
    setFileList((prevFileList) =>
      prevFileList.filter((item) => item.uid !== file.uid)
    );
    // Revoke the object URL to avoid memory leaks
    if (file.url) {
      URL.revokeObjectURL(file.url);
    }
  };

  /**
   * Handle before upload to prevent automatic upload
   */
  const handleBeforeUpload = (file) => {
    // Prevent automatic upload
    return false;
  };

  return (
    <Drawer
      title="Report an Issue"
      width={480}
      onClose={onClose}
      open={visible}
      styles={{ body: { paddingBottom: 80 } }}
      destroyOnClose={true}
      extra={
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
          loading={loading}
        >
          Submit
        </Button>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            name="issueDescription"
            label="Describe the Issue (paste screenshots here as well)"
            rules={[
              { required: true, message: "Please describe the issue." },
              {
                min: 10,
                message: "Description must be at least 10 characters.",
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Enter a detailed description or paste screenshots here..."
              onPaste={handlePaste}
              ref={textAreaRef}
            />
          </Form.Item>

          <Form.Item label="Upload Files">
            <Upload
              multiple
              listType="picture"
              beforeUpload={handleBeforeUpload}
              onChange={({ fileList }) => setFileList(fileList)}
              onRemove={handleRemove}
              fileList={fileList}
              accept="*/*" // Accepts any file type
            >
              <Button icon={<UploadOutlined />}>Select Files</Button>
            </Upload>
            <Text type="secondary">
              You can upload files here.
            </Text>
          </Form.Item>

          <Form.Item label="User Email">
            <Text>
              {user?.primaryEmailAddress?.emailAddress || "Not Provided"}
            </Text>
          </Form.Item>

          <Form.Item label="Current URL">
            <Text>{window.location.href}</Text>
          </Form.Item>

          {/* Screenshot Preview */}
          {screenshot && (
            <Form.Item label="Captured Screenshot">
              <img
                src={screenshot}
                alt="Captured Screenshot"
                style={{ maxWidth: "100%" }}
              />
            </Form.Item>
          )}

          {/* Display Captured Errors */}
          {logs && logs.length > 0 && (
            <Form.Item label="Captured Errors/Warnings">
              <div
                style={{
                  maxHeight: 200,
                  overflowY: "auto",
                  padding: "10px",
                  border: "1px solid #f0f0f0",
                  borderRadius: "4px",
                }}
              >
                {logs.slice(-5).map((log, index) => (
                  <div key={index} style={{ marginBottom: "10px" }}>
                    <Text type={log.type === "error" ? "danger" : "warning"}>
                      {log.timestamp} - {log.type.toUpperCase()}: {log.message}
                    </Text>
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        marginTop: "5px",
                        backgroundColor: "#fafafa",
                        padding: "10px",
                        borderRadius: "4px",
                      }}
                    >
                      {log.stackTrace}
                    </pre>
                  </div>
                ))}
              </div>
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Drawer>
  );
};

export default HelpDrawer;
