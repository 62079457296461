// src/utils/statusUtils.js

import { STATUS_COLORS } from "../constants";

/**
 * Determines the current status of a module based on its production and preconstruction data.
 * @param {Object} module - The module object containing production and preconstruction data.
 * @param {Object} project - The project object containing preconstruction data.
 * @returns {string} - The determined status.
 */
export const getModuleStatus = (module, project) => {
  // Priority: Production status takes precedence over Preconstruction
  if (module.production && module.production.status) {
    return module.production.status;
  }

  // If in preconstruction
  if (project.preconstruction) {
    return project.preconstruction.status || "Issued for Preconstruction";
  }

  // Default status if none is found
  return "Issued for Preconstruction";
};

/**
 * Determines the color for a given status.
 * @param {string} status - The status string.
 * @returns {string} - The corresponding color string.
 */
export const getStatusColor = (status) => {
  return STATUS_COLORS[status] || "default";
};
