// src/Styled/CustomIcons.jsx

import React from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types"; // Optional: For prop type validation
import frameIcon from "../images/CustomIcons/frame.svg";
import welderIcon from "../images/CustomIcons/welder.svg";
import logisticsIcon from "../images/CustomIcons/timeTruck.svg"; // Ensure correct path
import shippingIcon from "../images/CustomIcons/truck.svg"; // Ensure correct path

const SvgIcon = ({
  src,
  style = {},
  width = "24px",
  height = "24px",
  className = "",
  ...props
}) => {
  const { color = "currentColor", opacity, ...restStyle } = style;

  return (
    <ReactSVG
      src={src}
      beforeInjection={(svg) => {
        // Remove all existing fill attributes to prevent overrides
        svg
          .querySelectorAll("[fill]")
          .forEach((el) => el.removeAttribute("fill"));

        // Set the desired fill color
        if (color) {
          svg.setAttribute("fill", color);
        }

        // Apply opacity if provided
        if (opacity !== undefined) {
          svg.style.opacity = opacity;
        }

        // Remove width and height attributes to allow external styling
        svg.removeAttribute("width");
        svg.removeAttribute("height");

        // Set width and height directly on the SVG element via style
        svg.style.width = width;
        svg.style.height = height;

        // Optionally, ensure viewBox is set
        if (!svg.getAttribute("viewBox")) {
          const widthAttr = svg.getAttribute("width") || "24";
          const heightAttr = svg.getAttribute("height") || "24";
          svg.setAttribute("viewBox", `0 0 ${widthAttr} ${heightAttr}`);
        }
      }}
      {...props}
      className={className}
      style={{
        display: "inline-block", // Ensure proper alignment
        verticalAlign: "middle", // Align with text if needed
        ...restStyle, // Pass other styles to the wrapper element
      }}
    />
  );
};

// Optional: Define prop types for better developer experience
SvgIcon.propTypes = {
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  width: PropTypes.string, // Expecting strings with units, e.g., "24px"
  height: PropTypes.string,
  className: PropTypes.string,
};

// Export specific icons for use
export const FramingIcon = (props) => {
  return <SvgIcon src={frameIcon} {...props} />;
};

export const ShippingContainerIcon = (props) => {
  return <SvgIcon src={welderIcon} {...props} />;
};

export const WeldingIcon = (props) => {
  return <SvgIcon src={welderIcon} {...props} />;
};

export const LogisticsIcon = (props) => {
  return <SvgIcon src={logisticsIcon} {...props} />;
};

export const ShippingIcon = (props) => {
  return <SvgIcon src={shippingIcon} {...props} />;
};

export default SvgIcon;
