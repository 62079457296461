// OutboundRFQ.js
import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  Select,
  Checkbox,
  Button,
  Input,
  message,
  Row,
  Col,
  Tag,
  Spin,
  DatePicker,
} from "antd";
import {
  WarningOutlined,
  FilePdfOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useFreightAgents } from "../../Contexts/useSpecificData";
import generateHtmlOutboundRfq from "./generateHtmlOutboundRfq";
import { sendEmail } from "../../Functions/sendEmail";
import { generatePdf } from "../../Functions/generatePdf";
import { getShipmentItems } from "./getShipmentItems"; // We still need the data for the PDF
import HeaderText from "../../Styled/HeaderText";
import useCurrentUser from "../../hooks/useCurrentUser";
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import dayjs from "dayjs";


/**
 * OutboundRFQ
 * - Left card ONLY shows Freight Agents + Notes (no Project/Origin/Dest/Items).
 * - Right card previews the PDF, which *does* show Project/Origin/Dest/Items.
 * - HeightIn / LengthIn / WidthIn are used in the PDF generation (not shown in the left card).
 */
function OutboundRFQ({ shipment, project }) {
  const { data: freightAgents = [] } = useFreightAgents();
  const { userName, userEmail } = useCurrentUser();
  const today = dayjs().format("YYYY-MM-DD HH:mm:ss");

  // Filter out disabled agents + sort them A–Z
  const filteredAndSortedAgents = useMemo(() => {
    return freightAgents
      .filter((fa) => !fa.disabled)
      .sort((a, b) => (a.companyName || "").localeCompare(b.companyName || ""));
  }, [freightAgents]);

  // State for Freight Agents
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  const [agentExtraContacts, setAgentExtraContacts] = useState({});

  // State for notes (formerly customDescription)
  const [notes, setNotes] = useState("");

  // State for pickup date
  const [pickupDate, setPickupDate] = useState(null);

  // HTML preview & PDF link
  const [htmlPreview, setHtmlPreview] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");

  // Spinner for PDF generation
  const [pdfLoading, setPdfLoading] = useState(false);

  // Pull items from your shipment for the PDF
  const { truckModules, truckPallets } = useMemo(() => {
    if (!project || !shipment?.id) {
      return { truckModules: [], truckPallets: [] };
    }
    return getShipmentItems(project, shipment.id);
  }, [project, shipment?.id]);

  const emptyShipment = !truckModules.length && !truckPallets.length;

  // Prepare array for PDF generation (including ft/in fields)
  const onTruckItems = useMemo(() => {
    const modItems = truckModules.map((m) => ({
      type: "Module",
      id: m.id,
      weight: m.weight,
      weightIn: m.weightIn,
      heightIn: m.heightIn,
      lengthIn: m.lengthIn,
      widthIn: m.widthIn,
      length: m.length,
      width: m.width,
      height: m.height,
      description: project?.projectName + "-" + (m.number ?? "Module"),
    }));
    const palItems = truckPallets.map((p) => ({
      type: "Pallet",
      id: p.id,
      weight: p.weight,
      weightIn: p.weightIn,
      heightIn: p.heightIn,
      lengthIn: p.lengthIn,
      widthIn: p.widthIn,
      length: p.length,
      width: p.width,
      height: p.height,
      description: p.palletName || "Pallet",
    }));
    return [...modItems, ...palItems];
  }, [truckModules, truckPallets, project?.projectName]);

  /**
   * AUTO-GENERATE the HTML preview whenever the shipment, project, items, notes, or pickupDate changes.
   * - Even though we don't display them on the left card, we *do* want them in the PDF preview.
   */
  useEffect(() => {
    const html = generateHtmlOutboundRfq({
      type: shipment?.type || "Unknown Type",
      origin: shipment?.origin?.address || "Unknown Origin",
      destination: shipment?.destination?.address || "Unknown Destination",
      projectName: project?.projectName || "Untitled Project",
      items: onTruckItems,
      customDescription: notes,
      pickupDate: pickupDate ? pickupDate.format("dddd MMMM DD, YYYY") : "🚫",
      tripDistance: shipment.tripDistance || "Unknown",
      shipmentNumber: shipment.shipmentNumber,
    });
    setHtmlPreview(html);
    setPdfUrl(""); // reset PDF link if the preview changed
  }, [shipment, project, onTruckItems, notes, pickupDate]);

  /**
   * Generate PDF
   */
async function handleGeneratePdf(openTab = true) {
  if (!htmlPreview) {
    message.warning("No HTML to generate PDF from!");
    return;
  }

  // If a PDF URL already exists, open it in a new tab.
  if (pdfUrl && openTab) {
    window.open(pdfUrl, "_blank");
    return pdfUrl;
  }

  // Preopen a new tab with a loading message
  let newTab;
  if (openTab) {
    newTab = window.open("", "_blank");
    if (newTab) {
      newTab.document.write(`
        <html>
          <head>
            <title>Generating PDF...</title>
          </head>
          <body>
            <p style="font-family: Arial; text-align: center; margin-top: 20%;">
              Generating your PDF, please wait...
            </p>
          </body>
        </html>
      `);
    }
  }

  setPdfLoading(true);
  try {
    const fileName = `LOGISTICS_RFQ_${project?.projectName}_${shipment.shipmentNumber}_${today}.pdf`;
    const result = await generatePdf(htmlPreview, fileName);
    if (result && result.location) {
      setPdfUrl(result.location);
      message.success("PDF generated successfully!");

      // Update the preopened tab with the generated PDF URL
      if (openTab && newTab) {
        newTab.location = result.location;
      } else if (openTab) {
        window.open(result.location, "_blank");
      }
      return result.location;
    } else {
      message.error("PDF generation failed. No location returned.");
      return null;
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
    message.error("Failed to generate PDF. Check console for details.");
    return null;
  } finally {
    setPdfLoading(false);
  }
}


  /**
   * Send Emails
   */
async function handleSendEmails() {
  const isDev = process.env.REACT_APP_STAGE === "_dev";

  if (!htmlPreview) {
    message.warning("No HTML to send!");
    return;
  }

  let currentPdfUrl = pdfUrl;
  // Automatically generate PDF if not already generated
  if (!currentPdfUrl) {
    currentPdfUrl = await handleGeneratePdf();
    if (!currentPdfUrl) {
      message.error("PDF generation failed. Cannot send emails without PDF.");
      return;
    }
  }

  if (!selectedAgentIds.length) {
    message.warning("No Freight Agents selected!");
    return;
  }

  try {
    for (const agentId of selectedAgentIds) {
      const agent = filteredAndSortedAgents.find((fa) => fa.id === agentId);
      if (!agent) continue;

      // Gather primary and additional contact emails
      const primaryContacts = (agent.contacts || []).filter((c) => c.primary);
      const extras = agentExtraContacts[agentId] || [];
      const extraEmails = (agent.contacts || [])
        .filter((c) => extras.includes(c.email))
        .map((c) => c.email);

      // Use the first valid primary contact for greeting
      const primaryContact = primaryContacts.find(
        (c) => !isMissingContactInfo(c)
      );
      const greetingName = primaryContact
        ? `${primaryContact.firstName}`
        : agent.companyName; // fallback if no valid primary contact

      // Combine primary emails and extra emails
      const primaryEmails = primaryContacts.map((c) => c.email);
      const allEmails = Array.from(new Set([...primaryEmails, ...extraEmails]));
      if (!allEmails.length) {
        message.warning(
          `No contacts selected for ${agent.companyName}. Skipping.`
        );
        continue;
      }

      // Format pickup date and calculate gross weight
      const pickupDateStr = pickupDate
        ? pickupDate.format("dddd MMMM DD, YYYY")
        : "TBD";
      const grossWeight = onTruckItems.reduce(
        (sum, item) => sum + (item.weight || 0),
        0
      );

      // If in development mode, list emails being sent to
      const devEmailsSection = isDev
        ? `<p><strong>Sending to emails:</strong> ${allEmails.join(", ")}</p>`
        : "";

      // Compose email body with requested details
      const emailBody = `
        <p>Hello ${greetingName},</p>
        <p>Please see attached PDF for an RFQ regarding a shipment.</p>
        <p><strong>Pickup Date:</strong> ${pickupDateStr}</p>
        <p><strong>Pickup Address:</strong> ${
          shipment?.origin?.address || "N/A"
        }</p>
        <p><strong>Drop-off Address:</strong> ${
          shipment?.destination?.address || "N/A"
        }</p>
        <p><strong>Gross Weight:</strong> ${grossWeight} lbs</p>
        ${devEmailsSection}
        <p>Thanks,</p>
        <p>${userName}</p>
      `;

      const subject = `BMarko Structures RFQ Request - [${project.projectName}] [${shipment.shipmentNumber}] ${today}`;

      const resp = await sendEmail({
        subject,
        htmlBody: emailBody,
        emailAddresses: allEmails,
        sender: userEmail,
        attachmentUrls: [currentPdfUrl],
        manager: "christina.parker@bmarko.com",
      });

      if (!resp.success) {
        throw new Error(`Failed to send email to agent: ${agent.companyName}`);
      }
    }

    message.success("RFQ Emails sent successfully!");
  } catch (error) {
    console.error("Error sending RFQ emails:", error);
    message.error("Some emails failed. Check console for details.");
  }
}


  // Utility to check missing info
  function isMissingContactInfo(contact) {
    const noEmail = !contact.email;
    const noName =
      (!contact.firstName || !contact.firstName.trim()) &&
      (!contact.lastName || !contact.lastName.trim());
    return noEmail || noName;
  }

  // Determine if we can enable the Send Emails button
  const canSendEmails = useMemo(() => {
    if (!selectedAgentIds.length) return false;
    for (const agentId of selectedAgentIds) {
      const agent = filteredAndSortedAgents.find((fa) => fa.id === agentId);
      if (!agent) continue;
      const validContacts = (agent.contacts || []).filter(
        (c) => !isMissingContactInfo(c)
      );
      if (validContacts.length > 0) {
        return true;
      }
    }
    return false;
  }, [selectedAgentIds, filteredAndSortedAgents]);

  // Freight Agent <Select> options
  const freightAgentOptions = useMemo(() => {
    return filteredAndSortedAgents.map((fa) => ({
      label: fa.companyName,
      value: fa.id,
    }));
  }, [filteredAndSortedAgents]);

  // Handling agent selection
  function handleSelectAgents(values) {
    setSelectedAgentIds(values);
    const updated = { ...agentExtraContacts };
    values.forEach((agentId) => {
      if (!updated[agentId]) {
        updated[agentId] = [];
      }
    });
    setAgentExtraContacts(updated);
  }

  // Checking / unchecking additional contacts
  function handleExtraContactsChange(agentId, checkedValues) {
    setAgentExtraContacts((prev) => ({
      ...prev,
      [agentId]: checkedValues,
    }));
  }

  // Right Card's extra
  const rightCardExtra = (
    <>
      <Button
        icon={<FilePdfOutlined />}
        onClick={handleGeneratePdf}
        style={{ marginRight: 8 }}
        disabled={ emptyShipment}
      >
        Generate PDF
      </Button>
      <Button
        icon={<MailOutlined />}
        type="primary"
        onClick={handleSendEmails}
        disabled={!canSendEmails || !selectedAgentIds.length || emptyShipment}
      >
        Send Emails
      </Button>
    </>
  );

  return (
    <Row gutter={[16, 16]}>
      {/* LEFT SIDE: Freight Agents + Notes + Pickup Date ONLY */}
      <Col xs={24} lg={12}>
        <Card title="Build Request for Quote">
          {/* Pickup Date */}
          <div>
            <HeaderText small text="Pickup Date" />
            <DatePicker
              style={{ width: "100%", marginTop: 8 }}
              value={pickupDate}
              onChange={(date) => setPickupDate(date)}
            />
          </div>

          {/* Freight Agents */}
          <div style={{ marginBottom: 16, marginTop: 16 }}>
            <HeaderText small text="Freight Agents" />
            <Select
              mode="multiple"
              style={{ width: "100%", marginTop: 8 }}
              placeholder="Select Freight Agents"
              value={selectedAgentIds}
              onChange={handleSelectAgents}
              options={freightAgentOptions}
            />
          </div>

          {/* Show selected freight agents + contacts */}
          <Row gutter={[16, 16]}>
            {selectedAgentIds.map((agentId) => {
              const agent = filteredAndSortedAgents.find(
                (fa) => fa.id === agentId
              );
              if (!agent) return null;

              const primaryContacts = (agent.contacts || []).filter(
                (c) => c.primary
              );
              const additionalContacts = (agent.contacts || []).filter(
                (c) => !c.primary
              );

              return (
                <Col key={agent.id} span={12}>
                  <Card size="small" title={agent.companyName}>
                    {/* Primary contact(s) */}
                    {primaryContacts.length > 0 ? (
                      primaryContacts.map((pc) => {
                        if (isMissingContactInfo(pc)) {
                          return (
                            <div
                              key={pc.id}
                              style={{
                                marginBottom: 8,
                                backgroundColor: "#ffe6e6",
                                padding: 4,
                              }}
                            >
                              <Tag color="red" icon={<WarningOutlined />}>
                                No name/email — cannot send
                              </Tag>
                            </div>
                          );
                        }
                        return (
                          <div key={pc.id} style={{ marginBottom: 8 }}>
                            <strong>Primary:</strong>
                            <br />
                            {pc.firstName} {pc.lastName}
                            <br />
                            <small>{pc.email}</small>
                          </div>
                        );
                      })
                    ) : (
                      <i>No primary contact</i>
                    )}

                    {/* Additional contacts in a Checkbox group */}
                    {additionalContacts.length > 0 && (
                      <>
                        <div style={{ fontWeight: 500, marginTop: 8 }}>
                          Additional Contacts:
                        </div>
                        <Checkbox.Group
                          style={{ display: "block", marginTop: 4 }}
                          value={agentExtraContacts[agent.id] || []}
                          onChange={(vals) =>
                            handleExtraContactsChange(agent.id, vals)
                          }
                        >
                          {additionalContacts.map((ac) => {
                            if (isMissingContactInfo(ac)) {
                              return (
                                <div
                                  key={ac.id}
                                  style={{
                                    marginLeft: 16,
                                    marginTop: 4,
                                    backgroundColor: "#ffe6e6",
                                    padding: 4,
                                  }}
                                >
                                  <Tag color="red" icon={<WarningOutlined />}>
                                    No name/email — cannot send
                                  </Tag>
                                </div>
                              );
                            }
                            return (
                              <div
                                key={ac.id}
                                style={{ marginLeft: 16, marginTop: 4 }}
                              >
                                <Checkbox value={ac.email}>
                                  {ac.firstName} {ac.lastName}
                                  <br />
                                  <small>{ac.email}</small>
                                </Checkbox>
                              </div>
                            );
                          })}
                        </Checkbox.Group>
                      </>
                    )}
                  </Card>
                </Col>
              );
            })}
          </Row>

          {/* Notes */}
          <div>
            <HeaderText small text="Notes" />
            <Input.TextArea
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter any special instructions or message here"
              style={{ marginTop: 8 }}
            />
          </div>
        </Card>
      </Col>

      {/* RIGHT SIDE: PDF Preview & Send */}
      <Col xs={24} lg={12}>
        <Card
          title="Preview & Send"
          style={{ height: "100%" }}
          extra={rightCardExtra}
        >
          <Spin spinning={pdfLoading} tip="Generating PDF...">
            {htmlPreview ? (
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: 16,
                  minHeight: 300,
                }}
                dangerouslySetInnerHTML={{ __html: htmlPreview }}
              />
            ) : (
              <div style={{ color: "#999" }}>No preview yet.</div>
            )}
          </Spin>
        </Card>
      </Col>
    </Row>
  );
}

export default OutboundRFQ;
