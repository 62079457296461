import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  DirectionsRenderer,
  useLoadScript,
} from "@react-google-maps/api";
import { Spin, Typography, Steps } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { COLORS } from "../../constants";
import { faBuilding, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bmarkoLogo from "../../images/Small - Black Icon.png";

const { Text } = Typography;
const { Step } = Steps;

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["places"];

function DeliveryRoute({
  originPlaceId,
  destinationPlaceId,
  onRouteCalculated,
}) {
  const [directions, setDirections] = useState(null);
  const [mapError, setMapError] = useState(null);
  const [originAddress, setOriginAddress] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [loadingAddresses, setLoadingAddresses] = useState(true);
  const [totalMiles, setTotalMiles] = useState(null);
  const [daysNeeded, setDaysNeeded] = useState(null);

  const mapRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const fetchDirections = () => {
    if (originPlaceId && destinationPlaceId && isLoaded) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: { placeId: originPlaceId },
          destination: { placeId: destinationPlaceId },
          travelMode: "DRIVING",
        },
        (result, status) => {
          if (status === "OK") {
            setDirections(result);

            const route = result.routes[0];
            let distanceInMeters = 0;
            if (route.legs && route.legs.length > 0) {
              route.legs.forEach((leg) => {
                distanceInMeters += leg.distance.value;
              });
            }
            const distanceInMiles = distanceInMeters / 1609.34;
            setTotalMiles(distanceInMiles.toFixed(2));

            const days = Math.ceil(distanceInMiles / 500);
            setDaysNeeded(days);

            if (onRouteCalculated) {
              onRouteCalculated(distanceInMiles.toFixed(2), days);
            }
          } else {
            setMapError("Could not fetch directions. " + status);
          }
        }
      );
    }
  };

  const fetchAddress = (placeId, setAddress) => {
    if (!mapRef.current || !placeId) {
      setAddress("N/A");
      return;
    }

    const service = new window.google.maps.places.PlacesService(mapRef.current);
    const request = {
      placeId: placeId,
      fields: ["formatted_address"],
    };

    service.getDetails(request, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.formatted_address
      ) {
        setAddress(place.formatted_address);
      } else {
        setAddress("Address not found");
        console.error("Place details request failed due to " + status);
      }
    });
  };

  useEffect(() => {
    if (isLoaded) {
      if (originPlaceId && destinationPlaceId) {
        const dummyMap = new window.google.maps.Map(
          document.createElement("div")
        );
        mapRef.current = dummyMap;

        fetchAddress(originPlaceId, setOriginAddress);
        fetchAddress(destinationPlaceId, setDestinationAddress);

        fetchDirections(); // Fetch directions here
      }
      setLoadingAddresses(false);
    }
  }, [isLoaded, originPlaceId, destinationPlaceId]);

  useEffect(() => {
    setDirections(null);
    setMapError(null);
    setOriginAddress("");
    setDestinationAddress("");
    setTotalMiles(null);
    setDaysNeeded(null);
  }, [originPlaceId, destinationPlaceId]);

  if (loadError) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <StopOutlined style={{ fontSize: "48px", color: COLORS.WARNING }} />
        <Text level={4}>Error loading maps</Text>
      </div>
    );
  }

  if (!isLoaded || loadingAddresses) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={{ lat: 0, lng: 0 }}
        zoom={2}
      >
        {directions && (
          <DirectionsRenderer
            options={{
              directions: directions,
              suppressMarkers: false,
            }}
          />
        )}
      </GoogleMap>

      <div style={{ marginTop: "16px", padding: "0px 16px" }}>
        <Steps direction="horizontal" current={2}>
          <Step
            title="Start"
            icon={
              <img
                src={bmarkoLogo}
                alt="Start Icon"
                style={{ width: 32, height: 32 }}
              />
            }
            description={originAddress || ""}
          />
          <Step
            title="Route"
            icon={<FontAwesomeIcon icon={faTruck} />}
            description={
              totalMiles && daysNeeded ? (
                <div style={{  }}>
                  <Text>
                    <strong>Distance:</strong> {totalMiles} miles
                  </Text>
                  <br />
                  <Text style={{ fontStyle: "italic" }}>500 miles per day</Text>
                  <br />
                  <Text>
                    <strong>Estimated Days:</strong> {daysNeeded}
                  </Text>
                </div>
              ) : (
                ""
              )
            }
          />
          <Step
            title="End"
            icon={<FontAwesomeIcon icon={faBuilding} />}
            description={destinationAddress || ""}
          />
        </Steps>
      </div>

      {mapError && (
        <Text type="danger" style={{ marginTop: "10px", display: "block" }}>
          {mapError}
        </Text>
      )}
    </div>
  );
}

DeliveryRoute.propTypes = {
  originPlaceId: PropTypes.string,
  destinationPlaceId: PropTypes.string,
  onRouteCalculated: PropTypes.func,
};

export default DeliveryRoute;
