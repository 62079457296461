import { useAriaData } from "./AriaDataContext";
import { TABLES } from "../constants";

const createDataHook = (tableName) => () => {
  const { data, loading, error, fetchData } = useAriaData();
  return {
    data: data[tableName] || [],
    loading: loading[tableName] || false,
    error: error[tableName] || null,
    refresh: () => fetchData(tableName),
  };
};

export const useEstimates = createDataHook(TABLES.ESTIMATES);
export const useItems = createDataHook(TABLES.ITEMS);
export const useAssemblies = createDataHook(TABLES.ASSEMBLIES);
export const useVendors = createDataHook(TABLES.VENDORS);
export const useProjects = createDataHook(TABLES.PROJECTS);
export const usePurchaseOrders = createDataHook(TABLES.PURCHASE_ORDERS);
export const useTickets = createDataHook(TABLES.TICKETS)
export const useCustomItems = createDataHook(TABLES.ESTIMATING_CUSTOM_ITEMS);
export const useTransactions = createDataHook(TABLES.TRANSACTIONS);
export const useCustomers = createDataHook(TABLES.CUSTOMERS);
export const useModuleOptions = createDataHook(TABLES.MODULE_OPTIONS);
export const useReminders = createDataHook(TABLES.REMINDERS);
export const useManufacturingLocations = createDataHook(TABLES.MANUFACTURING_LOCATIONS);
export const useFreightAgents = createDataHook(TABLES.FREIGHT_AGENTS);
export const useOutboundShipments = createDataHook(TABLES.OUTBOUND_SHIPMENTS);
export const useInboundShipments = createDataHook(TABLES.INBOUND_SHIPMENTS);

// export const usePurchaseLog = createDataHook(TABLES.PURCHASE_LOG);
// Add more hooks as needed
