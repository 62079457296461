// src/components/PreconCard.jsx

import React, { useState } from "react";
import {
  Badge,
  Button,
  Tag,
  Tooltip,
  Typography,
  Steps,
  Modal,
  Form,
  InputNumber,
  Select,
  DatePicker,
  message,
  Timeline,
} from "antd";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  HistoryOutlined,
  CheckCircleFilled,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faClipboardList,
  faHandHoldingHand,
  faMagnifyingGlass,
  faTag,
  faCheck,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { updateById } from "../../Functions/updateById";
import {
  TABLES,
  PRECON_STATUS_FLOW,
  PRODUCT_TYPES,
    COLORS,
  STATUS_COLORS
} from "../../constants";
import PriceTag from "../../Styled/PriceTag";
import { formatDate } from "../../Formatters/helpers";
import { getFirstAndLastNameFromEmail } from "../../Formatters/getNameFromEmail";
import PropTypes from "prop-types";
import StatusTag from "../../Styled/StatusTag";

const { Text } = Typography;
const { Option } = Select;
const { Step } = Steps;

// Helper function to get FontAwesome icon for a given status
const statusIconMapping = {
  "Issued for Preconstruction": faDownload, // You can choose a different icon if preferred
  Drawing: faPencil,
  "Client Review": faMagnifyingGlass,
  BOM: faClipboardList,
  Materials: faTag,
  Handoff: faHandHoldingHand,
  "Issued for Production": faCheck,
};

const getIconForStatus = (status) => {
  return statusIconMapping[status] || faClipboardList;
};

// Helper function to add business days
const addBusinessDays = (date, days) => {
  let result = dayjs(date);
  let addedDays = 0;
  while (addedDays < days) {
    result = result.add(1, "day");
    if (result.day() !== 0 && result.day() !== 6) {
      addedDays++;
    }
  }
  return result;
};

// Helper functions to disable weekends and future dates
const disableWeekends = (current) => {
  return current && (current.day() === 0 || current.day() === 6);
};

const disableFutureAndWeekend = (current) => {
  return (
    current &&
    (current.day() === 0 ||
      current.day() === 6 ||
      current.isAfter(dayjs(), "day"))
  );
};

function PreconCard({ project, viewOnly = false }) {
  const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [historyProject, setHistoryProject] = useState(null);
  const [moveForm] = Form.useForm();
  const [editForm] = Form.useForm();

  const [moveLoading, setMoveLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  // Determine the target status based on current status and direction
  const getTargetStatus = (currentStatus, direction) => {
    const allStatuses = ["Issued for Preconstruction", ...PRECON_STATUS_FLOW];
    const currentIndex = allStatuses.indexOf(currentStatus);
    if (direction === "forward") {
      return allStatuses[currentIndex + 1] || null;
    } else if (direction === "backward") {
      return allStatuses[currentIndex - 1] || null;
    }
    return null;
  };

  // Open move modal
  const showMoveModal = (direction) => {
    if (viewOnly) return; // Prevent opening modal in view-only mode

    setIsMoveModalVisible(true);
    moveForm.resetFields();
    moveForm.setFieldsValue({ direction: direction }); // Set the direction here

    const targetStatus = getTargetStatus(
      project.preconstruction?.status || "Issued for Preconstruction",
      direction
    );

    if (direction === "forward" && targetStatus === "Drawing") {
      const plannedDays = project.preconstruction?.plannedDays || {};
      moveForm.setFieldsValue({
        plannedDrawingDateComplete: project.preconstruction
          ?.plannedDrawingDateComplete
          ? dayjs(project.preconstruction.plannedDrawingDateComplete)
          : null,
        plannedClientReview: plannedDays.ClientReview || 5,
        plannedBOM: plannedDays.BOM || 5,
        plannedMaterials: plannedDays.Materials || 5,
        plannedHandoff: plannedDays.Handoff || 5,
        amount: project.preconstruction?.amount || 0,
        productType: project.preconstruction?.productType || "",
      });
    }
  };

  // Close move modal
  const handleMoveCancel = () => {
    setIsMoveModalVisible(false);
    moveForm.resetFields();
  };

  // Handle moving the project
  const handleMove = async (values) => {
    setMoveLoading(true);
    const { direction } = values;
    const targetStatus = getTargetStatus(
      project.preconstruction?.status || "Issued for Preconstruction",
      direction
    );

    if (!targetStatus) {
      message.error("Cannot move project beyond the available stages.");
      setMoveLoading(false);
      return;
    }

    try {
      let updatedPrecon = { ...(project.preconstruction || {}) };

      if (!updatedPrecon.history) {
        updatedPrecon.history = [];
      }

      if (direction === "forward") {
        if (targetStatus === "Drawing") {
          updatedPrecon.plannedDays = {
            ClientReview: values.plannedClientReview || 5,
            BOM: values.plannedBOM || 5,
            Materials: values.plannedMaterials || 5,
            Handoff: values.plannedHandoff || 5,
          };
          updatedPrecon.amount = values.amount;
          updatedPrecon.productType = values.productType || "";
          updatedPrecon.plannedDrawingDateComplete =
            values.plannedDrawingDateComplete
              ? values.plannedDrawingDateComplete.format("YYYY-MM-DD")
              : null;

          let previousDate = dayjs(updatedPrecon.plannedDrawingDateComplete);
          updatedPrecon.plannedClientReviewComplete = addBusinessDays(
            previousDate,
            values.plannedClientReview || 5
          ).format("YYYY-MM-DD");

          previousDate = dayjs(updatedPrecon.plannedClientReviewComplete);
          updatedPrecon.plannedBOMComplete = addBusinessDays(
            previousDate,
            values.plannedBOM || 5
          ).format("YYYY-MM-DD");

          previousDate = dayjs(updatedPrecon.plannedBOMComplete);
          updatedPrecon.plannedMaterialsComplete = addBusinessDays(
            previousDate,
            values.plannedMaterials || 5
          ).format("YYYY-MM-DD");

          previousDate = dayjs(updatedPrecon.plannedMaterialsComplete);
          updatedPrecon.plannedHandoffComplete = addBusinessDays(
            previousDate,
            values.plannedHandoff || 5
          ).format("YYYY-MM-DD");

          updatedPrecon.history.push({
            action: `Moved to ${targetStatus}`,
            submittedBy: "User", // Replace with actual user identifier if available
            date: new Date().toISOString(),
          });
        } else if (targetStatus === "Issued for Production") {
          updatedPrecon.actualHandoffComplete = values.actualHandoffComplete
            ? values.actualHandoffComplete.format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD");

          updatedPrecon.history.push({
            action: `Moved to ${targetStatus}`,
            submittedBy: "User",
            date: new Date().toISOString(),
          });
        } else {
          const actualField = `actual${project.preconstruction.status.replace(
            /\s+/g,
            ""
          )}Complete`;
          updatedPrecon[actualField] = values.actualDate
            ? values.actualDate.format("YYYY-MM-DD")
            : null;

          updatedPrecon.history.push({
            action: `Moved to ${targetStatus}`,
            submittedBy: "User",
            date: new Date().toISOString(),
          });
        }
      } else if (direction === "backward") {
        const targetActualField = `actual${targetStatus.replace(
          /\s+/g,
          ""
        )}Complete`;
        delete updatedPrecon[targetActualField];

        if (targetStatus === "Drawing") {
          delete updatedPrecon.plannedDays;
          delete updatedPrecon.amount;
          delete updatedPrecon.productType;
          delete updatedPrecon.plannedDrawingDateComplete;
          delete updatedPrecon.plannedClientReviewComplete;
          delete updatedPrecon.plannedBOMComplete;
          delete updatedPrecon.plannedMaterialsComplete;
          delete updatedPrecon.plannedHandoffComplete;
        }

        updatedPrecon.history.push({
          action: `Moved back to ${targetStatus}`,
          submittedBy: "User",
          date: new Date().toISOString(),
        });
      }

      const updatedProject = {
        ...project,
        preconstruction: {
          ...updatedPrecon,
          status: targetStatus,
          direction: direction,
        },
      };

      await updateById(TABLES.PROJECTS, updatedProject, project.id);
      message.success("Project moved successfully.");
      setIsMoveModalVisible(false);
    } catch (err) {
      console.error(err);
      message.error("Failed to move project.");
    } finally {
      setMoveLoading(false);
    }
  };

  // Open edit modal
  const showEditModal = () => {
    if (viewOnly) return; // Prevent opening modal in view-only mode

    setIsEditModalVisible(true);
    editForm.resetFields();
    const plannedDays = project.preconstruction?.plannedDays || {};
    editForm.setFieldsValue({
      plannedDrawingDateComplete: project.preconstruction
        ?.plannedDrawingDateComplete
        ? dayjs(project.preconstruction.plannedDrawingDateComplete)
        : null,
      plannedClientReview: plannedDays.ClientReview || 5,
      plannedBOM: plannedDays.BOM || 5,
      plannedMaterials: plannedDays.Materials || 5,
      plannedHandoff: plannedDays.Handoff || 5,
      amount: project.preconstruction?.amount || 0,
      productType: project.preconstruction?.productType || "",
    });
  };

  // Close edit modal
  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    editForm.resetFields();
  };

  // Handle editing the project
  const handleEdit = async (values) => {
    setEditLoading(true);
    try {
      let updatedPrecon = { ...(project.preconstruction || {}) };

      if (!updatedPrecon.history) {
        updatedPrecon.history = [];
      }

      let plannedDatesChanged = false;

      const previousDrawingDate = dayjs(
        updatedPrecon.plannedDrawingDateComplete
      );
      const newDrawingDate = values.plannedDrawingDateComplete
        ? dayjs(values.plannedDrawingDateComplete)
        : null;
      if (
        (previousDrawingDate.isValid() &&
          newDrawingDate.isValid() &&
          !previousDrawingDate.isSame(newDrawingDate, "day")) ||
        (!previousDrawingDate.isValid() && newDrawingDate.isValid()) ||
        (previousDrawingDate.isValid() && !newDrawingDate.isValid())
      ) {
        plannedDatesChanged = true;
        }
        
        if(!updatedPrecon.startDate) {
          updatedPrecon.startDate = dayjs().format("YYYY-MM-DD");
        }

      const previousPlannedDays = updatedPrecon.plannedDays || {};
      const newPlannedDays = {
        ClientReview: values.plannedClientReview || 5,
        BOM: values.plannedBOM || 5,
        Materials: values.plannedMaterials || 5,
        Handoff: values.plannedHandoff || 5,
      };

      for (let key in newPlannedDays) {
        if (previousPlannedDays[key] !== newPlannedDays[key]) {
          plannedDatesChanged = true;
          break;
        }
      }

      if (plannedDatesChanged) {
        if (!updatedPrecon.changedDates) {
          updatedPrecon.changedDates = {
            oldPlannedDates: [],
          };
        } else if (!updatedPrecon.changedDates.oldPlannedDates) {
          updatedPrecon.changedDates.oldPlannedDates = [];
        }

        const oldPlannedDateEntry = {
          plannedDrawingDateComplete: updatedPrecon.plannedDrawingDateComplete,
          plannedDays: { ...updatedPrecon.plannedDays },
        };

        updatedPrecon.changedDates.oldPlannedDates.push(oldPlannedDateEntry);
      }

      if (values.plannedDrawingDateComplete) {
        updatedPrecon.plannedDrawingDateComplete =
          values.plannedDrawingDateComplete.format("YYYY-MM-DD");
      }

      updatedPrecon.amount = values.amount;
      updatedPrecon.productType = values.productType;

      updatedPrecon.plannedDays = newPlannedDays;

      if (updatedPrecon.plannedDrawingDateComplete) {
        let previousDate = dayjs(updatedPrecon.plannedDrawingDateComplete);
        updatedPrecon.plannedClientReviewComplete = addBusinessDays(
          previousDate,
          values.plannedClientReview || 5
        ).format("YYYY-MM-DD");

        previousDate = dayjs(updatedPrecon.plannedClientReviewComplete);
        updatedPrecon.plannedBOMComplete = addBusinessDays(
          previousDate,
          values.plannedBOM || 5
        ).format("YYYY-MM-DD");

        previousDate = dayjs(updatedPrecon.plannedBOMComplete);
        updatedPrecon.plannedMaterialsComplete = addBusinessDays(
          previousDate,
          values.plannedMaterials || 5
        ).format("YYYY-MM-DD");

        previousDate = dayjs(updatedPrecon.plannedMaterialsComplete);
        updatedPrecon.plannedHandoffComplete = addBusinessDays(
          previousDate,
          values.plannedHandoff || 5
        ).format("YYYY-MM-DD");
      }

      if (plannedDatesChanged) {
        updatedPrecon.history.push({
          action: "Planned dates changed",
          submittedBy: "User",
          date: new Date().toISOString(),
        });
      }

      const updatedProject = {
        ...project,
        preconstruction: updatedPrecon,
      };

      await updateById(TABLES.PROJECTS, updatedProject, project.id);
      message.success(
        "Planned days, drawing date, amount, and product type updated successfully. Planned dates have been recorded in the history."
      );
      setIsEditModalVisible(false);
    } catch (err) {
      console.error(err);
      message.error("Failed to update project details.");
    } finally {
      setEditLoading(false);
    }
  };

  // Open history modal
  const showHistoryModal = () => {
    setIsHistoryModalVisible(true);
    setHistoryProject(project);
  };

  // Close history modal
  const handleHistoryCancel = () => {
    setIsHistoryModalVisible(false);
    setHistoryProject(null);
  };

  // Calculate planned completion dates based on plannedDrawingDateComplete and planned days
  const calculatePlannedDates = (project) => {
    if (
      !project.preconstruction?.plannedDays ||
      !project.preconstruction.plannedDrawingDateComplete
    ) {
      return {};
    }

    const plannedDates = {
      PlannedDrawingDateComplete:
        project.preconstruction.plannedDrawingDateComplete,
      PlannedClientReviewComplete:
        project.preconstruction.plannedClientReviewComplete,
      PlannedBOMComplete: project.preconstruction.plannedBOMComplete,
      PlannedMaterialsComplete:
        project.preconstruction.plannedMaterialsComplete,
      PlannedHandoffComplete: project.preconstruction.plannedHandoffComplete,
    };

    return plannedDates;
  };

  const precon = project.preconstruction || {
    status: "Issued for Preconstruction",
    clientDelay: false,
    history: [],
    plannedDays: {},
    amount: 0,
    productType: "",
  };

  const isIssuedForProduction = precon.status === "Issued for Production";
  const isDelayed = precon.clientDelay;
  const isIssuedForPreconstruction = !project.preconstruction;

  let ribbonText = "";
  let ribbonColor = "";
  if (isDelayed) {
    ribbonText = "CLIENT DELAY";
    ribbonColor = "red";
  } else if (isIssuedForProduction) {
    ribbonText = "ISSUED FOR PRODUCTION";
    ribbonColor = "#3f8600";
  } else if (isIssuedForPreconstruction) {
    ribbonText = "ISSUED FOR PRECONSTRUCTION";
    ribbonColor = "gray";
  }

  // Exclude "Issued for Production" from the timeline
  const timelineStatuses = PRECON_STATUS_FLOW.filter(
    (status) => status !== "Issued for Production"
  );

  // Determine the current step index in the timeline
  const currentStepIndex = timelineStatuses.indexOf(precon.status);

  // Calculate planned dates
  const plannedDates = calculatePlannedDates(project);

  return (
    <Badge.Ribbon
      text={ribbonText}
      color={ribbonColor}
      style={{
        display: ribbonText ? "inline-block" : "none",
        zIndex: 5,
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          border: "1px solid #f0f0f0",
          borderRadius: 8,
          marginBottom: 8,
          padding: 16,
          backgroundColor: isDelayed
            ? "rgba(255, 0, 0, 0.1)"
            : isIssuedForPreconstruction
            ? "rgba(128, 128, 128, 0.1)"
            : isIssuedForProduction
            ? "rgba(0, 128, 0, 0.1)"
            : "#fff",
          width: "100%",
          cursor: isDelayed && !viewOnly ? "pointer" : "default", // Only pointer if not viewOnly
        }}
        className="box-shadow"
        onClick={() => {
          if (isDelayed && !viewOnly) {
            Modal.confirm({
              title: "Remove Client Delay",
              icon: <ExclamationCircleOutlined />,
              content:
                "Are you sure you want to remove the client delay from this project?",
              onOk: async () => {
                try {
                  const updatedPrecon = {
                    ...(project.preconstruction || {}),
                    clientDelay: false,
                    history: [
                      ...(project.preconstruction?.history || []),
                      {
                        action: "Client Delay Removed",
                        submittedBy: "User",
                        date: new Date().toISOString(),
                      },
                    ],
                  };

                  const updatedProject = {
                    ...project,
                    preconstruction: updatedPrecon,
                  };

                  await updateById(TABLES.PROJECTS, updatedProject, project.id);
                  message.success("Client delay removed successfully.");
                  // Parent component should refresh and pass updated props
                } catch (err) {
                  console.error(err);
                  message.error("Failed to remove client delay.");
                }
              },
            });
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* Project Details and Buttons */}
          <div
            style={{
              width: "350px",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              zIndex: 2,
            }}
          >
            {/* Tags and Buttons Container */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* Project Name Tag */}
                {project.projectName &&
                  (viewOnly ? (
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginRight: 8,
                        background: COLORS.PRIMARY,
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        cursor: "default",
                      }}
                    >
                      {project.projectName}
                    </div>
                  ) : (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        "/project-management/projects/" +
                        project.id +
                        "/preconstruction"
                      }
                      style={{ textDecoration: "none" }} // Remove underline
                    >
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          marginRight: 8,
                          background: COLORS.PRIMARY,
                          color: "white",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        {project.projectName}
                      </div>
                    </a>
                  ))}
                {/* Price Tag */}
                {precon.amount !== undefined && (
                  <PriceTag medium amount={precon.amount} />
                )}
                {/* Product Type Tag */}
                {precon.productType && precon.productType !== undefined && (
                  <Tag
                    style={{
                      marginLeft: project.projectName ? 8 : 0,
                      fontSize: "14px",
                    }}
                  >
                    {precon.productType}
                  </Tag>
                )}
              </div>

              {/* Buttons Container */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                {/* History Button - Always Visible */}
                {precon.history && precon.history.length > 0 && (
                  <Tooltip title="View History">
                    <Button
                      type="link"
                      icon={<HistoryOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        showHistoryModal();
                      }}
                      style={{ marginRight: 8 }}
                    />
                  </Tooltip>
                )}

                {/* Edit Button - Hidden in viewOnly */}
                {!viewOnly && !isIssuedForPreconstruction && (
                  <Tooltip title="Edit Project">
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      disabled={isDelayed}
                      onClick={(e) => {
                        e.stopPropagation();
                        showEditModal();
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>

            <div style={{ margin: "8px 0", background: "transparent" }} />

            {/* Action Buttons and Status Tag */}
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Move Backward Button - Hidden in viewOnly */}
              {!viewOnly &&
                PRECON_STATUS_FLOW.includes(precon.status) &&
                PRECON_STATUS_FLOW.indexOf(precon.status) >= 1 && (
                  <Tooltip
                    title={`Move to ${getTargetStatus(
                      precon.status,
                      "backward"
                    )}`}
                  >
                    <Button
                      type="default"
                      icon={<ArrowLeftOutlined />}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        showMoveModal("backward");
                      }}
                      disabled={isDelayed || isIssuedForPreconstruction}
                      style={{ marginRight: 8 }}
                    />
                  </Tooltip>
                )}

              {/* Status Tag */}
<StatusTag status={precon.status} />

              {/* Move Forward Button - Hidden in viewOnly */}
              {!viewOnly && precon.status !== "Issued for Production" && (
                <Tooltip
                  title={`Move to ${getTargetStatus(precon.status, "forward")}`}
                >
                  <Button
                    icon={<ArrowRightOutlined />}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      showMoveModal("forward");
                    }}
                    disabled={isDelayed}
                    style={{ marginLeft: 8 }}
                  />
                </Tooltip>
              )}
            </div>
          </div>

          {/* Timeline Excluding "Issued for Production" */}
          <div style={{ flex: 1, marginLeft: "20px", zIndex: 2 }}>
            <Steps
              size="small"
              direction="horizontal"
              current={currentStepIndex}
              style={{ width: "100%" }}
            >
              {timelineStatuses.map((status, index) => {
                if (status === "Issued for Production") return null; // Exclude from timeline

                // Conditional field name construction for "Drawing"
                const plannedField =
                  status === "Drawing"
                    ? "plannedDrawingDateComplete"
                    : `planned${status.replace(/\s+/g, "")}Complete`;
                const actualField = `actual${status.replace(
                  /\s+/g,
                  ""
                )}Complete`;

                // Parse dates using dayjs
                const plannedDate = precon[plannedField]
                  ? dayjs(precon[plannedField])
                  : null;
                const actualDate = precon[actualField]
                  ? dayjs(precon[actualField])
                  : null;

                // Build description
                const description = plannedDate ? (
                  <>
                    <span style={{ fontSize: 12 }}>
                      Planned: {formatDate(plannedDate)}
                    </span>
                    <br />
                    <span style={{ fontSize: 12 }}>
                      Actual: {actualDate ? formatDate(actualDate) : "N/A"}
                    </span>
                  </>
                ) : (
                  <>
                    <span style={{ fontSize: 12 }}>Planned: N/A</span>
                    <br />
                    <span style={{ fontSize: 12 }}>Actual: N/A</span>
                  </>
                );

                // Determine step status
                let stepStatus = "process";
                if (precon.status === "Issued for Production") {
                  stepStatus = "finish";
                } else if (actualDate) {
                  stepStatus = "finish";
                } else if (index === currentStepIndex) {
                  stepStatus = "process";
                } else {
                  stepStatus = "wait";
                }

                // Conditional styling for step titles
                const titleStyle =
                  index === currentStepIndex
                    ? { fontWeight: "bold" } // Current step = bold
                    : index < currentStepIndex
                    ? { color: "black" } // Completed steps (behind current) = lightgray
                    : { color: "black" }; // Steps ahead of current = black

                const descriptionStyle =
                  index === currentStepIndex
                    ? { fontWeight: "bold" }
                    : index < currentStepIndex
                    ? {}
                    : { color: "gray" };

                // Determine the icon based on the status and stepStatus
                let iconElement = null;
                const icon = getIconForStatus(status);

                if (stepStatus === "finish") {
                  iconElement = (
                    <CheckCircleFilled style={{ color: COLORS.PRIMARY }} />
                  );
                } else if (stepStatus === "process") {
                  iconElement = (
                    <FontAwesomeIcon
                      icon={icon}
                      className={
                        index === currentStepIndex ? "animated-icon" : ""
                      }
                      style={{
                        color: COLORS.PRIMARY,
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    />
                  );
                } else {
                  iconElement = (
                    <FontAwesomeIcon
                      icon={icon}
                      style={{ color: "gray", opacity: 0.5 }}
                    />
                  );
                }

                return (
                  <Step
                    key={status}
                    title={<span style={titleStyle}>{status}</span>}
                    description={
                      <span style={descriptionStyle}>{description}</span>
                    }
                    status={stepStatus}
                    icon={iconElement}
                    style={{
                      pointerEvents: "none", // Make steps non-interactive
                    }}
                  />
                );
              })}
            </Steps>
          </div>
        </div>
      </div>

      {/* History Modal - Preserved <Timeline> Component */}
      <Modal
        visible={isHistoryModalVisible}
        title={`Preconstruction Timeline - ${project.projectName || ""}`}
        onCancel={handleHistoryCancel}
        footer={[
          <Button key="close" onClick={handleHistoryCancel}>
            Close
          </Button>,
        ]}
        destroyOnClose
      >
        {project && project.preconstruction?.history && (
          <div
            style={{
              marginBottom: 16,
              textAlign: "left", // Ensure text is aligned to the left
              display: "flex",
              justifyContent: "flex-start", // Align items to the start (left)
              width: "100%", // Take full width of the Modal
            }}
          >
            <Timeline
              mode="left" // Align Timeline to the left
              style={{
                marginTop: 20,
                width: "100%", // Ensure Timeline takes the full width of its container
              }}
              items={project.preconstruction.history
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((event, index) => ({
                  key: index,
                  children: (
                    <>
                      <Text strong>{event.action}</Text>
                      <br />
                      <Text type="secondary">
                        {getFirstAndLastNameFromEmail(event.submittedBy)}
                      </Text>
                      <br />
                      <Text type="secondary">
                        {dayjs(event.date).format("MMMM D, YYYY h:mm A")}
                      </Text>
                    </>
                  ),
                  color: event.action.includes("Moved")
                    ? "blue"
                    : event.action === "Client Delay"
                    ? "red"
                    : "green",
                }))}
            />
          </div>
        )}
        {(!project || !project.preconstruction?.history.length > 0) && (
          <Text>No history available for this project.</Text>
        )}
      </Modal>

      {/* Move Modal - Hidden in viewOnly */}
      {!viewOnly && (
        <Modal
          visible={isMoveModalVisible}
          title={`Move Project - ${project?.projectName}`}
          onCancel={handleMoveCancel}
          footer={null}
          destroyOnClose
        >
          <Form form={moveForm} layout="vertical" onFinish={handleMove}>
            {project && (
              <>
                {/* Moving Forward to "Drawing" */}
                {moveForm.getFieldValue("direction") === "forward" &&
                  getTargetStatus(precon.status, "forward") === "Drawing" && (
                    <>
                      {/* Project Amount */}
                      <Form.Item
                        name="amount"
                        label="Project Amount"
                        rules={[
                          { required: true, message: "Please enter an amount" },
                          {
                            type: "number",
                            min: 0,
                            message: "Amount must be a positive number",
                          },
                        ]}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0}
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>

                      {/* Product Type */}
                      <Form.Item
                        name="productType"
                        label="Product Type"
                        rules={[
                          {
                            required: true,
                            message: "Please select a product type",
                          },
                        ]}
                      >
                        <Select placeholder="Select product type">
                          {PRODUCT_TYPES.map((type) => (
                            <Option key={type} value={type}>
                              {type}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* Planned Drawing Date Complete */}
                      <Form.Item
                        name="plannedDrawingDateComplete"
                        label="Planned Drawing Date Complete"
                        rules={[
                          {
                            required: true,
                            message: "Please select the planned drawing date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          disabledDate={disableWeekends}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>

                      {/* Planned Days for All Steps */}
                      {PRECON_STATUS_FLOW.map((status) => {
                        if (
                          status === "Drawing" ||
                          status === "Issued for Production"
                        )
                          return null;
                        return (
                          <Form.Item
                            key={`planned${status.replace(/\s+/g, "")}`}
                            name={`planned${status.replace(/\s+/g, "")}`}
                            label={`Planned ${status} Complete`}
                            rules={[
                              {
                                required: true,
                                message: `Please enter business days for ${status}`,
                              },
                            ]}
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              addonAfter="Business Days"
                              type="number"
                              placeholder="Enter number of business days"
                            />
                          </Form.Item>
                        );
                      })}
                    </>
                  )}

                {/* Moving Forward to "Issued for Production" */}
                {moveForm.getFieldValue("direction") === "forward" &&
                  getTargetStatus(precon.status, "forward") ===
                    "Issued for Production" && (
                    <>
                      {/* Actual Completion Date for Handoff */}
                      <Form.Item
                        name="actualHandoffComplete"
                        label="Actual Handoff Completion Date"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please select the actual handoff completion date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          disabledDate={disableFutureAndWeekend}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                    </>
                  )}

                {/* Moving Forward to Other Statuses */}
                {moveForm.getFieldValue("direction") === "forward" &&
                  getTargetStatus(precon.status, "forward") !== "Drawing" &&
                  getTargetStatus(precon.status, "forward") !==
                    "Issued for Production" && (
                    <Form.Item
                      name="actualDate"
                      label={`Actual Completion Date for ${precon.status}`}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the actual date",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        disabledDate={disableFutureAndWeekend}
                        format="YYYY-MM-DD"
                      />
                    </Form.Item>
                  )}

                {/* Moving Backward */}
                {moveForm.getFieldValue("direction") === "backward" && (
                  <>
                    {/* Confirmation Text */}
                    <div style={{ marginBottom: 16 }}>
                      Are you sure you want to move the project back to{" "}
                      {getTargetStatus(precon.status, "backward")}?
                    </div>
                  </>
                )}
              </>
            )}

            {/* Hidden Field for Direction */}
            <Form.Item name="direction" initialValue="forward" hidden>
              <Select>
                <Option value="forward">Forward</Option>
                <Option value="backward">Backward</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 16,
                }}
              >
                <Button
                  onClick={handleMoveCancel}
                  style={{ marginRight: 8 }}
                  disabled={moveLoading} // Disable while loading
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={moveLoading}>
                  {moveForm.getFieldValue("direction") === "forward"
                    ? getTargetStatus(precon.status, "forward") ===
                      "Issued for Production"
                      ? "Complete"
                      : `Move to ${getTargetStatus(precon.status, "forward")}`
                    : "Move Backward"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}

      {/* Edit Modal - Hidden in viewOnly */}
      {!viewOnly && (
        <Modal
          visible={isEditModalVisible}
          title={`Edit Project - ${project?.projectName}`}
          onCancel={handleEditCancel}
          footer={null}
          destroyOnClose
        >
          <Form form={editForm} layout="vertical" onFinish={handleEdit}>
            {project && (
              <>
                {/* Project Amount */}
                <Form.Item
                  name="amount"
                  label="Project Amount"
                  rules={[
                    { required: true, message: "Please enter an amount" },
                    {
                      type: "number",
                      min: 0,
                      message: "Amount must be a positive number",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>

                {/* Product Type */}
                <Form.Item
                  name="productType"
                  label="Product Type"
                  rules={[
                    {
                      required: true,
                      message: "Please select a product type",
                    },
                  ]}
                >
                  <Select placeholder="Select product type">
                    {PRODUCT_TYPES.map((type) => (
                      <Option key={type} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* Planned Drawing Date Complete */}
                <Form.Item
                  name="plannedDrawingDateComplete"
                  label="Planned Drawing Date Complete"
                  rules={[
                    {
                      required: true,
                      message: "Please select the planned drawing date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={disableWeekends}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>

                {/* Planned Days for All Steps */}
                {PRECON_STATUS_FLOW.map((status) => {
                  if (
                    status === "Drawing" ||
                    status === "Issued for Production"
                  )
                    return null;
                  return (
                    <Form.Item
                      key={`planned${status.replace(/\s+/g, "")}`}
                      name={`planned${status.replace(/\s+/g, "")}`}
                      label={`Planned ${status} Complete`}
                      rules={[
                        {
                          required: true,
                          message: `Please enter business days for ${status}`,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        addonAfter="Business Days"
                        type="number"
                        placeholder="Enter number of business days"
                      />
                    </Form.Item>
                  );
                })}
              </>
            )}

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={handleEditCancel}
                  style={{ marginRight: 8 }}
                  disabled={editLoading} // Disable while loading
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={editLoading}>
                  Save Changes
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Badge.Ribbon>
  );
}

// Define PropTypes for better type checking
PreconCard.propTypes = {
  project: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool,
};

export default PreconCard;
