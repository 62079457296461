import React, { useState, useMemo } from "react";
import { Table, Row, Col, Button, Tag } from "antd"; // Import Tag here
import { PlusOutlined } from "@ant-design/icons";
import HeaderText from "../../Styled/HeaderText";
import { SearchInput } from "../../Styled/SearchInput";
import AddOutboundShipmentModal from "../../Components/Logistics/AddOutboundShipmentModal";
import {
  useOutboundShipments,
  useManufacturingLocations,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getShipmentItems } from "../../Components/Logistics/getShipmentItems"; // Correct import path

function OutboundShipments() {
  const { data: projects } = useProjects();
  const { data: shipments } = useOutboundShipments();
  const { data: manufacturingLocations } = useManufacturingLocations();

  const [modalVisible, setModalVisible] = useState(false);
  const [editingShipment, setEditingShipment] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Global search query

  // Prepare filter options for Pickup Location
  const pickupLocationFilters = useMemo(() => {
    const locations = new Set();
    shipments.forEach((sh) => {
      const loc = sh.origin?.nickname || sh.origin?.address;
      if (loc) locations.add(loc);
    });
    return Array.from(locations).map((loc) => ({ text: loc, value: loc }));
  }, [shipments]);

  // Prepare filter options for Project using only projects present in shipments
  const projectFilters = useMemo(() => {
    const projectIds = new Set(
      shipments.map((sh) => sh.projectId).filter(Boolean)
    );
    return projects
      .filter((p) => projectIds.has(p.id))
      .map((p) => ({ text: p.projectName, value: p.id }));
  }, [projects, shipments]);

  // Define table columns for shipment data
  const columns = [
    {
      title: "Shipment ID",
      dataIndex: "shipmentNumber",
      key: "shipmentNumber",
      render: (text, record) => (
        <a href={`/logistics/outbound/${record.id}/freight`}>{text}</a>
      ),
    },
    {
      title: "Pickup Location",
      dataIndex: ["origin", "nickname"],
      key: "startAddress",
      render: (text) => text || "N/A",
      filters: pickupLocationFilters,
      onFilter: (value, record) => {
        const loc = record.origin?.nickname || record.origin?.address || "";
        return loc === value;
      },
    },
    {
      title: "Dropoff Address",
      dataIndex: ["destination", "address"],
      key: "endAddress",
      render: (text) => text || "N/A",
    },
    {
      title: "Distance",
      dataIndex: "tripDistance",
      key: "tripDistance",
      render: (distance) => (distance ? `${distance} miles` : "N/A"),
    },
    {
      title: "Estimated Days",
      dataIndex: "estimatedDays",
      key: "estimatedDays",
      render: (days) => (days ? `${days} days` : "N/A"),
    },
    {
      title: "Project",
      dataIndex: "projectId",
      key: "projectId",
      render: (projectId) => {
        const project = projects.find((p) => p.id === projectId);
        return project ? project.projectName : "No Project";
      },
      filters: projectFilters,
      onFilter: (value, record) => record.projectId === value,
    },
    {
      title: "Freight",
      key: "onTruck",
      render: (text, record) => {
        const shipmentProject = projects.find((p) => p.id === record.projectId);
        if (!shipmentProject) {
          return <Tag color="red">No Project</Tag>;
        }
        const { truckModules, truckPallets } = getShipmentItems(
          shipmentProject,
          record.id
        );
        return (
          <>
            {truckModules.map((module) => (
              <Tag color="blue" key={`module-${module.id}`}>
                {shipmentProject.projectName +
                  "-" +
                  (module.number || module.id)}
              </Tag>
            ))}
            {truckPallets.map((pallet) => (
              <Tag color="purple" key={`pallet-${pallet.id}`}>
                {pallet.palletName || pallet.id}
              </Tag>
            ))}
          </>
        );
      },
    },
  ];

  // Global search filter across multiple columns including Freight
  const filteredShipments = useMemo(() => {
    if (!searchQuery) return shipments;
    const q = searchQuery.toLowerCase();
    return shipments.filter((sh) => {
      const project = projects.find((p) => p.id === sh.projectId);
      const projectName = project ? project.projectName.toLowerCase() : "";
      const shipmentId = (sh.shipmentNumber || "").toLowerCase();
      const pickupLocation = (
        sh.origin?.nickname ||
        sh.origin?.address ||
        ""
      ).toLowerCase();
      const dropoffAddress = (sh.destination?.address || "").toLowerCase();

      let freightMatch = false;
      if (project) {
        const { truckModules, truckPallets } = getShipmentItems(project, sh.id);
        freightMatch = [...truckModules, ...truckPallets].some((item) => {
          const val = (item.number || item.palletName || "").toLowerCase();
          return val.includes(q);
        });
      }

      return (
        shipmentId.includes(q) ||
        projectName.includes(q) ||
        pickupLocation.includes(q) ||
        dropoffAddress.includes(q) ||
        freightMatch
      );
    });
  }, [searchQuery, shipments, projects]);

  return (
    <Row gutter={[0, 16]}>
      <Col span={12}>
        <HeaderText text={"Outbound Shipments"} />
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setEditingShipment(null);
            setModalVisible(true);
          }}
        >
          Add Outbound Shipment
        </Button>
      </Col>
      <Col span={24}>
        <SearchInput
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Col>
      <Col span={24}>
        <Table
          dataSource={filteredShipments}
          columns={columns}
          rowKey="id"
          pagination={{ pageSize: 10 }}
        />
      </Col>

      {modalVisible && (
        <AddOutboundShipmentModal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          shipment={editingShipment}
          manufacturingLocations={manufacturingLocations || []}
          existingShipments={shipments || []}
        />
      )}
    </Row>
  );
}

export default OutboundShipments;
