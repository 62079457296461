import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tag,
  Button,
  Collapse,
  Modal,
  Checkbox,
  Input,
  message,
  Divider,
} from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  StopOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  IconRulerMeasure,
  IconRulerMeasure2,
  IconWeight,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";

import flatbedTruck from "../../images/FlatbedTruck4.png";
import { TABLES } from "../../constants";
import { updateById } from "../../Functions/updateById";
import DimensionsCell from "../../Styled/DimensionCell";
import { createReminder } from "../../Functions/reminders/addReminder";
import { useUser } from "@clerk/clerk-react";
import dayjs from "dayjs";

const { Panel } = Collapse;

function OutboundFreight({ shipment, project, allShipments = [] }) {
  const [modules, setModules] = useState([]);
  const [pallets, setPallets] = useState([]);

  // For calculating total weight on *this* shipment:
  const [truckModules, setTruckModules] = useState([]);
  const [truckPallets, setTruckPallets] = useState([]);
  const user = useUser();
  const userEmail = user.user?.primaryEmailAddress?.emailAddress;
  const userName = user.user?.fullName;

  // New states for Unverified functionality
  const [isUnverifiedModalVisible, setIsUnverifiedModalVisible] =
    useState(false);
  const [selectedUnverifiedIds, setSelectedUnverifiedIds] = useState([]);
  const [unverifiedOptions, setUnverifiedOptions] = useState([]);
  const [reminderNotes, setReminderNotes] = useState("");

  // Track whether we've shown the vertical labels for modules/pallets:
  let hasShownModuleLabel = false;
  let hasShownPalletLabel = false;

  /**
   * On mount (or when project changes), initialize local state:
   *   - modules have { onTruck, shipmentId }
   *   - pallets have { onTruck, shipmentId }
   */
  useEffect(() => {
    if (project) {
      // Prepare modules
      const projectModules = (project.modules || []).map((m) => ({
        ...m,
        onTruck: m.onTruck || false,
        shipmentId: m.shipmentId || null,
      }));

      // Prepare pallets
      const shipLoosePallets = project.shipLoose?.pallets || [];
      const projectPallets = shipLoosePallets.map((pallet) => ({
        ...pallet,
        onTruck: pallet.onTruck || false,
        shipmentId: pallet.shipmentId || null,
        assignedItems:
          pallet.assignedItems?.map((item) => {
            const projItem = project.shipLoose?.items?.find(
              (i) => i.id === item.itemId
            );
            return {
              ...item,
              itemDescription: projItem?.itemDescription || "Unknown Item",
            };
          }) || [],
      }));

      setModules(projectModules);
      setPallets(projectPallets);

      // Figure out which modules/pallets belong to THIS shipment
      const initialTruckModules = projectModules.filter(
        (mod) => mod.onTruck && mod.shipmentId === shipment.id
      );
      const initialTruckPallets = projectPallets.filter(
        (pal) => pal.onTruck && pal.shipmentId === shipment.id
      );
      setTruckModules(initialTruckModules);
      setTruckPallets(initialTruckPallets);
    }
  }, [project, shipment.id]);

  /**
   * Utility: find a shipment’s shipmentNumber by ID, or "Unknown" if not found.
   */
  function findShipmentNumberById(id) {
    const found = allShipments.find((s) => s.id === id);
    if (!found) {
      console.warn(
        `Shipment with id=${id} not found in allShipments`,
        allShipments
      );
      return "Unknown";
    }
    return found.shipmentNumber || "Unknown";
  }

  /** Add a Module to this shipment */
  async function handleAddModuleToTruck(module) {
    if (truckModules.some((m) => m.id === module.id)) return;

    const updatedTruckModule = {
      ...module,
      onTruck: true,
      shipmentId: shipment.id,
      added: new Date().toISOString(),
    };
    setTruckModules([...truckModules, updatedTruckModule]);

    const updatedModules = modules.map((m) =>
      m.id === module.id ? { ...m, onTruck: true, shipmentId: shipment.id } : m
    );
    setModules(updatedModules);

    try {
      await updateById(
        TABLES.PROJECTS,
        { modules: updatedModules },
        project.id
      );
    } catch (error) {
      console.error("Error updating project modules:", error);
    }

    try {
      const updatedTruckModuleIds = [
        ...(shipment.truckModules || []),
        module.id,
      ];
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { truckModules: updatedTruckModuleIds },
        shipment.id
      );
    } catch (error) {
      console.error("Error updating outbound shipment (modules):", error);
    }
  }

  /** Remove a Module from this shipment */
  async function handleRemoveModuleFromTruck(moduleId) {
    setTruckModules(truckModules.filter((m) => m.id !== moduleId));

    const updatedModules = modules.map((m) =>
      m.id === moduleId ? { ...m, onTruck: false, shipmentId: null } : m
    );
    setModules(updatedModules);

    try {
      await updateById(
        TABLES.PROJECTS,
        { modules: updatedModules },
        project.id
      );
    } catch (error) {
      console.error("Error updating project modules:", error);
    }

    try {
      const updatedTruckModules = (shipment.truckModules || []).filter(
        (id) => id !== moduleId
      );
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { truckModules: updatedTruckModules },
        shipment.id
      );
    } catch (error) {
      console.error("Error updating outbound shipment (modules):", error);
    }
  }

  /** Add a Pallet to this shipment */
  async function handleAddPalletToTruck(pallet) {
    if (truckPallets.some((p) => p.id === pallet.id)) return;

    const updatedTruckPallet = {
      ...pallet,
      onTruck: true,
      shipmentId: shipment.id,
      added: new Date().toISOString(),
    };
    setTruckPallets([...truckPallets, updatedTruckPallet]);

    const updatedPallets = pallets.map((p) =>
      p.id === pallet.id ? { ...p, onTruck: true, shipmentId: shipment.id } : p
    );
    setPallets(updatedPallets);

    try {
      await updateById(
        TABLES.PROJECTS,
        {
          shipLoose: {
            ...(project.shipLoose || {}),
            pallets: updatedPallets,
          },
        },
        project.id
      );
    } catch (error) {
      console.error("Error updating project pallets:", error);
    }

    try {
      const updatedTruckPalletIds = [
        ...(shipment.truckPallets || []),
        pallet.id,
      ];
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { truckPallets: updatedTruckPalletIds },
        shipment.id
      );
    } catch (error) {
      console.error("Error updating outbound shipment (pallets):", error);
    }
  }

  /** Remove a Pallet from this shipment */
  async function handleRemovePalletFromTruck(palletId) {
    setTruckPallets(truckPallets.filter((p) => p.id !== palletId));

    const updatedPallets = pallets.map((p) =>
      p.id === palletId ? { ...p, onTruck: false, shipmentId: null } : p
    );
    setPallets(updatedPallets);

    try {
      await updateById(
        TABLES.PROJECTS,
        {
          shipLoose: {
            ...(project.shipLoose || {}),
            pallets: updatedPallets,
          },
        },
        project.id
      );
    } catch (error) {
      console.error("Error updating project pallets:", error);
    }

    try {
      const updatedTruckPalletIds = (shipment.truckPallets || []).filter(
        (id) => id !== palletId
      );
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { truckPallets: updatedTruckPalletIds },
        shipment.id
      );
    } catch (error) {
      console.error("Error updating outbound shipment (pallets):", error);
    }
  }



  function getMissingAttributes(item) {
    const missing = [];
    if (item.length == null) missing.push("length");
    if (item.width == null) missing.push("width");
    if (item.height == null) missing.push("height");
    if (!item.weight) missing.push("weight");
    return missing;
  }

  function renderModuleTag(mod) {
    return (
      <Tag color="blue">
        {`${project?.projectName || "Project"}-${mod.number || "-"}`}
      </Tag>
    );
  }

  function renderPalletTag(pal) {
    return <Tag color="purple">{pal.palletName}</Tag>;
  }

  function renderExtraContent(record) {
    const missingAttrs = getMissingAttributes(record);

    // New logic for unverified modules: red tag indicating "Unverified"
    if (record.number && !record.dimensionsVerified) {
      return (
        <Tag
          color="red"
          style={{ cursor: "pointer" }}
          onClick={() => openUnverifiedModal(record.id)}
        >
          Unverified
        </Tag>
      );
    }

    // If missing ANY required dimension or weight => hide "Add" button
    if (missingAttrs.length > 0) {
      return null;
    }

    // If already on a shipment:
    if (record.onTruck && record.shipmentId) {
      if (record.shipmentId === shipment.id) {
        return (
          <Tag
            icon={<CheckCircleOutlined />}
            color="green"
            style={{ cursor: "default" }}
          >
            On Truck
          </Tag>
        );
      } else {
        const otherShipmentNumber = findShipmentNumberById(record.shipmentId);
        return (
          <Tag color="orange">
            In{" "}
            <Link
              to={`/logistics/outbound/${record.shipmentId}/freight`}
              target="_blank"
            >
              {otherShipmentNumber}
            </Link>
          </Tag>
        );
      }
    }

    // Not on any shipment => "Add"
    return (
      <Button
        size="small"
        icon={<PlusOutlined />}
        type="primary"
        onClick={() =>
          record.number
            ? handleAddModuleToTruck(record)
            : handleAddPalletToTruck(record)
        }
      >
        Add
      </Button>
    );
  }

  function maybeRenderLabel(record) {
    if (record.number && !hasShownModuleLabel) {
      hasShownModuleLabel = true;
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 4,
          }}
        >
          <Divider style={{ marginRight: 8 }} >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            MODULES
            </div>
            </Divider>
        </div>
      );
    }
    if (!record.number && !hasShownPalletLabel) {
      hasShownPalletLabel = true;
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 4,
          }}
        >
          <Divider>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            PALLETS
            </div>
            </Divider>
        </div>
      );
    }
    return null;
  }

  // --- New Unverified Modal Functions ---

  const openUnverifiedModal = (moduleId) => {
    const unverified = modules.filter(
      (mod) => mod.number && !mod.dimensionsVerified
    );
    setUnverifiedOptions(
      unverified.map((mod) => ({
        label: `${project.projectName}-${mod.number}`,
        value: mod.id,
      }))
    );
    setSelectedUnverifiedIds([moduleId]);
    setIsUnverifiedModalVisible(true);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = unverifiedOptions.map((opt) => opt.value);
      setSelectedUnverifiedIds(allIds);
    } else {
      setSelectedUnverifiedIds([]);
    }
  };

  const handleSaveReminders = async () => {
    const modulesToRemind = selectedUnverifiedIds
      .map((id) => modules.find((m) => m.id === id))
      .filter(Boolean);
    if (modulesToRemind.length === 0) {
      message.info("No modules selected.");
      return;
    }
    const moduleList = modulesToRemind
      .map((mod) => `${project.projectName}-${mod.number}`)
      .join(", ");
    
    const moduleIds = modulesToRemind.map((mod) => mod.id);
    try {
      await createReminder({
        body: [
          {
            key: "Attention",
            value: "The following modules need dimensions / weight verified",
          },
          { key: "Project", value: project.projectName },
          { key: "Modules", value: moduleList },
        ],
        typeId: moduleIds,
        type: "dimensionsVerification",
        notes: reminderNotes || "",
        date: dayjs().format("MM/DD/YYYY"),
        userName,
        module: "project-management",
      });
      message.success("Reminder created.");
    } catch (e) {
      console.error(e);
      message.error("Failed to create reminder.");
    }
    setIsUnverifiedModalVisible(false);
  };

  const totalGrossWeight = [...truckModules, ...truckPallets].reduce(
    (sum, item) => sum + (item.weight || 0),
    0
  );

  return (
    <div style={{ width: "100%" }}>
      <Row gutter={[16, 16]}>
        {/* LEFT COLUMN: TRUCK PREVIEW */}
        <Col
          span={6}
          xs={24}
          sm={6}
          style={{
            position: "sticky",
            top: 16,
            zIndex: 1000,
          }}
        >
          <div
            className="box-shadow"
            style={{
              padding: "16px",
              borderRadius: "8px",
              textAlign: "center",
              border: "1px solid #ddd",
              background: "#fff",
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {/* TAGS for modules/pallets on this truck */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "8px",
              }}
            >
              {truckModules.map((mod) => (
                <Tag
                  key={mod.id}
                  color="blue"
                  closable
                  closeIcon={<CloseCircleOutlined style={{ color: "red" }} />}
                  onClose={() => handleRemoveModuleFromTruck(mod.id)}
                >
                  {`${project?.projectName || "Project"}-${mod.number || "-"}`}
                </Tag>
              ))}

              {truckPallets.map((pal) => (
                <Tag
                  key={pal.id}
                  color="purple"
                  closable
                  closeIcon={<CloseCircleOutlined style={{ color: "red" }} />}
                  onClose={() => handleRemovePalletFromTruck(pal.id)}
                >
                  {pal.palletName}
                </Tag>
              ))}
            </div>

            <div style={{ width: "100%", marginTop: 16 }}>
              <img
                src={flatbedTruck}
                alt="Flatbed Truck"
                style={{ width: "80%", height: "auto", textAlign: "center" }}
              />
            </div>

            <div
              style={{
                fontWeight: 600,
                fontSize: 16,
                textAlign: "center",
                marginTop: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconWeight size={24} style={{ marginRight: 8 }} />
              Gross Weight: {totalGrossWeight.toLocaleString()} lbs
            </div>
          </div>
        </Col>

        {/* RIGHT COLUMNS: MODULE AND PALLET CARDS */}
        {[...modules, ...pallets].map((record, index) => {
          const missingAttrs = getMissingAttributes(record);
          const isModule = !!record.number;
          const isPallet = !isModule;
          const isUnverified = !record.dimensionsVerified;
          const justifyContent =
            (isModule && index < 4) || isPallet ? "flex-end" : "flex-start";

          return (
            <Col
              key={index}
              span={4}
              xs={24}
              sm={4}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent,
              }}
            >
              {maybeRenderLabel(record)}
              <Card
                size="small"
                style={{
                  width: "100%",
                  ...(missingAttrs.length || (isUnverified && isModule)
                    ? { backgroundColor: "#ffe6e6" }
                    : {}),
                }}
                title={
                  record.number
                    ? renderModuleTag(record)
                    : renderPalletTag(record)
                }
                extra={renderExtraContent(record)}
              >
                <div style={{ textAlign: "center", marginBottom: 8 }}>
                  <IconWeight size={24} />
                  <div>
                    {record.weight ? (
                      `${record.weight.toLocaleString()} lbs`
                    ) : (
                      <StopOutlined style={{ color: "red" }} />
                    )}
                  </div>
                </div>
                <div style={{ textAlign: "center", marginBottom: 16 }}>
                  <IconRulerMeasure size={24} style={{ marginRight: 4 }} />
                  <IconRulerMeasure2 size={24} style={{ marginRight: 4 }} />
                  <DimensionsCell
                    length={record.length}
                    lengthIn={record.lengthIn}
                    width={record.width}
                    widthIn={record.widthIn}
                    height={record.height}
                    heightIn={record.heightIn}
                  />
                </div>
                {!record.number && record.assignedItems?.length > 0 ? (
                  <Collapse size="small">
                    <Panel header="Items" key="1">
                      <ul style={{ margin: 0, paddingLeft: 20 }}>
                        {record.assignedItems.map((item, idx) => (
                          <li key={idx}>
                            {item.quantity}x{" "}
                            {item.itemDescription || "Unknown Item"}
                          </li>
                        ))}
                      </ul>
                    </Panel>
                  </Collapse>
                ) : (
                  !record.number && (
                    <div
                      style={{
                        fontStyle: "italic",
                        color: "#888",
                        textAlign: "center",
                      }}
                    >
                      <StopOutlined /> No Items
                    </div>
                  )
                )}
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* Unverified Modules Modal */}
      <Modal
        visible={isUnverifiedModalVisible}
        title="Select Unverified Modules for Reminder"
        onCancel={() => setIsUnverifiedModalVisible(false)}
        onOk={handleSaveReminders}
      >
        <Checkbox
          onChange={handleSelectAll}
          checked={selectedUnverifiedIds.length === unverifiedOptions.length}
        >
          Select All
        </Checkbox>
        <Checkbox.Group
          style={{ display: "block", marginTop: 16 }}
          options={unverifiedOptions}
          value={selectedUnverifiedIds}
          onChange={(checkedValues) => setSelectedUnverifiedIds(checkedValues)}
        />
        <Input.TextArea
          value={reminderNotes}
          onChange={(e) => setReminderNotes(e.target.value)}
          placeholder="Notes..."
          style={{ marginTop: 16 }}
        />
      </Modal>
    </div>
  );
}

export default OutboundFreight;
