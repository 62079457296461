/**
 * Returns arrays of:
 *   - allModules (all project modules, each with { onTruck, shipmentId, ... })
 *   - allPallets (all project pallets, each with { onTruck, shipmentId, ... })
 *   - truckModules (subset of modules on THIS shipment)
 *   - truckPallets (subset of pallets on THIS shipment)
 *
 * @param {Object} project - e.g. { modules: [...], shipLoose: { pallets: [...], items: [...] } }
 * @param {string} shipmentId - The ID of the outbound shipment in question
 * @returns {Object} { allModules, allPallets, truckModules, truckPallets }
 */
export function getShipmentItems(project = {}, shipmentId = "") {
  // 1) Prepare modules array
  const projectModules = (project.modules || []).map((m) => ({
    ...m,
    onTruck: m.onTruck || false,
    shipmentId: m.shipmentId || null,
  }));

  // 2) Prepare pallets array
  const shipLoosePallets = project.shipLoose?.pallets || [];
  const projectPallets = shipLoosePallets.map((pallet) => ({
    ...pallet,
    onTruck: pallet.onTruck || false,
    shipmentId: pallet.shipmentId || null,
    // If the pallet has assignedItems referencing an ID in project.shipLoose.items:
    assignedItems:
      pallet.assignedItems?.map((itemRef) => {
        const actualItem = project.shipLoose?.items?.find(
          (i) => i.id === itemRef.itemId
        );
        return {
          ...itemRef,
          itemDescription: actualItem?.itemDescription || "Unknown Item",
        };
      }) || [],
  }));

  // 3) Identify which modules/pallets are on THIS shipment
  const truckModules = projectModules.filter(
    (mod) => mod.onTruck && mod.shipmentId === shipmentId
  );
  const truckPallets = projectPallets.filter(
    (pal) => pal.onTruck && pal.shipmentId === shipmentId
  );

  return {
    allModules: projectModules,
    allPallets: projectPallets,
    truckModules,
    truckPallets,
  };
}
