import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  InputNumber,
  Input,
  Select,
  Table,
  Space,
  Typography,
  message,
  Row,
  Col,
  Empty,
  Card,
  Space as AntdSpace,
} from "antd";
import {
  SaveOutlined,
  CloseOutlined,
  EditOutlined,
  CopyOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { TABLES, PRODUCT_TYPES } from "../../constants";
import { updateById } from "../../Functions/updateById";
import PriceTag from "../../Styled/PriceTag";
import { getModuleStatus } from "../../utils/getModuleStatus";
import StatusTag from "../../Styled/StatusTag";
import { formatCommas } from "../../Formatters/helpers";
import DimensionsCell from "../../Styled/DimensionCell";

const { Option } = Select;
const { Text } = Typography;

function ProjectModules({ project }) {
  // Existing states
  const [modules, setModules] = useState(project.modules || []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalModules, setModalModules] = useState([]);
  const [loading, setLoading] = useState(false);

  // State for verification modal
  const [isVerificationModalVisible, setIsVerificationModalVisible] =
    useState(false);
  const [verificationModules, setVerificationModules] = useState([]);

  // Global inputs for "Apply to All" functionality (for both modals)
  const [globalLength, setGlobalLength] = useState(0);
  const [globalLengthIn, setGlobalLengthIn] = useState(0);
  const [globalWidth, setGlobalWidth] = useState(0);
  const [globalWidthIn, setGlobalWidthIn] = useState(0);
  const [globalHeight, setGlobalHeight] = useState(0);
  const [globalHeightIn, setGlobalHeightIn] = useState(0);
  const [globalWeight, setGlobalWeight] = useState(0);
  const [globalDescription, setGlobalDescription] = useState("");
  const [globalProductType, setGlobalProductType] = useState("");

  useEffect(() => {
    setModules(project.modules || []);
  }, [project.modules]);

  // --- Edit Modules Modal Functions ---

  const showModal = () => {
    const initialized = modules.map((mod) => ({
      ...mod,
      lengthIn: mod.lengthIn || 0,
      widthIn: mod.widthIn || 0,
      heightIn: mod.heightIn || 0,
    }));
    setModalModules(initialized);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTableChange = (record, field, value) => {
    const updatedModules = modalModules.map((mod) => {
      if (mod.id === record.id) {
        return { ...mod, [field]: value };
      }
      return mod;
    });
    setModalModules(updatedModules);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await updateById(
        TABLES.PROJECTS,
        { ...project, modules: modalModules },
        project.id
      );
      setModules([...modalModules]);
      setIsModalVisible(false);
      message.success("Modules saved successfully!");
    } catch (error) {
      console.error(error);
      message.error("Failed to save modules. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleApplyToAllEdit = () => {
    const updated = modalModules.map((mod) => ({
      ...mod,
      length: globalLength,
      lengthIn: globalLengthIn,
      width: globalWidth,
      widthIn: globalWidthIn,
      height: globalHeight,
      heightIn: globalHeightIn,
      weight: globalWeight,
      moduleDescription: globalDescription || mod.moduleDescription,
      productType: globalProductType || mod.productType,
    }));
    setModalModules(updated);
    message.success("Applied values to all modules in edit modal.");
  };

  // --- Verification Modal Functions ---

  const showVerificationModal = () => {
    const initialized = modules.map((mod) => ({
      ...mod,
      lengthIn: mod.lengthIn || 0,
      widthIn: mod.widthIn || 0,
      heightIn: mod.heightIn || 0,
    }));
    setVerificationModules(initialized);
    setIsVerificationModalVisible(true);
  };

  const handleVerificationCancel = () => {
    setIsVerificationModalVisible(false);
  };

  const handleVerificationChange = (record, field, value) => {
    const updatedModules = verificationModules.map((mod) => {
      if (mod.id === record.id) {
        return { ...mod, [field]: value };
      }
      return mod;
    });
    setVerificationModules(updatedModules);
  };

  const handleVerify = async (record) => {
    const updatedModule = { ...record, dimensionsVerified: true };
    try {
      setLoading(true);
      const newModules = verificationModules.map((mod) =>
        mod.id === record.id ? updatedModule : mod
      );
      await updateById(
        TABLES.PROJECTS,
        { ...project, modules: newModules },
        project.id
      );
      setVerificationModules(newModules);
      setModules(newModules);
      message.success(`Module ${record.number} verified!`);
    } catch (error) {
      console.error(error);
      message.error("Failed to verify module.");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveVerification = async (record) => {
    const updatedModule = { ...record, dimensionsVerified: false };
    try {
      setLoading(true);
      const newModules = verificationModules.map((mod) =>
        mod.id === record.id ? updatedModule : mod
      );
      await updateById(
        TABLES.PROJECTS,
        { ...project, modules: newModules },
        project.id
      );
      setVerificationModules(newModules);
      setModules(newModules);
      message.success(`Removed verification for module ${record.number}.`);
    } catch (error) {
      console.error(error);
      message.error("Failed to remove verification.");
    } finally {
      setLoading(false);
    }
  };

  const handleApplyToAll = () => {
    Modal.confirm({
      title: "Apply to All",
      content:
        "Are you sure you want to apply these dimensions and weight to all modules?",
      onOk() {
        const updated = verificationModules.map((mod) => ({
          ...mod,
          length: globalLength,
          lengthIn: globalLengthIn,
          width: globalWidth,
          widthIn: globalWidthIn,
          height: globalHeight,
          heightIn: globalHeightIn,
          weight: globalWeight,
        }));
        setVerificationModules(updated);
        message.success("Applied values to all modules.");
      },
    });
  };

  const handleVerifyAll = () => {
    Modal.confirm({
      title: "Verify All Modules",
      content: "Are you sure you want to verify all modules?",
      async onOk() {
        const updated = verificationModules.map((mod) => ({
          ...mod,
          dimensionsVerified: true,
        }));
        try {
          setLoading(true);
          await updateById(
            TABLES.PROJECTS,
            { ...project, modules: updated },
            project.id
          );
          setVerificationModules(updated);
          setModules(updated);
          message.success("All modules verified!");
        } catch (error) {
          console.error(error);
          message.error("Failed to verify all modules.");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleRemoveAllVerifications = () => {
    Modal.confirm({
      title: "Remove All Verifications",
      content: "Are you sure you want to remove verification from all modules?",
      async onOk() {
        const updated = verificationModules.map((mod) => ({
          ...mod,
          dimensionsVerified: false,
        }));
        try {
          setLoading(true);
          await updateById(
            TABLES.PROJECTS,
            { ...project, modules: updated },
            project.id
          );
          setVerificationModules(updated);
          setModules(updated);
          message.success("Removed verification from all modules!");
        } catch (error) {
          console.error(error);
          message.error("Failed to remove all verifications.");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  // --- Table Columns Definitions ---

  const editableColumns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 60,
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Module Description",
      dataIndex: "moduleDescription",
      key: "moduleDescription",
      width: 200,
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) =>
            handleTableChange(record, "moduleDescription", e.target.value)
          }
          placeholder="Description"
        />
      ),
    },
    {
      title: "Length (ft)",
      dataIndex: "length",
      key: "length",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          value={text}
          onChange={(value) => handleTableChange(record, "length", value)}
          style={{ width: "100%" }}
          placeholder="Length"
          addonAfter="ft"
        />
      ),
    },
    {
      title: "Length (in)",
      dataIndex: "lengthIn",
      key: "lengthIn",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          max={11.99}
          value={text}
          onChange={(value) => handleTableChange(record, "lengthIn", value)}
          style={{ width: "100%" }}
          placeholder="Inches"
          addonAfter="in"
        />
      ),
    },
    {
      title: "Width (ft)",
      dataIndex: "width",
      key: "width",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          value={text}
          onChange={(value) => handleTableChange(record, "width", value)}
          style={{ width: "100%" }}
          placeholder="Width"
          addonAfter="ft"
        />
      ),
    },
    {
      title: "Width (in)",
      dataIndex: "widthIn",
      key: "widthIn",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          max={11.99}
          value={text}
          onChange={(value) => handleTableChange(record, "widthIn", value)}
          style={{ width: "100%" }}
          placeholder="Inches"
          addonAfter="in"
        />
      ),
    },
    {
      title: "Height (ft)",
      dataIndex: "height",
      key: "height",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          value={text}
          onChange={(value) => handleTableChange(record, "height", value)}
          style={{ width: "100%" }}
          placeholder="Height"
          addonAfter="ft"
        />
      ),
    },
    {
      title: "Height (in)",
      dataIndex: "heightIn",
      key: "heightIn",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          max={11.99}
          value={text}
          onChange={(value) => handleTableChange(record, "heightIn", value)}
          style={{ width: "100%" }}
          placeholder="Inches"
          addonAfter="in"
        />
      ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: 150,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          value={text}
          onChange={(value) => handleTableChange(record, "weight", value)}
          style={{ width: "100%" }}
          placeholder="Weight"
          addonAfter="lbs"
        />
      ),
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      width: 150,
      render: (text, record) => (
        <Select
          value={text}
          onChange={(value) => handleTableChange(record, "productType", value)}
          style={{ width: "100%" }}
          placeholder="Select Type"
        >
          {PRODUCT_TYPES.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 120,
      render: (text) => (
        <Text>
          <PriceTag medium amount={text} />
        </Text>
      ),
    },
  ];

  const displayColumns = [
    {
      title: "Module ID",
      dataIndex: "number",
      key: "number",
      width: 60,
      render: (text) => <Text>{`${project.projectName}-${text}`}</Text>,
    },
    {
      title: "Module Description",
      dataIndex: "moduleDescription",
      key: "moduleDescription",
      width: 200,
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Dimensions (L x W x H)",
      key: "dimensions",
      render: (_, record) => (
        <DimensionsCell
          length={record.length}
          lengthIn={record.lengthIn}
          width={record.width}
          widthIn={record.widthIn}
          height={record.height}
          heightIn={record.heightIn}
        />
      ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: 150,
      render: (text) => (text ? <Text>{formatCommas(text)} lbs</Text> : null),
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      width: 150,
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 120,
      render: (text) => (
        <Text>
          <PriceTag medium amount={text} />
        </Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: "Dims Verified",
      dataIndex: "dimensionVerified",
      key: "dimensionsVerified",
      width: 150,
      render: (text, record) =>
        record.dimensionsVerified ? (
          <Text strong style={{ color: "green" }}>
            Verified &#10003;
          </Text>
        ) : (
          <Text>Not Verified</Text>
        ),
    },
  ];

  const verificationColumns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 75,
      render: (text) => <Text>{project.projectName + "-" + text}</Text>,
    },
    {
      title: "Length (ft)",
      dataIndex: "length",
      key: "length",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          value={text}
          onChange={(value) =>
            handleVerificationChange(record, "length", value)
          }
          style={{ width: "100%" }}
          placeholder="Length"
          addonAfter="ft"
        />
      ),
    },
    {
      title: "Length (in)",
      dataIndex: "lengthIn",
      key: "lengthIn",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          max={11.99}
          value={text}
          onChange={(value) =>
            handleVerificationChange(record, "lengthIn", value)
          }
          style={{ width: "100%" }}
          placeholder="Inches"
          addonAfter="in"
        />
      ),
    },
    {
      title: "Width (ft)",
      dataIndex: "width",
      key: "width",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          value={text}
          onChange={(value) => handleVerificationChange(record, "width", value)}
          style={{ width: "100%" }}
          placeholder="Width"
          addonAfter="ft"
        />
      ),
    },
    {
      title: "Width (in)",
      dataIndex: "widthIn",
      key: "widthIn",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          max={11.99}
          value={text}
          onChange={(value) =>
            handleVerificationChange(record, "widthIn", value)
          }
          style={{ width: "100%" }}
          placeholder="Inches"
          addonAfter="in"
        />
      ),
    },
    {
      title: "Height (ft)",
      dataIndex: "height",
      key: "height",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          value={text}
          onChange={(value) =>
            handleVerificationChange(record, "height", value)
          }
          style={{ width: "100%" }}
          placeholder="Height"
          addonAfter="ft"
        />
      ),
    },
    {
      title: "Height (in)",
      dataIndex: "heightIn",
      key: "heightIn",
      width: 100,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          max={11.99}
          value={text}
          onChange={(value) =>
            handleVerificationChange(record, "heightIn", value)
          }
          style={{ width: "100%" }}
          placeholder="Inches"
          addonAfter="in"
        />
      ),
    },
    {
      title: "Weight (lbs)",
      dataIndex: "weight",
      key: "weight",
      width: 150,
      render: (text, record) => (
        <InputNumber
          type="number"
          min={0}
          value={text}
          onChange={(value) =>
            handleVerificationChange(record, "weight", value)
          }
          style={{ width: "100%" }}
          placeholder="Weight"
          addonAfter="lbs"
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (_, record) => {
        if (record.dimensionsVerified) {
          return (
            <div style={{ display: "flex", alignItems: "center", justifyContent:"space-between" }}>
              <Text strong style={{ color: "green" }}>
                Verified &#10003;
              </Text>
              <Button type="link" icon={<CloseCircleOutlined/>} danger onClick={() => handleRemoveVerification(record)}>
                
              </Button>
            </div>
          );
        }
        return <Button onClick={() => handleVerify(record)}>Verify</Button>;
      },
    },
  ];

  return (
    <div>
      <Space
        style={{
          marginBottom: "16px",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Button
          type="primary"
          onClick={showVerificationModal}
          disabled={modules.length === 0}
          style={{ marginRight: 8 }}
        >
          Verify Dimensions + Weight
        </Button>
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={showModal}
          disabled={modules.length === 0}
        >
          Edit Modules
        </Button>
      </Space>

      {modules.length > 0 ? (
        <Table
          dataSource={modules.map((mod) => ({
            ...mod,
            status: getModuleStatus(mod, project),
          }))}
          columns={displayColumns}
          rowKey="id"
          pagination={false}
          bordered
        />
      ) : (
        <Empty
          description={`No Modules Available for ${project.projectName}`}
        />
      )}

      {/* EDIT MODAL */}
      <Modal
        title="Edit Modules"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel} icon={<CloseOutlined />}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleSave}
            loading={loading}
            icon={<SaveOutlined />}
          >
            Save
          </Button>,
        ]}
        width={"90%"}
        destroyOnClose
      >
        <Card
          size="small"
          title="Global Edit"
          extra={
            <Button
              icon={<CopyOutlined />}
              onClick={handleApplyToAllEdit}
              type="primary"
            >
              Apply to All Modules
            </Button>
          }
        >
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={5}>
              <Text>Length:</Text>
              <div style={{ display: "flex", marginTop: 8 }}>
                <InputNumber
                  type="number"
                  addonAfter="ft"
                  min={0}
                  value={globalLength}
                  onChange={setGlobalLength}
                  style={{ marginRight: 8 }}
                />
                <InputNumber
                  type="number"
                  min={0}
                  max={11.99}
                  addonAfter="in"
                  value={globalLengthIn}
                  onChange={setGlobalLengthIn}
                />
              </div>
            </Col>
            <Col span={5}>
              <Text>Width:</Text>
              <div style={{ display: "flex", marginTop: 8 }}>
                <InputNumber
                  type="number"
                  addonAfter="ft"
                  min={0}
                  value={globalWidth}
                  onChange={setGlobalWidth}
                  style={{ marginRight: 8 }}
                />
                <InputNumber
                  type="number"
                  min={0}
                  max={11.99}
                  addonAfter="in"
                  value={globalWidthIn}
                  onChange={setGlobalWidthIn}
                />
              </div>
            </Col>
            <Col span={5}>
              <Text>Height:</Text>
              <div style={{ display: "flex", marginTop: 8 }}>
                <InputNumber
                  type="number"
                  addonAfter="ft"
                  min={0}
                  value={globalHeight}
                  onChange={setGlobalHeight}
                  style={{ marginRight: 8 }}
                />
                <InputNumber
                  type="number"
                  min={0}
                  max={11.99}
                  addonAfter="in"
                  value={globalHeightIn}
                  onChange={setGlobalHeightIn}
                />
              </div>
            </Col>
            <Col span={5}>
              <Text>Weight (lbs):</Text>
              <InputNumber
                type="number"
                min={0}
                addonAfter="lbs"
                value={globalWeight}
                onChange={setGlobalWeight}
                style={{ display: "block", marginTop: 8, width: "100%" }}
              />
            </Col>
            <Col span={4} />
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Text>Description:</Text>
              <Input
                value={globalDescription}
                onChange={(e) => setGlobalDescription(e.target.value)}
                placeholder="Global Description"
                style={{ marginTop: 8, width: "100%" }}
              />
            </Col>
            <Col span={8}>
              <Text>Product Type:</Text>
              <Select
                value={globalProductType}
                onChange={setGlobalProductType}
                placeholder="Select Type"
                style={{ marginTop: 8, width: "100%" }}
              >
                {PRODUCT_TYPES.map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Card>
        <Table
          dataSource={modalModules.map((mod) => ({
            ...mod,
            status: getModuleStatus(mod, project),
          }))}
          columns={editableColumns}
          rowKey="id"
          pagination={false}
          bordered
          style={{ marginTop: 8 }}
        />
      </Modal>

      {/* VERIFICATION MODAL */}
      <Modal
        title="Verify Dimensions & Weight"
        visible={isVerificationModalVisible}
        onCancel={handleVerificationCancel}
        footer={null}
        width={"90%"}
        destroyOnClose
      >
        <Card
          title="Global Dimensions"
          extra={
            <div style={{ display: "flex", gap: "8px" }}>
              <Button danger onClick={handleRemoveAllVerifications}>
                Remove All Verifications
              </Button>
              <Button
                style={{ background: "green", color: "white" }}
                icon={<CheckOutlined />}
                onClick={handleVerifyAll}
              >
                Verify All
              </Button>
            </div>
          }
        >
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={5}>
              <Text>Length:</Text>
              <div style={{ display: "flex", marginTop: 8 }}>
                <InputNumber
                  type="number"
                  addonAfter="ft"
                  min={0}
                  value={globalLength}
                  onChange={setGlobalLength}
                  style={{ marginRight: 8 }}
                />
                <InputNumber
                  type="number"
                  min={0}
                  max={11.99}
                  addonAfter="in"
                  value={globalLengthIn}
                  onChange={setGlobalLengthIn}
                />
              </div>
            </Col>
            <Col span={5}>
              <Text>Width:</Text>
              <div style={{ display: "flex", marginTop: 8 }}>
                <InputNumber
                  type="number"
                  addonAfter="ft"
                  min={0}
                  value={globalWidth}
                  onChange={setGlobalWidth}
                  style={{ marginRight: 8 }}
                />
                <InputNumber
                  type="number"
                  min={0}
                  max={11.99}
                  addonAfter="in"
                  value={globalWidthIn}
                  onChange={setGlobalWidthIn}
                />
              </div>
            </Col>
            <Col span={5}>
              <Text>Height:</Text>
              <div style={{ display: "flex", marginTop: 8 }}>
                <InputNumber
                  type="number"
                  addonAfter="ft"
                  min={0}
                  value={globalHeight}
                  onChange={setGlobalHeight}
                  style={{ marginRight: 8 }}
                />
                <InputNumber
                  type="number"
                  min={0}
                  max={11.99}
                  addonAfter="in"
                  value={globalHeightIn}
                  onChange={setGlobalHeightIn}
                />
              </div>
            </Col>
            <Col span={3}>
              <Text>Weight (lbs):</Text>
              <InputNumber
                type="number"
                min={0}
                addonAfter="lbs"
                value={globalWeight}
                onChange={setGlobalWeight}
                style={{ display: "block", marginTop: 8, width: "100%" }}
              />
            </Col>
            <Col span={6} style={{ display: "flex", alignItems: "flex-end" }}>
              <Button
                icon={<CopyOutlined />}
                onClick={handleApplyToAll}
                type="primary"
              >
                Apply LWH / Weight to All Modules
              </Button>
            </Col>
          </Row>
        </Card>
        <Table
          dataSource={verificationModules.map((mod) => ({
            ...mod,
            status: getModuleStatus(mod, project),
          }))}
          columns={verificationColumns}
          rowKey="id"
          pagination={false}
          bordered
          style={{ marginTop: 8 }}
        />
      </Modal>
    </div>
  );
}

export default ProjectModules;
