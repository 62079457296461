import React, { useState, useEffect, useMemo } from "react";
import {
  List,
  Card,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Typography,
  Tag,
} from "antd";
import { StopOutlined, SearchOutlined } from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import dayjs from "dayjs";
import { getProjectName } from "../../Formatters/getProjectName";
import { Loader } from "../../Styled/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "@clerk/clerk-react";
import { COLORS } from "../../constants";
import InfiniteScroll from "react-infinite-scroll-component";

const { Search } = Input;
const { Title } = Typography;

const ITEMS_PER_LOAD = 20; // Number of items to load each time

function ReceiveReturn() {
  const [selectedRO, setSelectedRO] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [displayedROs, setDisplayedROs] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors, loading: vendorsLoading } = useVendors();
  const { data: projects } = useProjects();

  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress || "";

  // Determine if the user is a "sterling" user
  const isSterlingUser = useMemo(() => {
    return userEmail.toLowerCase().includes("sterling");
  }, [userEmail]);

  // Determine filter based on user role
  const filter = useMemo(() => {
    if (isSterlingUser) {
      return "6544 Warren";
    } else {
      return "All";
    }
  }, [isSterlingUser]);

  // Debounce the search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // 300ms debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Utility function to extract numerical part from roNumber for sorting
  const extractNumber = (roNumber) => {
    const match = roNumber.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  // Filter return orders based on filter and lines with qtyShipped < qtyReturn
  const filteredReturnOrders = useMemo(() => {
    if (!purchaseOrders) return [];

    // First, filter ROs that have at least one line with qtyShipped < qtyReturn
    let filteredROs = purchaseOrders.filter((po) =>
      po.lines.some((line) => line.qtyShipped < line.qtyReturn)
    );

    // Then, apply shipFromAddress filter if necessary
    if (filter !== "All") {
      filteredROs = filteredROs.filter((po) =>
        po.returnOrder.shipFromAddress
          .toLowerCase()
          .includes(filter.toLowerCase())
      );
    }

    // Apply search filter
    if (debouncedSearchTerm.trim() !== "") {
      const lowerSearch = debouncedSearchTerm.toLowerCase();
      filteredROs = filteredROs.filter(
        (po) =>
          po.roNumber.toLowerCase().includes(lowerSearch) ||
          getVendorName(po.vendor, vendors).toLowerCase().includes(lowerSearch)
      );
    }

    // Sort by roNumber descending
    filteredROs.sort(
      (a, b) => extractNumber(b.roNumber) - extractNumber(a.roNumber)
    );

    return filteredROs;
  }, [purchaseOrders, filter, debouncedSearchTerm, vendors]);

  // Initialize displayedROs with the first set
  useEffect(() => {
    if (filteredReturnOrders.length > 0) {
      setDisplayedROs(filteredReturnOrders.slice(0, ITEMS_PER_LOAD));
      setHasMore(filteredReturnOrders.length > ITEMS_PER_LOAD);
    } else {
      setDisplayedROs([]);
      setHasMore(false);
    }
  }, [filteredReturnOrders]);

  // Function to load more ROs
  const fetchMoreROs = () => {
    const currentLength = displayedROs.length;
    const moreROs = filteredReturnOrders.slice(
      currentLength,
      currentLength + ITEMS_PER_LOAD
    );
    setDisplayedROs([...displayedROs, ...moreROs]);
    if (displayedROs.length + moreROs.length >= filteredReturnOrders.length) {
      setHasMore(false);
    }
  };

  // Handle RO Selection
  const handleSelectRO = (ro) => {
    setSelectedRO(ro);
    setIsModalVisible(true);
  };

  // Handle Modal Close
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedRO(null);
  };

  // Handle Search Input Change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  if (poLoading || vendorsLoading) {
    return <Loader />;
  }

  return (
    <div
      style={{

        boxSizing: "border-box",


      }}
    >
      {/* Sticky Search Bar */}
      <div
        style={{
          position: "sticky",
          top: 0,
          paddingBottom: "16px",
          zIndex: 1000,

          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <Input
          placeholder="Search by RO Number or Vendor"
          allowClear
          prefix={<SearchOutlined />}
          size="large"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ width: "100%" }}
        />
      </div>

      {/* Infinite Scroll List */}
      <div
        id="scrollableDiv"
        style={{
          height: "calc(90vh - 120px)", // Adjust based on search bar height
          overflow: "auto",

        }}
      >
        <InfiniteScroll
          dataLength={displayedROs.length}
          next={fetchMoreROs}
          hasMore={hasMore}
          loader={
            <div style={{ textAlign: "center", padding: "16px" }}>
              <Loader />
            </div>
          }
          endMessage={
            <div style={{ textAlign: "center", padding: "16px" }}>
              <b>No more return orders</b>
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={displayedROs}
            renderItem={(ro) => (
              <List.Item
                key={ro.id}
                onClick={() => handleSelectRO(ro)}
                style={{
                  border: "1px solid #f0f0f0",
                  borderRadius: "4px",
                  marginBottom: "8px",
                  padding: "12px",
                  cursor: "pointer",
                  background: "#ffffff",
                  transition: "background-color 0.3s, box-shadow 0.3s",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#fafafa")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#ffffff")
                }
              >
                <List.Item.Meta
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Tag color="blue" style={{ fontSize: 16 }}>
                          {ro.roNumber}
                        </Tag>
                      </div>
                      <div
                        style={{
                          margin: 0,
                          fontWeight: 600,
                          fontSize: 14,
                          opacity: 0.8,
                          textAlign: "right",
                        }}
                      >
                        {getVendorName(ro.vendor, vendors)}
                      </div>
                    </div>
                  }

                />

              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>

      {/* Modal to Display RO Details */}
      {selectedRO && (
        <Modal
          title={`Return Order Details - ${selectedRO.roNumber}`}
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={[
            <Button key="cancel" onClick={handleCloseModal}>
              Cancel
            </Button>,
            <Button
              key="next"
              type="primary"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              href={`/receiving/receive/return-orders/${selectedRO.id}`}
            >
              Next
            </Button>,
          ]}
        >
          <Card bordered={false}>
            <p>
              <strong>Address:</strong> {selectedRO.returnOrder.shipFromAddress}
            </p>
            <p>
              <strong>Project:</strong>{" "}
              {getProjectName(selectedRO.projectNumber, projects)}
            </p>
            <p>
              <strong>Buyer:</strong> {selectedRO.buyer}
            </p>
            <p>
              <strong>Required Date:</strong>{" "}
              {dayjs(selectedRO.returnOrder.requiredDate).format("MM/DD/YYYY")}
            </p>
            <p>
              <strong>Notes:</strong>{" "}
              {selectedRO.returnOrder.poNotes || (
                <StopOutlined
                  style={{ color: COLORS.WARNING, marginLeft: 8 }}
                />
              )}
            </p>
          </Card>
        </Modal>
      )}
    </div>
  );
}

export default ReceiveReturn;
