import React from "react";
import PreconCard from "../../Pages/projectmanagement/PreconCard";
import ProductionCard from "../Production/ProductionCard";
import HeaderText from "../../Styled/HeaderText";

function ProjectProgress({ project }) {

  return (
    <div style={{}}>
      <HeaderText medium text="Preconstruction" />

      {/* 1) Preconstruction Card in VIEW-ONLY mode */}
      <PreconCard project={project} viewOnly={true} />

      <div style={{ marginTop: 24 }}>
        <HeaderText medium text="Production" />

        {project.modules &&
          project.modules.map((mod) => (
            <div key={mod.id} style={{ marginBottom: 0 }}>
              <ProductionCard module={mod} project={project} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ProjectProgress;
