// src/Pages/projectmanagement/Preconstruction.jsx

import React, { useState, useMemo, useEffect } from "react";
import {
  Modal,
  Form,
  Select,
  Button,
  Typography,
  Row,
  Col,
  Statistic,
  Spin,
  Input,
  Tooltip,
  message,
} from "antd";
import {
  WarningOutlined,
  SortAscendingOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { useProjects } from "../../Contexts/useSpecificData"; // Custom hook to fetch projects
import { updateById } from "../../Functions/updateById"; // Function to update project by ID
import {
  TABLES,
  PRECON_STATUS_FLOW,
  PRODUCT_TYPES,
  COLORS,
} from "../../constants"; // Constants including PRECON_STATUS_FLOW
import HeaderText from "../../Styled/HeaderText"; // Custom HeaderText component
import PreconCard from "./PreconCard";
import { useUser } from "@clerk/clerk-react"; // User hook from Clerk
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPencil,
  faMagnifyingGlass,
  faClipboardList,
  faTag,
  faHandHoldingHand,
  faCheck,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { formatMoney } from "../../Formatters/helpers";
import { WeldingIcon } from "../../Styled/CustomIcons";

const { Text } = Typography;
const { Option } = Select;

// Status to FontAwesome icon mapping
const statusIconMapping = {
  "Issued for Preconstruction": faDownload, // You can choose a different icon if preferred
  Drawing: faPencil,
  "Client Review": faMagnifyingGlass,
  BOM: faClipboardList,
  Materials: faTag,
  Handoff: faHandHoldingHand,
  "Issued for Production": faCheck,
};

// Helper function to get FontAwesome icon for a given status
const getIconForStatus = (status) => {
  return statusIconMapping[status] || faClipboardList;
};

function Preconstruction({ viewOnly = false }) {
  const { data: projects, refresh: refreshProjects } = useProjects();
  const [isClientDelayModalVisible, setIsClientDelayModalVisible] =
    useState(false);
  const [clientDelayForm] = Form.useForm();

  // Loading state for Client Delay modal
  const [clientDelayLoading, setClientDelayLoading] = useState(false);

  // Infinite Scroll States
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_LOAD = 10;

  // States for ordering and filtering
  const [order, setOrder] = useState("most"); // 'most' | 'least'
  const [filterStatus, setFilterStatus] = useState("All Stages");
  const [filterProductType, setFilterProductType] =
    useState("All Product Types");
  const [searchQuery, setSearchQuery] = useState("");

  // Extract unique product types for filter
  const productTypes = useMemo(() => {
    if (!projects || projects.length === 0)
      return ["All Product Types", ...PRODUCT_TYPES];
    const types = projects
      .map((project) => project.preconstruction?.productType)
      .filter(Boolean);
    const uniqueTypes = Array.from(new Set(types));
    return ["All Product Types", ...uniqueTypes];
  }, [projects]);

  // Access userEmail from Clerk's useUser hook
  const { user, isLoaded: isUserLoaded } = useUser();
  const userEmail =
    isUserLoaded && user && user.primaryEmailAddress
      ? user.primaryEmailAddress.emailAddress
      : "Unknown";

  // Compute eligible projects for Client Delay modal
  const eligibleProjects = useMemo(() => {
    if (!projects) return [];
    return projects.filter(
      (project) =>
        project.preconstruction &&
        !project.preconstruction.clientDelay &&
        !project.preconstruction.actualHandoffComplete
    );
  }, [projects]);

  // Memoized sorted and filtered projects
  const sortedAndFilteredProjects = useMemo(() => {
    if (!projects) return [];

    let filtered = [...projects];

    // Apply status filtering
    if (filterStatus !== "All Stages") {
      filtered = filtered.filter((project) => {
        if (filterStatus === "Issued for Preconstruction") {
          return !project.preconstruction;
        } else if (filterStatus === "Client Delay") {
          return project.preconstruction?.clientDelay === true;
        }
        return project.preconstruction?.status === filterStatus;
      });
    }

    // Apply productType filtering
    if (filterProductType !== "All Product Types") {
      filtered = filtered.filter(
        (project) => project.preconstruction?.productType === filterProductType
      );
    }

    // Apply search filtering
    if (searchQuery.trim() !== "") {
      const query = searchQuery.trim().toLowerCase();
      filtered = filtered.filter(
        (project) =>
          project.projectName &&
          project.projectName.toLowerCase().includes(query)
      );
    }

    // Apply ordering
    filtered.sort((a, b) => {
      const allStatuses = ["Issued for Preconstruction", ...PRECON_STATUS_FLOW];
      const aStatus = a.preconstruction?.status || "Issued for Preconstruction";
      const bStatus = b.preconstruction?.status || "Issued for Preconstruction";
      const aIndex = allStatuses.indexOf(aStatus);
      const bIndex = allStatuses.indexOf(bStatus);
      if (order === "most") {
        return bIndex - aIndex;
      } else {
        return aIndex - bIndex;
      }
    });

    return filtered;
  }, [projects, order, filterStatus, filterProductType, searchQuery]);

  // Calculate statistics based on filtered projects
  const statistics = useMemo(() => {
    const totalProjects = sortedAndFilteredProjects.length;
    const totalAmount = sortedAndFilteredProjects.reduce((acc, project) => {
      return acc + (project.preconstruction?.amount || 0);
    }, 0);
    return { totalProjects, totalAmount };
  }, [sortedAndFilteredProjects]);

  // Initialize displayed projects for infinite scroll
  useEffect(() => {
    setDisplayedProjects(sortedAndFilteredProjects.slice(0, ITEMS_PER_LOAD));
    setHasMore(sortedAndFilteredProjects.length > ITEMS_PER_LOAD);
  }, [sortedAndFilteredProjects]);

  // Function to fetch more projects
  const fetchMoreProjects = () => {
    const currentLength = displayedProjects.length;
    const nextProjects = sortedAndFilteredProjects.slice(
      currentLength,
      currentLength + ITEMS_PER_LOAD
    );
    setDisplayedProjects([...displayedProjects, ...nextProjects]);
    if (
      displayedProjects.length + nextProjects.length >=
      sortedAndFilteredProjects.length
    ) {
      setHasMore(false);
    }
  };

  // Handle Client Delay modal submission
  const handleClientDelaySubmit = async (values) => {
    const selectedProjectIds = values.projects || [];
    if (selectedProjectIds.length === 0) {
      message.warning("Please select at least one project to delay.");
      return;
    }

    setClientDelayLoading(true);
    try {
      // Update each selected project
      const updatePromises = selectedProjectIds.map((projectId) => {
        const project = projects.find((p) => p.id === projectId);
        if (!project) return Promise.resolve();

        const updatedPrecon = {
          ...(project.preconstruction || {}),
          clientDelay: true,
          history: [
            ...(project.preconstruction?.history || []),
            {
              action: "Client Delay",
              submittedBy: userEmail,
              date: new Date().toISOString(),
            },
          ],
        };

        const updatedProject = {
          ...project,
          preconstruction: updatedPrecon,
        };

        return updateById(TABLES.PROJECTS, updatedProject, project.id);
      });

      await Promise.all(updatePromises);

      message.success("Selected projects have been marked as delayed.");
      setIsClientDelayModalVisible(false);
      clientDelayForm.resetFields();
      refreshProjects();
    } catch (err) {
      console.error(err);
      message.error("Failed to mark selected projects as delayed.");
    } finally {
      setClientDelayLoading(false);
      refreshProjects();
    }
  };

  // Function to remove Client Delay
  const removeClientDelay = async (project) => {
    Modal.confirm({
      title: "Remove Client Delay",
      icon: <WarningOutlined />,
      content:
        "Are you sure you want to remove the client delay from this project?",
      onOk: async () => {
        try {
          const updatedPrecon = {
            ...(project.preconstruction || {}),
            clientDelay: false,
            history: [
              ...(project.preconstruction?.history || []),
              {
                action: "Client Delay Removed",
                submittedBy: userEmail,
                date: new Date().toISOString(),
              },
            ],
          };

          const updatedProject = {
            ...project,
            preconstruction: updatedPrecon,
          };

          await updateById(TABLES.PROJECTS, updatedProject, project.id);
          message.success("Client delay removed successfully.");
          refreshProjects();
        } catch (err) {
          console.error(err);
          message.error("Failed to remove client delay.");
        }
      },
    });
  };

  return (
    <div style={{ width: "100%" }}>
      {/* 1. Statistics Row */}
      <Row
        gutter={[0, 16]}
        style={{
          borderRadius: 8,
          marginBottom: 8,
        }}
      >
        {/* Title */}
        <Col span={6}>
          <HeaderText text="Preconstruction" />
        </Col>

        {/* Side-by-side "Total Projects" */}
        <Col span={6}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <span style={{ marginRight: 8, opacity: 0.7 }}>
              Total Projects:
            </span>
            <span style={{ fontSize: 18 }}>{statistics.totalProjects}</span>
          </div>
        </Col>

        {/* Side-by-side "Total Amount" */}
        <Col span={6}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <span style={{ marginRight: 8, opacity: 0.7 }}>Total Amount:</span>
            <span
              style={{
                color: statistics.totalAmount > 0 ? "#3f8600" : "black",
                fontSize: 18,
              }}
            >
              {formatMoney(statistics.totalAmount)}
            </span>
          </div>
        </Col>

        {/* Optional Client Delay button */}
        <Col span={6} style={{ textAlign: "right" }}>
          {!viewOnly && (
            <Button
              danger
              icon={<WarningOutlined />}
              onClick={() => setIsClientDelayModalVisible(true)}
              disabled={eligibleProjects.length === 0}
            >
              Client Delay
            </Button>
          )}
        </Col>
      </Row>

      {/* 2. Ordering and Filtering Controls */}
      <div
        style={{
          position: "sticky",
          top: 16,
          zIndex: 6,
          padding: "16px 0",
          borderBottom: "1px solid #f0f0f0",
          backgroundColor: "white",
          marginBottom: 8,
          borderRadius: 8,
        }}
      >
        <Row gutter={16} style={{ paddingRight: 16, paddingLeft: 16 }}>
          {/* Search Input */}
          <Col span={6}>
            <Input
              placeholder="Search by Project Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              allowClear
              prefix={<SearchOutlined />}
            />
          </Col>

          {/* Ordering with Sort Icon */}
          <Col span={6}>
            <Select
              value={order}
              onChange={(value) => setOrder(value)}
              style={{ width: "100%" }}
              placeholder="Order Projects"
              suffixIcon={<SortAscendingOutlined />}
            >
              <Option value="most">Most Progressed</Option>
              <Option value="least">Least Progressed</Option>
            </Select>
          </Col>

          {/* Filtering by Stage with Filter Icon and Icons in Options */}
          <Col span={6}>
            <Select
              value={filterStatus}
              onChange={(value) => setFilterStatus(value)}
              style={{ width: "100%" }}
              dropdownStyle={{ overflowY: "auto" }}
              placeholder="Filter by Stage"
              listHeight={10 * 32}
              suffixIcon={<FilterOutlined />}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.props.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Option value="All Stages">All Stages</Option>
              <Option value="Client Delay">
                <WarningOutlined style={{ marginRight: 8, color: "red" }} />
                Client Delay
              </Option>
              <Option value="Issued for Preconstruction">
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ marginRight: 8, color: "gray" }}
                />
                Issued for Preconstruction
              </Option>
              {PRECON_STATUS_FLOW.map((status) => (
                <Option key={status} value={status}>
                  <FontAwesomeIcon
                    icon={getIconForStatus(status)}
                    style={{ marginRight: 8, color: COLORS.PRIMARY }}
                  />
                  {status}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Filtering by Product Type with Filter Icon */}
          <Col span={6}>
            <Select
              value={filterProductType}
              onChange={(value) => setFilterProductType(value)}
              style={{ width: "100%" }}
              placeholder="Filter by Product Type"
              suffixIcon={<FilterOutlined />}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {productTypes.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>

      {/* 3. Infinite Scroll Component */}
      <InfiniteScroll
        dataLength={displayedProjects.length} // This is important field to render the next data
        next={fetchMoreProjects}
        hasMore={hasMore}
        style={{ overflow: "visible" }}
        loader={
          <div style={{ textAlign: "center", padding: "20px 0" }}>
            <Spin tip="Loading more projects..." />
          </div>
        }
      >
        {/* Projects List Using PreconCard */}
        {displayedProjects.map((project) => (
          <PreconCard
            key={project.id}
            project={project}
            viewOnly={viewOnly}
            onClientDelayRemove={removeClientDelay}
          />
        ))}
      </InfiniteScroll>

      {/* 4. Client Delay Modal */}
      <Modal
        visible={isClientDelayModalVisible}
        title="Mark Projects as Delayed"
        onCancel={() => setIsClientDelayModalVisible(false)}
        onOk={() => clientDelayForm.submit()}
        okText="Mark as Delayed"
        cancelText="Cancel"
        confirmLoading={clientDelayLoading}
      >
        <Form
          form={clientDelayForm}
          layout="vertical"
          onFinish={handleClientDelaySubmit}
        >
          <Form.Item
            name="projects"
            label="Select Projects to Delay"
            rules={[
              {
                required: true,
                message: "Please select at least one project to delay.",
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              placeholder="Search and select projects to delay"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {eligibleProjects.map((project) => (
                <Option key={project.id} value={project.id}>
                  {project.projectName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Preconstruction;
