import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Modal,
  Input,
  InputNumber,
  Table,
  Typography,
  message,
  Empty,
  Row,
  Col,
  Badge,
  Select,
  Tag,
  Divider,
} from "antd";
import {
  SaveOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  StopOutlined,
  ExclamationCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import HeaderText from "../../Styled/HeaderText";
import { capitalize } from "lodash";
import { formatCommas } from "../../Formatters/helpers";
import DimensionsCell from "../../Styled/DimensionCell";

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

/** Generate next SL #, e.g. SL1, SL2, etc. */
function getNextSLNumber(shipLooseItems = []) {
  if (!shipLooseItems.length) return "SL1";
  const numbers = shipLooseItems
    .map((item) => {
      if (!item.slNumber) return 0;
      const match = item.slNumber.match(/^SL(\d+)$/i);
      return match ? parseInt(match[1], 10) : 0;
    })
    .filter((num) => num > 0);
  const maxNum = numbers.length ? Math.max(...numbers) : 0;
  return `SL${maxNum + 1}`;
}

/** Generate next Pallet #, e.g. PAL01, PAL02, etc. */
function getNextPalletName(palletsArray = []) {
  if (!palletsArray.length) return "PAL01";
  const numbers = palletsArray
    .map((p) => {
      const match = p.palletName?.match(/^PAL(\d+)$/i);
      return match ? parseInt(match[1], 10) : 0;
    })
    .filter((num) => num > 0);
  const maxNum = numbers.length ? Math.max(...numbers) : 0;
  const next = String(maxNum + 1).padStart(2, "0");
  return `PAL${next}`;
}

/**
 * Helper: Sum how many of `itemId` are assigned in ALL other pallets/modules,
 * excluding the current pallet/module if editing.
 */
function getAssignedInOthers(shipLoose, itemId, editingId, editingType) {
  let assignedInOthers = 0;

  // sum from all pallets except the one we're editing
  shipLoose.pallets.forEach((pal) => {
    if (editingType === "pallet" && pal.id === editingId) return;
    pal.assignedItems.forEach((ai) => {
      if (ai.itemId === itemId) {
        assignedInOthers += ai.quantity;
      }
    });
  });

  // sum from all modules except the one we're editing
  shipLoose.modules.forEach((mod) => {
    if (editingType === "module" && mod.id === editingId) return;
    mod.assignedItems.forEach((ai) => {
      if (ai.itemId === itemId) {
        assignedInOthers += ai.quantity;
      }
    });
  });

  return assignedInOthers;
}

function ShipLoose({ project }) {
  const [form] = Form.useForm();

  // #1: Manage Items Modal
  const [isItemsModalVisible, setIsItemsModalVisible] = useState(false);

  // #2: Manage Pallet/Module Modal
  const [isPalletModuleModalVisible, setIsPalletModuleModalVisible] =
    useState(false);

  const [editingPalletModule, setEditingPalletModule] = useState(null);
  const [loading, setLoading] = useState(false);

  // ---------- Local states for "Add Ship Loose Item" row ----------
  const [assignedItems, setAssignedItems] = useState([]);
  const [selectItemId, setSelectItemId] = useState(null);
  const [selectQty, setSelectQty] = useState(1);

  // For real-time availability in the "Add Ship Loose Item" row
  const [availableForCurrentItem, setAvailableForCurrentItem] = useState(null);

  // Initialize form data on mount or project change
  useEffect(() => {
    const shipLoose = {
      items: project.shipLoose?.items || [],
      pallets: project.shipLoose?.pallets || [],
      modules: project.shipLoose?.modules || [],
    };

    // Ensure each item has an ID + qtyAssigned
    const updatedItems = shipLoose.items.map((item) => ({
      ...item,
      id: item.id || uuidv4(),
      qtyAssigned: item.qtyAssigned ?? 0,
    }));

    form.setFieldsValue({
      shipLoose: {
        items: updatedItems,
        pallets: shipLoose.pallets,
        modules: shipLoose.modules,
      },
    });
  }, [project, form]);

  // Immediately persist changes to DB
  const saveChangesToDB = async (updatedShipLoose) => {
    try {
      setLoading(true);
      const updatedProject = { ...project, shipLoose: updatedShipLoose };
      await updateById(TABLES.PROJECTS, updatedProject, project.id);
    } catch (err) {
      console.error(err);
      message.error("Failed to save. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Delete ALL Ship Loose
  const handleDeleteShipLoose = () => {
    confirm({
      title: "Are you sure you want to DELETE ALL Ship Loose data?",
      icon: <ExclamationCircleOutlined />,
      content:
        "This will remove all items, pallets, modules, and assigned items from the project.",
      onOk: async () => {
        try {
          const updatedProject = {
            ...project,
            shipLoose: { items: [], pallets: [], modules: [] },
          };
          setLoading(true);
          await updateById(TABLES.PROJECTS, updatedProject, project.id);
          message.success("All Ship Loose data deleted.");

          form.setFieldsValue({
            shipLoose: { items: [], pallets: [], modules: [] },
          });
        } catch (error) {
          console.error(error);
          message.error("Failed to delete Ship Loose data.");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  // #1) Manage Items Modal
  const showItemsModal = () => setIsItemsModalVisible(true);
  const handleItemsModalCancel = () => setIsItemsModalVisible(false);

  const handleItemsModalSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedShipLoose = { ...values.shipLoose };
      await saveChangesToDB(updatedShipLoose);
      setIsItemsModalVisible(false);
    } catch (err) {
      console.error(err);
    }
  };

  // "Manage Items" table
  const modalItemColumns = (fields, remove) => [
    {
      title: "SL #",
      dataIndex: "slNumber",
      width: 70,
      render: (_, record) => (
        <Form.Item name={[record.fieldIndex, "slNumber"]} noStyle>
          <Input readOnly />
        </Form.Item>
      ),
    },
    {
      title: "Description *",
      dataIndex: "itemDescription",
      render: (_, record) => (
        <Form.Item
          noStyle
          name={[record.fieldIndex, "itemDescription"]}
          rules={[{ required: true, message: "Description required" }]}
        >
          <TextArea autoSize placeholder="Item Description" />
        </Form.Item>
      ),
    },
    {
      title: "Quantity *",
      dataIndex: "quantity",
      width: 70,
      render: (_, record) => (
        <Form.Item
          noStyle
          name={[record.fieldIndex, "quantity"]}
          rules={[{ required: true, message: "Quantity required" }]}
        >
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      render: (_, record) => (
        <Form.Item noStyle name={[record.fieldIndex, "notes"]}>
          <TextArea autoSize placeholder="Optional" />
        </Form.Item>
      ),
    },
    {
      title: "Remove",
      width: 80,
      render: (_, record) => (
        <Button
          icon={<DeleteOutlined />}
          danger
          onClick={() => remove(record.fieldIndex)}
        />
      ),
    },
  ];

  const renderItemsModalTable = () => (
    <Form.List name={["shipLoose", "items"]}>
      {(fields, { add, remove }) => {
        const dataSource = fields.map((field) => {
          const rowKey = form.getFieldValue([
            "shipLoose",
            "items",
            field.name,
            "id",
          ]);
          return { fieldIndex: field.name, id: rowKey };
        });

        const handleAddNewItem = () => {
          const currentItems = form.getFieldValue(["shipLoose", "items"]) || [];
          const newSLNumber = getNextSLNumber(currentItems);
          add({
            id: uuidv4(),
            slNumber: newSLNumber,
            itemDescription: "",
            quantity: 1,
            qtyAssigned: 0,
            notes: "",
          });
        };

        return (
          <>
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={handleAddNewItem}
              style={{ marginBottom: 16 }}
            >
              Add New Item
            </Button>
            <Table
              dataSource={dataSource}
              columns={modalItemColumns(fields, remove)}
              rowKey="id"
              pagination={false}
              bordered
            />
          </>
        );
      }}
    </Form.List>
  );

  // #2) Manage Pallet/Module Modal
  const [palletModuleForm] = Form.useForm();

  const showPalletModuleModal = (palletOrModule = null) => {
    if (palletOrModule) {
      setEditingPalletModule({
        id: palletOrModule.id,
        type: palletOrModule.type,
      });
    } else {
      setEditingPalletModule(null);
    }
    setIsPalletModuleModalVisible(true);
  };

  const handlePalletModuleModalCancel = () => {
    setIsPalletModuleModalVisible(false);
    setEditingPalletModule(null);
    palletModuleForm.resetFields();
    setAssignedItems([]);
    setSelectItemId(null);
    setSelectQty(1);
    setAvailableForCurrentItem(null);
  };

  const handlePalletModuleModalOk = async () => {
    try {
      const shipLoose = form.getFieldValue("shipLoose");
      if (!shipLoose) return;

      // Validate the form to ensure required fields are filled
      const values = await palletModuleForm.validateFields();

      // Determine the type (pallet or module)
      const typeVal = values.type;
      if (!typeVal) {
        return message.error("Please select Pallet or Module first.");
      }

      // Build the assignedItems array from local state
      const newAssignedItems = assignedItems.map(({ itemId, quantity }) => ({
        itemId,
        quantity,
      }));

      let targetObj = null;

      // EDIT existing
      if (editingPalletModule) {
        if (editingPalletModule.type === "pallet") {
          // Find the existing pallet
          const idx = shipLoose.pallets.findIndex(
            (p) => p.id === editingPalletModule.id
          );
          if (idx >= 0) {
            targetObj = shipLoose.pallets[idx];
            targetObj.description = values.description || "";
            targetObj.length = values.length;
            targetObj.lengthIn = values.lengthIn; // NEW
            targetObj.width = values.width;
            targetObj.widthIn = values.widthIn; // NEW
            targetObj.height = values.height;
            targetObj.heightIn = values.heightIn; // NEW
            targetObj.weight = values.weight;
            targetObj.assignedItems = newAssignedItems;
          }
        } else {
          // Edit existing module
          const idx = shipLoose.modules.findIndex(
            (m) => m.id === editingPalletModule.id
          );
          if (idx >= 0) {
            targetObj = shipLoose.modules[idx];
            const chosenModule = project.modules?.find(
              (mod) => mod.id === values.moduleId
            );
            targetObj.moduleId = chosenModule?.id;
            targetObj.moduleNumber = chosenModule?.number;
            targetObj.description = values.description || "";
            targetObj.assignedItems = newAssignedItems;
            // If you also want inches on modules, add them here
            // e.g. targetObj.lengthIn = values.lengthIn, etc.
          }
        }
      } else {
        // CREATE new
        if (typeVal === "pallet") {
          const newPalletName = getNextPalletName(shipLoose.pallets);
          targetObj = {
            id: uuidv4(),
            type: "pallet",
            palletName: newPalletName,
            description: values.description || "",
            length: values.length,
            lengthIn: values.lengthIn || 0, // NEW
            width: values.width,
            widthIn: values.widthIn || 0, // NEW
            height: values.height,
            heightIn: values.heightIn || 0, // NEW
            weight: values.weight,
            assignedItems: newAssignedItems,
          };
          shipLoose.pallets.push(targetObj);
        } else {
          // Create a new module
          const chosenModule = project.modules?.find(
            (mod) => mod.id === values.moduleId
          );
          if (!chosenModule) {
            return message.error("Invalid module selected.");
          }
          targetObj = {
            id: uuidv4(),
            type: "module",
            moduleId: chosenModule.id,
            moduleNumber: chosenModule.number,
            description: values.description || "",
            assignedItems: newAssignedItems,
            // If modules also need inches:
            // length: values.length,
            // lengthIn: values.lengthIn || 0,
            // ...
          };
          shipLoose.modules.push(targetObj);
        }
      }

      // Recalculate qtyAssigned
      shipLoose.items.forEach((item) => {
        item.qtyAssigned = 0;
      });
      shipLoose.pallets.forEach((pal) => {
        pal.assignedItems.forEach((ai) => {
          const foundItem = shipLoose.items.find((itm) => itm.id === ai.itemId);
          if (foundItem) foundItem.qtyAssigned += ai.quantity;
        });
      });
      shipLoose.modules.forEach((mod) => {
        mod.assignedItems.forEach((ai) => {
          const foundItem = shipLoose.items.find((itm) => itm.id === ai.itemId);
          if (foundItem) foundItem.qtyAssigned += ai.quantity;
        });
      });

      // Update the form values and save changes to the database
      form.setFieldsValue({ shipLoose });
      await saveChangesToDB(shipLoose);

      // Reset modal state
      setIsPalletModuleModalVisible(false);
      setEditingPalletModule(null);
      palletModuleForm.resetFields();
      setAssignedItems([]);
      setSelectItemId(null);
      setSelectQty(1);
      setAvailableForCurrentItem(null);
    } catch (error) {
      console.error(error);
      message.error("An error occurred. Please try again.");
    }
  };

  const handleRemovePalletModule = async () => {
    try {
      const shipLoose = form.getFieldValue("shipLoose");
      if (!shipLoose || !editingPalletModule) return;

      if (editingPalletModule.type === "pallet") {
        shipLoose.pallets = shipLoose.pallets.filter(
          (p) => p.id !== editingPalletModule.id
        );
      } else {
        shipLoose.modules = shipLoose.modules.filter(
          (m) => m.id !== editingPalletModule.id
        );
      }

      // Recalc
      shipLoose.items.forEach((item) => {
        item.qtyAssigned = 0;
      });
      shipLoose.pallets.forEach((pal) => {
        pal.assignedItems.forEach((ai) => {
          const foundItem = shipLoose.items.find((itm) => itm.id === ai.itemId);
          if (foundItem) foundItem.qtyAssigned += ai.quantity;
        });
      });
      shipLoose.modules.forEach((mod) => {
        mod.assignedItems.forEach((ai) => {
          const foundItem = shipLoose.items.find((itm) => itm.id === ai.itemId);
          if (foundItem) foundItem.qtyAssigned += ai.quantity;
        });
      });

      form.setFieldsValue({ shipLoose });
      await saveChangesToDB(shipLoose);

      message.success("Removed Pallet/Module!");
      setIsPalletModuleModalVisible(false);
      setEditingPalletModule(null);
      palletModuleForm.resetFields();
      setAssignedItems([]);
      setSelectItemId(null);
      setSelectQty(1);
      setAvailableForCurrentItem(null);
    } catch (err) {
      console.error(err);
    }
  };

  // Duplicating a pallet
  const handleDuplicatePallet = async (originalPallet) => {
    try {
      const shipLoose = form.getFieldValue("shipLoose");
      if (!shipLoose) return;

      // 1) Gather info on each assigned item from the original pallet
      const itemsToDuplicate = originalPallet.assignedItems.map((ai) => {
        const foundItem = shipLoose.items.find((itm) => itm.id === ai.itemId);
        if (!foundItem) {
          return {
            ...ai,
            itemData: null,
            finalQty: 0,
            reason: "Item not found in global list",
          };
        }

        const available = foundItem.quantity - foundItem.qtyAssigned;
        let finalQty = 0;
        let reason = "";
        if (available <= 0) {
          finalQty = 0;
          reason = "No availability, item will be excluded";
        } else if (available < ai.quantity) {
          finalQty = available;
          reason = `Requested ${ai.quantity}, only ${available} available (partial)`;
        } else {
          finalQty = ai.quantity;
          reason = `Fully matched quantity ${ai.quantity}`;
        }

        return {
          ...ai,
          itemData: foundItem,
          requested: ai.quantity,
          available,
          finalQty,
          reason,
        };
      });

      // 2) Filter them into categories
      const fullyDuplicated = itemsToDuplicate.filter(
        (it) => it.finalQty > 0 && it.finalQty === it.requested
      );
      const partiallyDuplicated = itemsToDuplicate.filter(
        (it) => it.finalQty > 0 && it.finalQty < it.requested
      );
      const excluded = itemsToDuplicate.filter((it) => it.finalQty === 0);

      // If everything is excluded
      const allExcluded =
        fullyDuplicated.length === 0 && partiallyDuplicated.length === 0;
      if (allExcluded) {
        Modal.info({
          title: `Cannot duplicate pallet ${originalPallet.palletName}`,
          content:
            "No items have enough availability to be duplicated. All items have 0 availability.",
        });
        return;
      }

      // 3) Build a user-friendly message
      let confirmTitle = `Duplicate Pallet ${originalPallet.palletName}?`;
      let confirmContent = "Items to be duplicated:\n\n";

      if (fullyDuplicated.length > 0) {
        confirmContent += `Fully duplicated items:\n`;
        fullyDuplicated.forEach((it) => {
          confirmContent += `- ${it.itemData.slNumber} – ${it.itemData.itemDescription}\n  requested: ${it.requested}, final: ${it.finalQty}\n`;
        });
        confirmContent += "\n";
      }

      if (partiallyDuplicated.length > 0) {
        confirmContent += `Partially duplicated items:\n`;
        partiallyDuplicated.forEach((it) => {
          confirmContent += `- ${it.itemData.slNumber} – ${it.itemData.itemDescription}\n  requested: ${it.requested}, available: ${it.available}, final: ${it.finalQty}\n`;
        });
        confirmContent += "\n";
      }

      if (excluded.length > 0) {
        confirmContent += `Excluded (0 availability) items:\n`;
        excluded.forEach((it) => {
          confirmContent += `- ${
            it.itemData
              ? `${it.itemData.slNumber} – ${it.itemData.itemDescription}`
              : it.itemId
          }\n  requested: ${it.requested}, available: ${it.available}\n`;
        });
        confirmContent += "\n";
      }

      confirmContent += "Continue with this duplication?";

      // 4) Confirm modal
      confirm({
        title: confirmTitle,
        width: 600,
        icon: <ExclamationCircleOutlined />,
        content: (
          <pre
            style={{
              whiteSpace: "pre-wrap",
              fontFamily: "inherit",
              marginTop: 16,
            }}
          >
            {confirmContent}
          </pre>
        ),
        onOk: async () => {
          // Actually do the duplication
          const newPalletName = getNextPalletName(shipLoose.pallets);
          const newId = uuidv4();

          // Build assignedItems array from the finalQty
          const newAssignedItems = itemsToDuplicate
            .filter((it) => it.finalQty > 0)
            .map((it) => ({
              itemId: it.itemId,
              quantity: it.finalQty,
            }));

          const newPallet = {
            id: newId,
            type: "pallet",
            palletName: newPalletName,
            description: originalPallet.description || "",
            // If you want to duplicate the dimensions as well:
            length: originalPallet.length || 0,
            lengthIn: originalPallet.lengthIn || 0,
            width: originalPallet.width || 0,
            widthIn: originalPallet.widthIn || 0,
            height: originalPallet.height || 0,
            heightIn: originalPallet.heightIn || 0,
            weight: originalPallet.weight || 0,
            assignedItems: newAssignedItems,
          };
          shipLoose.pallets.push(newPallet);

          // Recalc all qtyAssigned
          shipLoose.items.forEach((item) => {
            item.qtyAssigned = 0;
          });
          shipLoose.pallets.forEach((pal) => {
            pal.assignedItems.forEach((ai) => {
              const foundItem = shipLoose.items.find(
                (itm) => itm.id === ai.itemId
              );
              if (foundItem) foundItem.qtyAssigned += ai.quantity;
            });
          });
          shipLoose.modules.forEach((mod) => {
            mod.assignedItems.forEach((ai) => {
              const foundItem = shipLoose.items.find(
                (itm) => itm.id === ai.itemId
              );
              if (foundItem) foundItem.qtyAssigned += ai.quantity;
            });
          });

          form.setFieldsValue({ shipLoose });
          await saveChangesToDB(shipLoose);
          message.success("Pallet duplicated successfully!");
        },
        onCancel: () => {
          // do nothing
        },
      });
    } catch (err) {
      console.error(err);
      message.error("Could not duplicate pallet. Please try again.");
    }
  };

  // Real-time availability whenever selectItemId changes
  useEffect(() => {
    if (!selectItemId) {
      setAvailableForCurrentItem(null);
      return;
    }
    // If we have an item selected, compute availability
    const shipLoose = form.getFieldValue("shipLoose");
    const allItems = shipLoose.items || [];
    const foundItem = allItems.find((i) => i.id === selectItemId);
    if (!foundItem) {
      setAvailableForCurrentItem(null);
      return;
    }
    const assignedElsewhere = getAssignedInOthers(
      shipLoose,
      foundItem.id,
      editingPalletModule?.id,
      editingPalletModule?.type
    );
    const maxAvailable = foundItem.quantity - assignedElsewhere;
    setAvailableForCurrentItem(maxAvailable < 0 ? 0 : maxAvailable);
  }, [selectItemId, assignedItems, editingPalletModule, form]);

  const handleAddAssignedItem = () => {
    if (!selectItemId) {
      return message.warning("Please select an item first.");
    }
    if (selectQty < 1) {
      return message.warning("Quantity must be at least 1.");
    }
    if (availableForCurrentItem == null) {
      return message.warning("No item selected or no availability computed.");
    }
    if (availableForCurrentItem <= 0) {
      return message.warning("No availability for this item.");
    }
    if (selectQty > availableForCurrentItem) {
      return message.warning(
        `Cannot assign more than ${availableForCurrentItem} to this pallet/module.`
      );
    }

    // Check if we already assigned that item in local assignedItems
    if (assignedItems.some((a) => a.itemId === selectItemId)) {
      return message.warning("That item is already assigned here.");
    }

    // Push into local state
    const newItem = { itemId: selectItemId, quantity: selectQty };
    setAssignedItems([...assignedItems, newItem]);
    // Clear the selection
    setSelectItemId(null);
    setSelectQty(1);
    setAvailableForCurrentItem(null);
  };

  const handleRemoveAssignedItem = (itemId) => {
    const newArr = assignedItems.filter((a) => a.itemId !== itemId);
    setAssignedItems(newArr);
  };

  // Table (read-only) for assigned items in the Pallet/Module modal
  const assignedItemColumns = [
    {
      title: "Item",
      dataIndex: "itemId",
      render: (itemId) => {
        const shipLoose = form.getFieldValue("shipLoose");
        const allItems = shipLoose.items || [];
        const found = allItems.find((i) => i.id === itemId);
        if (!found) return itemId;
        return `${found.slNumber} – ${found.itemDescription}`;
      },
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      width: 60,
      render: (val) => <Text>{val}</Text>,
    },
    {
      title: "Remove",
      width: 60,
      render: (_, record) => (
        <Button
          icon={<DeleteOutlined />}
          danger
          onClick={() => handleRemoveAssignedItem(record.itemId)}
        />
      ),
    },
  ];

  // Filter items that are NOT already in assignedItems
  const shipLooseData = form.getFieldValue("shipLoose") || {};
  const allShipLooseItems = shipLooseData.items || [];
  const assignedItemIds = assignedItems.map((a) => a.itemId);
  const unassignedItems = allShipLooseItems.filter(
    (i) => !assignedItemIds.includes(i.id)
  );

  // We'll show "Add Pallet" / "Edit Pallet" / "Add Module" / "Edit Module"
  const modalTitle = (() => {
    const typeVal = palletModuleForm.getFieldValue("type");
    if (!typeVal) {
      // if editing, might know from editingPalletModule.type
      if (editingPalletModule) {
        return editingPalletModule.type === "pallet"
          ? "Edit Pallet"
          : "Edit Module";
      }
      return "Add Pallet / Module"; // fallback
    }
    // else we have a type
    const isEditing = !!editingPalletModule;
    if (typeVal === "pallet") {
      return isEditing ? "Edit Pallet" : "Add Pallet";
    } else {
      // module
      return isEditing ? "Edit Module" : "Add Module";
    }
  })();

  // Display columns for main Ship Loose Items table
  const itemsCount = project.shipLoose?.items?.length || 0;

  const displayItemColumns = [
    {
      title: "SL #",
      dataIndex: "slNumber",
      key: "slNumber",
      width: 70,
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 80,
      render: (val) =>
        val != null ? (
          <Text>{val}</Text>
        ) : (
          <StopOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Assigned",
      dataIndex: "qtyAssigned",
      key: "qtyAssigned",
      width: 80,
      render: (val) => (val != null ? <Text>{val}</Text> : 0),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (val) =>
        val ? <Text>{val}</Text> : <StopOutlined style={{ color: "red" }} />,
    },
  ];

  // Pallets table
  const palletsColumns = [
    {
      title: "Pallet #",
      dataIndex: "palletName",
      key: "palletName",
      width: 100,
    },
    {
      title: "Dimensions (L x W x H)",
      key: "dimensions",
      render: (_, record) => (
        <DimensionsCell
          length={record.length}
          lengthIn={record.lengthIn}
          width={record.width}
          widthIn={record.widthIn}
          height={record.height}
          heightIn={record.heightIn}
        />
      ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (val) =>
        val != null ? (
          `${formatCommas(val)} lbs`
        ) : (
          <StopOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (val) => (val ? val : <StopOutlined style={{ color: "red" }} />),
    },
    {
      title: "Assigned Items",
      key: "assignedItems",
      render: (_, record) => {
        if (!record.assignedItems?.length) {
          return <Text type="secondary">No items</Text>;
        }
        const allItems = project.shipLoose?.items || [];
        return record.assignedItems.map((ai, idx) => {
          const item = allItems.find((itm) => itm.id === ai.itemId);
          return (
            <div
              key={idx}
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <Tag
                style={{
                  display: "inline-block",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  paddingLeft: 0,
                  marginTop: 4,
                }}
              >
                <Tag color="blue" style={{ marginLeft: 0 }}>
                  {ai.quantity}
                </Tag>
                {item
                  ? `${item.slNumber} – ${item.itemDescription}`
                  : `Item ID: ${ai.itemId}`}
              </Tag>
            </div>
          );
        });
      },
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => showPalletModuleModal(record)}
            style={{ marginRight: 8 }}
          >
            Edit
          </Button>
          <Button
            icon={<CopyOutlined />}
            onClick={() => handleDuplicatePallet(record)}
          >
            Duplicate
          </Button>
        </div>
      ),
    },
  ];

  // Modules table
  const modulesColumns = [
    {
      title: "Module #",
      dataIndex: "moduleNumber",
      key: "moduleNumber",
      width: 100,
      render: (text) => <Text>{project.projectName + "-" + text}</Text>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (val) => (val ? val : <StopOutlined style={{ color: "red" }} />),
    },
    {
      title: "Assigned Items",
      key: "assignedItems",
      render: (_, record) => {
        if (!record.assignedItems?.length) {
          return <Text type="secondary">No items</Text>;
        }
        const allItems = project.shipLoose?.items || [];
        return record.assignedItems.map((ai, idx) => {
          const item = allItems.find((itm) => itm.id === ai.itemId);
          return (
            <div
              key={idx}
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <Tag
                style={{
                  display: "inline-block",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  paddingLeft: 0,
                  marginTop: 4,
                }}
              >
                <Tag color="blue" style={{ marginLeft: 0 }}>
                  {ai.quantity}
                </Tag>
                {item
                  ? `${item.slNumber} – ${item.itemDescription}`
                  : `Item ID: ${ai.itemId}`}
              </Tag>
            </div>
          );
        });
      },
    },
    {
      title: "Action",
      key: "action",
      width: 130,
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => showPalletModuleModal(record)}
        >
          Edit
        </Button>
      ),
    },
  ];

  // On open of the Pallet/Module modal, load data if editing
  useEffect(() => {
    if (!isPalletModuleModalVisible) return;

    const shipLoose = form.getFieldValue("shipLoose");
    if (!editingPalletModule) {
      // Creating a new pallet/module
      palletModuleForm.resetFields();
      setAssignedItems([]);
      setSelectItemId(null);
      setSelectQty(1);
      setAvailableForCurrentItem(null);
      return;
    }

    // Editing an existing pallet/module
    let existingObj;
    if (editingPalletModule.type === "pallet") {
      existingObj = shipLoose.pallets.find(
        (p) => p.id === editingPalletModule.id
      );
      if (existingObj) {
        palletModuleForm.setFieldsValue({
          type: "pallet",
          description: existingObj.description || "",
          length: existingObj.length || 0,
          lengthIn: existingObj.lengthIn || 0, // NEW
          width: existingObj.width || 0,
          widthIn: existingObj.widthIn || 0, // NEW
          height: existingObj.height || 0,
          heightIn: existingObj.heightIn || 0, // NEW
          weight: existingObj.weight || 0,
        });
        // Populate local assigned items
        setAssignedItems([...existingObj.assignedItems]);
      }
    } else {
      // Editing a module
      existingObj = shipLoose.modules.find(
        (m) => m.id === editingPalletModule.id
      );
      if (existingObj) {
        palletModuleForm.setFieldsValue({
          type: "module",
          moduleId: existingObj.moduleId,
          description: existingObj.description || "",
          // If you also store inches for modules, set them here:
          // length: existingObj.length || 0,
          // lengthIn: existingObj.lengthIn || 0,
        });
        setAssignedItems([...existingObj.assignedItems]);
      }
    }
  }, [isPalletModuleModalVisible, editingPalletModule, palletModuleForm, form]);

  const palletList = project.shipLoose?.pallets || [];
  const moduleList = project.shipLoose?.modules || [];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <HeaderText medium text="Ship Loose Items" />
        </Col>
        <Col span={16} style={{ textAlign: "right" }}>
          {/* Example button for deleting everything (commented out for caution):
          <Button
            danger
            icon={<DeleteOutlined />}
            style={{ marginRight: 8 }}
            onClick={handleDeleteShipLoose}
          >
            Delete ALL Ship Loose
          </Button> */}
          <Button
            type="primary"
            icon={itemsCount > 0 ? <EditOutlined /> : <PlusOutlined />}
            onClick={showItemsModal}
          >
            {itemsCount > 0 ? "Edit Ship Loose Items" : "Add Ship Loose Items"}
          </Button>
        </Col>

        <Col span={24}>
          {itemsCount === 0 ? (
            <Empty description="No Ship Loose Items" />
          ) : (
            <Table
              dataSource={project.shipLoose.items}
              columns={displayItemColumns}
              rowKey="id"
              pagination={false}
              bordered
            />
          )}
        </Col>

        {/* Pallets/Modules Section */}
        {itemsCount > 0 && (
          <>
            <Divider style={{ margin: "8px 0" }} />
            <Col span={12}>
              <HeaderText medium text="Pallets / In Modules" />
            </Col>
            <Col span={12} align="right">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => showPalletModuleModal(null)}
              >
                Add Pallet / Module
              </Button>
            </Col>

            {palletList.length > 0 && (
              <Col span={24} style={{ marginTop: 16 }}>
                <HeaderText small text="Pallets" />
                <Table
                  dataSource={palletList}
                  columns={palletsColumns}
                  rowKey="id"
                  pagination={false}
                  bordered
                />
              </Col>
            )}

            {moduleList.length > 0 && (
              <Col span={24} style={{ marginTop: 16 }}>
                <HeaderText small text="In Modules" />
                <Table
                  dataSource={moduleList}
                  columns={modulesColumns}
                  rowKey="id"
                  pagination={false}
                  bordered
                />
              </Col>
            )}
          </>
        )}
      </Row>

      {/* MODAL #1: Manage Ship Loose Items */}
      <Modal
        title="Manage Ship Loose Items"
        visible={isItemsModalVisible}
        onCancel={handleItemsModalCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleItemsModalCancel}
            icon={<CloseOutlined />}
          >
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleItemsModalSave}
            loading={loading}
            icon={<SaveOutlined />}
          >
            Save
          </Button>,
        ]}
        width="80%"
        destroyOnClose
      >
        <Form form={form} layout="vertical">
          {renderItemsModalTable()}
        </Form>
      </Modal>

      {/* MODAL #2: Manage Pallet or Module */}
      <Modal
        title={modalTitle}
        width="80%"
        visible={isPalletModuleModalVisible}
        onCancel={handlePalletModuleModalCancel}
        footer={[
          editingPalletModule && (
            <Button
              key="remove"
              danger
              icon={<DeleteOutlined />}
              onClick={handleRemovePalletModule}
            >
              {`Remove ${capitalize(editingPalletModule.type)}`}
            </Button>
          ),
          <Button
            key="cancel"
            onClick={handlePalletModuleModalCancel}
            icon={<CloseOutlined />}
          >
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handlePalletModuleModalOk}
            loading={loading}
            icon={<SaveOutlined />}
          >
            Save
          </Button>,
        ].filter(Boolean)}
        destroyOnClose
      >
        <Form form={palletModuleForm} layout="vertical">
          <Form.Item
            name="type"
            label="Type"
            rules={[
              { required: true, message: "Please choose Pallet or Module" },
            ]}
          >
            <Select
              placeholder="Select one"
              disabled={!!editingPalletModule}
              onChange={() => {
                // if they switch type, we might need to reset 'moduleId'
                palletModuleForm.setFieldsValue({ moduleId: null });
              }}
            >
              <Option value="pallet">Pallet</Option>
              <Option value="module">Module</Option>
            </Select>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              getFieldValue("type") === "pallet" && (
                <>
                  <Row gutter={16}>
                    <Col span={3}>
                      <Form.Item
                        name="length"
                        label="Length (ft)"
                        rules={[
                          { required: true, message: "Please enter length" },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          addonAfter="ft"
                          min={0}
                          style={{ width: "100%" }}
                          placeholder="Length"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name="lengthIn"
                        label="Length (in)"
                        rules={[
                          { required: true, message: "Please enter inches" },
                          { type: "number", max: 11.99, min: 0 },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          addonAfter="in"
                          min={0}
                          style={{ width: "100%" }}
                          placeholder="Length (in)"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name="width"
                        label="Width (ft)"
                        rules={[
                          { required: true, message: "Please enter width" },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          addonAfter="ft"
                          min={0}
                          style={{ width: "100%" }}
                          placeholder="Width"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name="widthIn"
                        label="Width (in)"
                        rules={[
                          { required: true, message: "Please enter inches" },
                          { type: "number", max: 11.99, min: 0 },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          addonAfter="in"
                          min={0}
                          style={{ width: "100%" }}
                          placeholder="Width (in)"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item
                        name="height"
                        label="Height (ft)"
                        rules={[
                          { required: true, message: "Please enter height" },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          addonAfter="ft"
                          min={0}
                          style={{ width: "100%" }}
                          placeholder="Height"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name="heightIn"
                        label="Height (in)"
                        rules={[
                          { required: true, message: "Please enter inches" },
                          { type: "number", max: 11.99, min: 0 },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          addonAfter="in"
                          min={0}
                          style={{ width: "100%" }}
                          placeholder="Height (in)"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="weight"
                        label="Weight"
                        rules={[
                          { required: true, message: "Please enter weight" },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          addonAfter="Lbs"
                          min={0}
                          style={{ width: "100%" }}
                          placeholder="Weight"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input />
          </Form.Item>

          {/* If module, show the moduleId field */}
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              getFieldValue("type") === "module" && (
                <Form.Item
                  name="moduleId"
                  label="Select a Module"
                  rules={[
                    { required: true, message: "Please choose a module" },
                  ]}
                >
                  <Select placeholder="Pick a module">
                    {(project.modules || []).map((m) => (
                      <Option key={m.id} value={m.id}>
                        {project.projectName} - {m.number}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            }
          </Form.Item>

          {/* Row/Col for "Ship Loose Items" */}
          <Form.Item label="Ship Loose Items">
            <Row gutter={16}>
              <Col span={16}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select an item"
                  value={selectItemId}
                  onChange={(val) => setSelectItemId(val)}
                >
                  {unassignedItems.map((itm) => (
                    <Option key={itm.id} value={itm.id}>
                      {itm.slNumber} – {itm.itemDescription}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col span={4}>
                <InputNumber
                  min={1}
                  value={selectQty}
                  onChange={(val) => setSelectQty(val)}
                  style={{ width: "100%" }}
                  // Show "Left: X" if we have availableForCurrentItem
                  addonAfter={
                    availableForCurrentItem != null
                      ? `Left: ${Math.max(
                          availableForCurrentItem - (selectQty || 0),
                          0
                        )}`
                      : ""
                  }
                />
              </Col>

              <Col span={4}>
                <Button
                  icon={<PlusOutlined />}
                  type="dashed"
                  style={{ width: "100%" }}
                  onClick={handleAddAssignedItem}
                >
                  Add Ship Loose Item
                </Button>
              </Col>
            </Row>
          </Form.Item>

          {/* Assigned Items Table */}
          <Table
            columns={assignedItemColumns}
            dataSource={assignedItems}
            rowKey={(record) => record.itemId}
            pagination={false}
            bordered
          />
        </Form>
      </Modal>
    </>
  );
}

export default ShipLoose;
