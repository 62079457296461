// src/components/HeaderText.jsx
import React from "react";
import PropTypes from "prop-types";

function HeaderText({ text, large, medium, small, style }) {
  // Determine the font size based on the props
  let fontSize = 24; // Default to 'xl'

  if (large) {
    fontSize = 20;
  } else if (medium) {
    fontSize = 18;
  } else if (small) {
    fontSize = 16;
  }

  return (
    <div style={{ fontSize: `${fontSize}px`, fontWeight: 600, ...style }}>
      {text}
    </div>
  );
}

// Define prop types for validation
HeaderText.propTypes = {
  text: PropTypes.string.isRequired, // The text to display
  large: PropTypes.bool, // Large size (20px)
  medium: PropTypes.bool, // Medium size (18px)
  small: PropTypes.bool, // Small size (16px)
  style: PropTypes.object, // Optional additional styles
};

// Define default props
HeaderText.defaultProps = {
  large: false,
  medium: false,
  small: false,
  style: {},
};

export default HeaderText;
