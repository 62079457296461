import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  Select,
  Checkbox,
  Button,
  InputNumber,
  message,
  Row,
  Col,
  Tag,
  Spin,
  DatePicker,
  Input,
} from "antd";
import {
  WarningOutlined,
  FilePdfOutlined,
  MailOutlined,
} from "@ant-design/icons";
import {
  useFreightAgents,
  useOutboundShipments,
} from "../../Contexts/useSpecificData";
import { sendEmail } from "../../Functions/sendEmail";
import { generatePdf } from "../../Functions/generatePdf";
import { getShipmentItems } from "./getShipmentItems";
import HeaderText from "../../Styled/HeaderText";
import useCurrentUser from "../../hooks/useCurrentUser";
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import dayjs from "dayjs";
import generateHtmlOutboundPurchaseOrder from "./generateHtmlOutboundPurchaseOrder";
import { formatMoney } from "../../Formatters/helpers";
import { formatPhoneNumber } from "../../Formatters/formatPhoneNumber";

// -------------------------------------------------------------
// Helper for parsing existing B-XXXX and generating the next
function parsePoNumber(poNum) {
  const match = poNum.match(/^B-(\d+)$/);
  if (!match) return null;
  return parseInt(match[1], 10);
}

function getNextPoNumber(existingPoNumbers = []) {
  let maxNumber = 0;
  existingPoNumbers.forEach((poNum) => {
    const num = parsePoNumber(poNum);
    if (num && num > maxNumber) {
      maxNumber = num;
    }
  });
  const nextNumber = maxNumber === 0 ? 4200 : maxNumber + 1;
  return `B-${nextNumber}`;
}
// -------------------------------------------------------------

function OutboundPurchaseOrder({ shipment, project }) {
  const { data: freightAgents = [] } = useFreightAgents();
  // Grab ALL outbound shipments from your context.
  // This gives you the data needed to find the highest existing PO number.
  const { data: allShipments = [] } = useOutboundShipments();

  const { userName, userEmail } = useCurrentUser();
  const today = dayjs().format("YYYY-MM-DD HH:mm:ss");

  // State for PO Number
  const [poNumber, setPoNumber] = useState(null);

  // Other existing states
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [agentExtraContacts, setAgentExtraContacts] = useState([]);
  const [notes, setNotes] = useState("");
  const [pickupDate, setPickupDate] = useState(null);
  const [amount, setAmount] = useState(null);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfLoading, setPdfLoading] = useState(false);

  // -------------------------------------------------------------
  // 1) On mount or when the shipment changes, figure out the PO number
  // -------------------------------------------------------------
  useEffect(() => {
    if (!shipment) return;

    // If there's a saved PO with a number, just use it
    const existingPO = shipment.purchaseOrder || {};
    setSelectedAgentId(existingPO.freightAgentId || null);
    setNotes(existingPO.notes || "");
    setPickupDate(existingPO.pickupDate ? dayjs(existingPO.pickupDate) : null);
    setAmount(existingPO.amount || null);
    setAgentExtraContacts(existingPO.extraContacts || []);

    if (existingPO.poNumber) {
      setPoNumber(existingPO.poNumber);
      return;
    }

    // Otherwise, we need to generate a new PO number
    // from all existing shipments
    const allPoNumbers = [];
    allShipments.forEach((s) => {
      const sPO = s.purchaseOrder;
      if (sPO?.poNumber) {
        allPoNumbers.push(sPO.poNumber);
      }
    });

    const nextNum = getNextPoNumber(allPoNumbers);
    setPoNumber(nextNum);
  }, [shipment, allShipments]);

  // -------------------------------------------------------------
  // 2) Check if we have no items
  // -------------------------------------------------------------
  const { truckModules, truckPallets } = useMemo(() => {
    if (!project || !shipment?.id) {
      return { truckModules: [], truckPallets: [] };
    }
    return getShipmentItems(project, shipment.id);
  }, [project, shipment?.id]);

  const emptyShipment = !truckModules.length && !truckPallets.length;

  // -------------------------------------------------------------
  // 3) Build the array of on-truck items for PDF/preview
  // -------------------------------------------------------------
  const onTruckItems = useMemo(() => {
    const modItems = truckModules.map((m) => ({
      type: "Module",
      id: m.id,
      weight: m.weight,
      weightIn: m.weightIn,
      heightIn: m.heightIn,
      lengthIn: m.lengthIn,
      widthIn: m.widthIn,
      length: m.length,
      width: m.width,
      height: m.height,
      description: project?.projectName + "-" + (m.number ?? "Module"),
    }));
    const palItems = truckPallets.map((p) => ({
      type: "Pallet",
      id: p.id,
      weight: p.weight,
      weightIn: p.weightIn,
      heightIn: p.heightIn,
      lengthIn: p.lengthIn,
      widthIn: p.widthIn,
      length: p.length,
      width: p.width,
      height: p.height,
      description: p.palletName || "Pallet",
    }));
    return [...modItems, ...palItems];
  }, [truckModules, truckPallets, project?.projectName]);

  // -------------------------------------------------------------
  // 4) Generate the HTML preview whenever relevant data changes
  // -------------------------------------------------------------
  useEffect(() => {
    if (!shipment) return;

    // Retrieve freight agent details based on selectedAgentId
    let freightAgentCompanyName = "N/A";
    let freightAgentName = "N/A";
    let freightAgentEmail = "N/A";
    let freightAgentPhone = "N/A";

    const filteredAgents = freightAgents.filter((fa) => !fa.disabled);
    const agent = filteredAgents.find((fa) => fa.id === selectedAgentId);
    if (agent) {
      freightAgentCompanyName = agent.companyName || "N/A";
      const primaryContact = (agent.contacts || []).find(
        (c) => c.primary && !isMissingContactInfo(c)
      );
      if (primaryContact) {
        freightAgentName = `${primaryContact.firstName} ${primaryContact.lastName}`;
        freightAgentEmail = primaryContact.email || "N/A";
        freightAgentPhone = formatPhoneNumber(primaryContact.phone) || "N/A";
      }
    }

    // Pass the poNumber into generateHtmlOutboundPurchaseOrder
    const html = generateHtmlOutboundPurchaseOrder({
      poNumber, // Include PO Number in your HTML
      type: shipment?.type || "Unknown Type",
      origin: shipment?.origin?.address || "Unknown Origin",
      destination: shipment?.destination?.address || "Unknown Destination",
      projectName: project?.projectName || "Untitled Project",
      items: onTruckItems,
      customDescription: notes,
      pickupDate: pickupDate ? pickupDate.format("dddd MMMM DD, YYYY") : "🚫",
      amount: amount,
      tripDistance: shipment?.tripDistance || "Unknown",
      shipmentNumber: shipment?.shipmentNumber,
      freightAgentCompanyName,
      freightAgentName,
      freightAgentEmail,
      freightAgentPhone,
    });

    setHtmlPreview(html);
    setPdfUrl("");
  }, [
    shipment,
    project,
    onTruckItems,
    notes,
    pickupDate,
    amount,
    selectedAgentId,
    freightAgents,
    poNumber, // Make sure to include in the dependency array
  ]);

  // -------------------------------------------------------------
  // 5) Utility: check if contact info is missing
  // -------------------------------------------------------------
  function isMissingContactInfo(contact) {
    const noEmail = !contact.email;
    const noName =
      (!contact.firstName || !contact.firstName.trim()) &&
      (!contact.lastName || !contact.lastName.trim());
    return noEmail || noName;
  }

  // -------------------------------------------------------------
  // 6) Save the purchase order (including poNumber) to DB
  // -------------------------------------------------------------
  const savePurchaseOrder = async () => {
    const existingPO = shipment.purchaseOrder
      ? { ...shipment.purchaseOrder }
      : {};

    // Save the PO number so it doesn't change once assigned
    existingPO.poNumber = poNumber;
    existingPO.pickupDate = pickupDate ? pickupDate.toISOString() : null;
    existingPO.notes = notes;
    existingPO.amount = amount;
    existingPO.freightAgentId = selectedAgentId;
    existingPO.extraContacts = agentExtraContacts;

    try {
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { purchaseOrder: existingPO },
        shipment.id
      );
      return existingPO;
    } catch (error) {
      console.error("Error saving purchase order:", error);
      message.error("Failed to save purchase order.");
      return null;
    }
  };

  // -------------------------------------------------------------
  // 7) Generate PDF
  // -------------------------------------------------------------
  async function handleGeneratePdf(openTab = true) {
    if (!htmlPreview) {
      message.warning("No HTML to generate PDF from!");
      return;
    }

    // Save purchase order data before generating PDF
    const savedPO = await savePurchaseOrder();
    if (!savedPO) return;

    let newTab;
    if (openTab) {
      // Open a blank tab and write a loading message
      newTab = window.open("", "_blank");
      if (newTab) {
        newTab.document.write(`
          <html>
            <head>
              <title>Generating PDF...</title>
            </head>
            <body>
              <p style="font-family: Arial; text-align: center; margin-top: 20%;">
                Generating your PDF, please wait...
              </p>
            </body>
          </html>
        `);
      }
    }

    // If we already have a PDF, just open it
    if (pdfUrl && openTab) {
      if (newTab) {
        newTab.location = pdfUrl;
      } else {
        window.open(pdfUrl, "_blank");
      }
      return pdfUrl;
    }

    setPdfLoading(true);
    try {
      const fileName = `LOGISTICS_PURCHASE_ORDER_${project?.projectName}_${poNumber}_${today}.pdf`;
      const result = await generatePdf(htmlPreview, fileName);
      if (result && result.location) {
        setPdfUrl(result.location);
        message.success("PDF generated successfully!");

        // Update lines in the purchase order with truck items
        const updatedPO = { ...savedPO, lines: onTruckItems };
        await updateById(
          TABLES.OUTBOUND_SHIPMENTS,
          { purchaseOrder: updatedPO },
          shipment.id
        );

        // Update the pre-opened tab
        if (openTab && newTab) {
          newTab.location = result.location;
        }
        return result.location;
      } else {
        message.error("PDF generation failed. No location returned.");
        return null;
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      message.error("Failed to generate PDF. Check console for details.");
      return null;
    } finally {
      setPdfLoading(false);
    }
  }

  // -------------------------------------------------------------
  // 8) Send Emails (using the same poNumber and PDF)
  // -------------------------------------------------------------
  async function handleSendEmails() {
    const isDev = process.env.REACT_APP_STAGE === "_dev";

    if (!htmlPreview) {
      message.warning("No HTML to send!");
      return;
    }

    // Save purchase order data before sending emails
    const savedPO = await savePurchaseOrder();
    if (!savedPO) return;

    let currentPdfUrl = pdfUrl;
    if (!currentPdfUrl) {
      // If we haven't generated the PDF yet, generate now
      currentPdfUrl = await handleGeneratePdf(false);
      if (!currentPdfUrl) {
        message.error("PDF generation failed. Cannot send emails without PDF.");
        return;
      }
    }

    if (!selectedAgentId) {
      message.warning("No Freight Agent selected!");
      return;
    }

    const agent = freightAgents.find((fa) => fa.id === selectedAgentId);
    if (!agent) {
      message.error("Selected agent not found.");
      return;
    }

    try {
      const primaryContacts = (agent.contacts || []).filter((c) => c.primary);
      const additionalContacts = (agent.contacts || []).filter(
        (c) => !c.primary
      );
      const extraEmails = (additionalContacts || [])
        .filter((c) => agentExtraContacts.includes(c.email))
        .map((c) => c.email);

      const primaryContact = primaryContacts.find(
        (c) => !isMissingContactInfo(c)
      );
      const greetingName = primaryContact
        ? `${primaryContact.firstName}`
        : agent.companyName;

      const primaryEmails = primaryContacts.map((c) => c.email).filter(Boolean);
      const allEmails = Array.from(new Set([...primaryEmails, ...extraEmails]));
      if (!allEmails.length) {
        message.warning(
          `No valid contacts selected for ${agent.companyName}. Skipping.`
        );
        return;
      }

      const pickupDateStr = pickupDate
        ? pickupDate.format("dddd MMMM DD, YYYY")
        : "TBD";
      const grossWeight = onTruckItems.reduce(
        (sum, item) => sum + (item.weight || 0),
        0
      );

      const devEmailsSection = isDev
        ? `<p><strong>Sending to emails:</strong> ${allEmails.join(", ")}</p>`
        : "";

      // Email body with the PO number included
      const emailBody = `
        <p>Hello ${greetingName},</p>
        <p>Please see attached Purchase Order <strong>${poNumber}</strong> regarding a shipment.</p>
        <p><strong>Pickup Date:</strong> ${pickupDateStr}</p>
        <p><strong>Pickup Address:</strong> ${
          shipment?.origin?.address || "N/A"
        }</p>
        <p><strong>Drop-off Address:</strong> ${
          shipment?.destination?.address || "N/A"
        }</p>
        <p><strong>Gross Weight:</strong> ${grossWeight} lbs</p>
        <p><strong>Total:</strong> ${formatMoney(amount) || "N/A"}</p>
        ${devEmailsSection}
        <p>Thanks,</p>
        <p>${userName}</p>
      `;

      const subject = `BMarko Structures Purchase Order ${poNumber} - [${project.projectName}] [${shipment.shipmentNumber}] ${today}`;

      // Actually send the email
      const resp = await sendEmail({
        subject,
        htmlBody: emailBody,
        emailAddresses: allEmails,
        sender: userEmail,
        attachmentUrls: [currentPdfUrl],
        manager: "christina.parker@bmarko.com",
      });

      if (!resp.success) {
        throw new Error(`Failed to send email to agent: ${agent.companyName}`);
      }

      message.success("Purchase Order Email sent successfully!");

      // Save the PDF URL to purchaseOrder files if desired
      const existingPO = shipment.purchaseOrder
        ? { ...shipment.purchaseOrder }
        : {};
      const existingFiles = existingPO.files || [];
      existingPO.files = [...existingFiles, currentPdfUrl];
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { purchaseOrder: existingPO },
        shipment.id
      );
    } catch (error) {
      console.error("Error sending Purchase Order email:", error);
      message.error("Email sending failed. Check console for details.");
    }
  }

  // -------------------------------------------------------------
  // 9) Quick check if we can send emails
  // -------------------------------------------------------------
  const canSendEmails = useMemo(() => {
    if (!selectedAgentId) return false;
    const agent = freightAgents.find((fa) => fa.id === selectedAgentId);
    if (!agent) return false;
    const validContacts = (agent.contacts || []).filter(
      (c) => !isMissingContactInfo(c)
    );
    return validContacts.length > 0;
  }, [selectedAgentId, freightAgents]);

  // -------------------------------------------------------------
  // 10) Build agent options
  // -------------------------------------------------------------
  const filteredAndSortedAgents = useMemo(() => {
    return freightAgents
      .filter((fa) => !fa.disabled)
      .sort((a, b) => (a.companyName || "").localeCompare(b.companyName || ""));
  }, [freightAgents]);

  const freightAgentOptions = useMemo(() => {
    return filteredAndSortedAgents.map((fa) => ({
      label: fa.companyName,
      value: fa.id,
    }));
  }, [filteredAndSortedAgents]);

  function handleSelectAgent(value) {
    setSelectedAgentId(value);
    setAgentExtraContacts([]);
  }

  // -------------------------------------------------------------
  // 11) Buttons (Generate PDF, Send Email)
  // -------------------------------------------------------------
  const rightCardExtra = (
    <>
      <Button
        icon={<FilePdfOutlined />}
        onClick={handleGeneratePdf}
        style={{ marginRight: 8 }}
        disabled={emptyShipment || !pickupDate || !amount || !selectedAgentId}
      >
        Generate PDF
      </Button>
      <Button
        icon={<MailOutlined />}
        type="primary"
        onClick={handleSendEmails}
        disabled={
          !canSendEmails ||
          emptyShipment ||
          !pickupDate ||
          !amount ||
          !selectedAgentId
        }
      >
        Send Email
      </Button>
    </>
  );

  // -------------------------------------------------------------
  // 12) Render
  // -------------------------------------------------------------
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={12}>
        <Card title="Build Purchase Order">
          <div>
            <HeaderText small text="PO Number" />
            <Input
              style={{ marginTop: 8, marginBottom: 16 }}
              value={poNumber || ""}
              disabled
            />
          </div>

          <div>
            <HeaderText small text="Pickup Date" />
            <DatePicker
              style={{ width: "100%", marginTop: 8 }}
              value={pickupDate}
              onChange={(date) => setPickupDate(date)}
            />
          </div>

          <div style={{ marginTop: 16 }}>
            <HeaderText small text="Amount" />
            <InputNumber
              type="number"
              style={{ width: "100%", marginTop: 8 }}
              prefix="$"
              value={amount}
              onChange={setAmount}
            />
          </div>

          <div style={{ marginBottom: 16, marginTop: 16 }}>
            <HeaderText small text="Freight Agent" />
            <Select
              style={{ width: "100%", marginTop: 8 }}
              placeholder="Select Freight Agent"
              value={selectedAgentId}
              onChange={handleSelectAgent}
              options={freightAgentOptions}
            />
          </div>

          {selectedAgentId &&
            (() => {
              const agent = filteredAndSortedAgents.find(
                (fa) => fa.id === selectedAgentId
              );
              if (!agent) return null;
              const primaryContacts = (agent.contacts || []).filter(
                (c) => c.primary
              );
              const additionalContacts = (agent.contacts || []).filter(
                (c) => !c.primary
              );

              return (
                <Card
                  size="small"
                  title={agent.companyName}
                  style={{ marginBottom: 16 }}
                >
                  {primaryContacts.length > 0 ? (
                    primaryContacts.map((pc) => {
                      if (isMissingContactInfo(pc)) {
                        return (
                          <div
                            key={pc.id}
                            style={{
                              marginBottom: 8,
                              backgroundColor: "#ffe6e6",
                              padding: 4,
                            }}
                          >
                            <Tag color="red" icon={<WarningOutlined />}>
                              No name/email — cannot send
                            </Tag>
                          </div>
                        );
                      }
                      return (
                        <div key={pc.id} style={{ marginBottom: 8 }}>
                          <strong>Primary:</strong>
                          <br />
                          {pc.firstName} {pc.lastName}
                          <br />
                          <small>{pc.email}</small>
                        </div>
                      );
                    })
                  ) : (
                    <i>No primary contact</i>
                  )}

                  {additionalContacts.length > 0 && (
                    <>
                      <div style={{ fontWeight: 500, marginTop: 8 }}>
                        Additional Contacts:
                      </div>
                      <Checkbox.Group
                        style={{ display: "block", marginTop: 4 }}
                        value={agentExtraContacts}
                        onChange={(vals) => setAgentExtraContacts(vals)}
                      >
                        {additionalContacts.map((ac) => {
                          if (isMissingContactInfo(ac)) {
                            return (
                              <div
                                key={ac.id}
                                style={{
                                  marginLeft: 16,
                                  marginTop: 4,
                                  backgroundColor: "#ffe6e6",
                                  padding: 4,
                                }}
                              >
                                <Tag color="red" icon={<WarningOutlined />}>
                                  No name/email — cannot send
                                </Tag>
                              </div>
                            );
                          }
                          return (
                            <div
                              key={ac.id}
                              style={{ marginLeft: 16, marginTop: 4 }}
                            >
                              <Checkbox value={ac.email}>
                                {ac.firstName} {ac.lastName}
                                <br />
                                <small>{ac.email}</small>
                              </Checkbox>
                            </div>
                          );
                        })}
                      </Checkbox.Group>
                    </>
                  )}
                </Card>
              );
            })()}

          <div>
            <HeaderText small text="Notes" />
            <Input.TextArea
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter any special instructions or message here"
              style={{ marginTop: 8 }}
            />
          </div>
        </Card>
      </Col>

      <Col xs={24} lg={12}>
        <Card
          title="Preview & Send"
          style={{ height: "100%" }}
          extra={rightCardExtra}
        >
          <Spin spinning={pdfLoading} tip="Generating PDF...">
            {htmlPreview ? (
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: 16,
                  minHeight: 300,
                }}
                dangerouslySetInnerHTML={{ __html: htmlPreview }}
              />
            ) : (
              <div style={{ color: "#999" }}>No preview yet.</div>
            )}
          </Spin>
        </Card>
      </Col>
    </Row>
  );
}

export default OutboundPurchaseOrder;
