import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { StopOutlined } from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { formatMoney } from "../../Formatters/helpers";
import { getVendorName } from "../../Formatters/getVendorName";
import dayjs from "dayjs";
import { formatBuyerName } from "../../Formatters/formatBuyerName";
import { getProjectName } from "../../Formatters/getProjectName";
import TableHeader from "../../Styled/TableHeader";

const ItemsTable = ({ onFilterChange }) => {
  const { data: purchaseOrders, loading: purchaseOrdersLoading } =
    usePurchaseOrders();
  const {
    data: vendors,
    loading: vendorsLoading,
    error: vendorsError,
  } = useVendors();
  const {
    data: projects,
    loading: projectsLoading,
    error: projectsError,
  } = useProjects();

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState({}); // Track table filters

  const [vendorFilters, setVendorFilters] = useState([]);
  const [buyerFilters, setBuyerFilters] = useState([]);
  const [projectFilters, setProjectFilters] = useState([]);
  const [lineStatusFilters, setLineStatusFilters] = useState([]);
  const [addressFilters, setAddressFilters] = useState([]); // Initialize Address Filters

  // Function to generate unique filters for each column
  const getUniqueFilters = (dataSource, dataIndex) => {
    return Array.from(
      new Set(
        dataSource.map((item) => {
          if (dataIndex === "vendor") {
            return item.vendorName; // Use vendorName instead of vendor ID
          } else if (dataIndex === "buyer") {
            return formatBuyerName(item[dataIndex]);
          } else if (dataIndex === "projectNumber") {
            return getProjectName(item[dataIndex], projects);
          } else {
            return item[dataIndex];
          }
        })
      )
    )
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b)) // Sorting alphabetically A-Z
      .map((value) => ({
        text: value,
        value: value,
      }));
  };

  // Function to update filter options based on the current data
  const updateFilterOptions = (filteredData) => {
    setVendorFilters(getUniqueFilters(filteredData, "vendor"));
    setBuyerFilters(getUniqueFilters(filteredData, "buyer"));
    setProjectFilters(getUniqueFilters(filteredData, "projectNumber"));
    setLineStatusFilters(getUniqueFilters(filteredData, "lineStatus"));
    setAddressFilters(getUniqueFilters(filteredData, "shipToAddress")); // Add Address Filters
  };

  // Function to apply both search and table filters
  const applyFilters = () => {
    let filteredData = originalData;

    // Apply table filters only if filteredInfo has keys
    if (Object.keys(filteredInfo).length > 0) {
      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] && filteredInfo[key].length > 0) {
          filteredData = filteredData.filter((record) => {
            const valueToCompare =
              key === "vendor"
                ? record.vendorName // Use vendorName instead of vendor ID
                : key === "buyer"
                ? formatBuyerName(record[key])
                : key === "projectNumber"
                ? getProjectName(record[key], projects)
                : key === "shipToAddress"
                ? record[key]
                : record[key];

            return valueToCompare && filteredInfo[key].includes(valueToCompare);
          });
        }
      });
    }

    // Apply search filter
    if (searchTerm.trim() !== "") {
      // Split search term into individual words
      const searchTerms = searchTerm.split(/\s+/).filter((term) => term);
      filteredData = filteredData.filter((record) =>
        ["itemNumber", "itemDescription", "poNumber", "vendorName"].some(
          (key) =>
            searchTerms.every((term) =>
              record[key]?.toString().toLowerCase().includes(term)
            )
        )
      );
    }

    console.log("Filtered Data:", filteredData); // Debugging

    setData(filteredData);
    updateFilterOptions(filteredData);
    onFilterChange(filteredData);
  };

  // useEffect to initialize data when purchaseOrders, vendors, and projects are loaded
  useEffect(() => {
    if (!purchaseOrdersLoading && !vendorsLoading && !projectsLoading) {
      const flattenedData = purchaseOrders.flatMap((order) => {
        // Validate critical dates using dayjs
        const orderedDate = dayjs(order.orderedDate);
        const requiredDate = dayjs(order.requiredDate);

        if (!orderedDate.isValid() || !requiredDate.isValid()) {
          console.warn(`Invalid dates for PO: ${order.poNumber}`);
          return [];
        }

        return order.lines
          .map((line) => {
            const receivedDate = dayjs(line.receivedDate);
            if (line.receivedDate && !receivedDate.isValid()) {
              console.warn(
                `Invalid receivedDate for line in PO: ${order.poNumber}`
              );
              return null;
            }

            const today = dayjs().startOf("day");
            const isLate =
              line.qtyReceived >= line.qtyOrdered &&
              line.receivedDate &&
              receivedDate.isAfter(requiredDate);
            const timeStatus =
              line.qtyReceived < line.qtyOrdered && today.isAfter(requiredDate)
                ? "Late"
                : isLate
                ? "Late"
                : "On Time";

            return {
              ...line,
              poNumber: order.poNumber,
              shipToAddress: order.shipToAddress,
              vendor: order.vendor,
              vendorName: getVendorName(order.vendor, vendors), // Added vendorName
              orderedDate: dayjs(order.orderedDate),
              requiredDate: dayjs(order.requiredDate),
              buyer: order.buyer,
              projectNumber: order.projectNumber,
              total: line.qtyOrdered * line.itemPrice,
              lineStatus:
                line.qtyReceived >= line.qtyOrdered
                  ? "Closed"
                  : line.qtyReceived > 0
                  ? "Partial"
                  : "Open",
              timeStatus,
            };
          })
          .filter((line) => line !== null); // Exclude lines with invalid receivedDate
      });

      console.log("Flattened Data:", flattenedData); // Debugging

      setOriginalData(flattenedData);
      setLoading(false);
      updateFilterOptions(flattenedData);
      onFilterChange(flattenedData);
    }
  }, [
    purchaseOrders,
    purchaseOrdersLoading,
    vendorsLoading,
    projectsLoading,
    vendors,
    projects,
    onFilterChange,
  ]);

  // useEffect to apply filters and search whenever searchTerm, filteredInfo, or originalData changes
  useEffect(() => {
    console.log(
      "Applying filters with searchTerm:",
      searchTerm,
      "and filteredInfo:",
      filteredInfo
    ); // Debugging
    applyFilters();
  }, [searchTerm, filteredInfo, originalData]);

  // Search handler only sets the search term
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
  };

  // Filter change handler only sets the filteredInfo
  const handleFilterChange = (pagination, filters, sorter) => {
    console.log("Filter Change:", filters); // Debugging
    setFilteredInfo(filters); // Track filters in state
  };

  // Function to remove all filters and reset data
  const removeFilters = () => {
    setFilteredInfo({}); // Clear the filter state
    setSearchTerm(""); // Clear the search term
    setData(originalData); // Reset data to originalData
    onFilterChange(originalData);
  };

  // Column definitions
  const columns = [
    {
      title: "Address",
      dataIndex: "shipToAddress",
      key: "shipToAddress",
      sorter: (a, b) => a.shipToAddress.localeCompare(b.shipToAddress),
      filteredValue: filteredInfo.shipToAddress || null,
      filters: addressFilters, // Add filters for Address
      filterSearch: true,
      onFilter: (value, record) =>
        record.shipToAddress
          ? record.shipToAddress.toLowerCase().includes(value.toLowerCase())
          : false,
      render: (text) => {
        if (!text) return null; // Handle null or undefined addresses

        // Split the address at the first comma
        const [firstPart, ...rest] = text.split(",");

        // Join the remaining parts back together (in case there are multiple commas)
        const secondPart = rest.join(",").trim();

        return (
          <div>
            <div>{firstPart.trim()}</div>
            {secondPart && <div>{secondPart}</div>}
          </div>
        );
      },
    },
    {
      title: "Item Number",
      dataIndex: "itemNumber",
      key: "itemNumber",
      className: "no-wrap",
      filterSearch: true,
      onFilter: (value, record) =>
        record.itemNumber
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      filterSearch: true,
      width: 325,
      onFilter: (value, record) =>
        record.itemDescription
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      className: "no-wrap",
      width: 60,
      filterSearch: true,
      onFilter: (value, record) =>
        record.poNumber?.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      defaultSortOrder: "descend", // Set default sort order to descending
    },

    {
      title: "Vendor",
      dataIndex: "vendorName", // Changed to vendorName
      key: "vendor",
      width: 150,
      filters: vendorFilters,
      filterSearch: true,
      filteredValue: filteredInfo.vendor || null, // Connect filteredInfo to Table
      onFilter: (value, record) => record.vendorName?.includes(value),
      render: (text) => {
        if (vendorsLoading) return "Loading...";
        if (vendorsError) return "Error loading vendors";
        return text || "N/A"; // Display vendorName directly
      },
    },
    {
      title: "Date Submitted",
      dataIndex: "orderedDate",
      key: "orderedDate",
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
      sorter: (a, b) => dayjs(a.orderedDate).diff(dayjs(b.orderedDate)),
      // defaultSortOrder: "ascend",
    },
    {
      title: "Required Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      filters: buyerFilters,
      filteredValue: filteredInfo.buyer || null, // Connect filteredInfo to Table
      onFilter: (value, record) =>
        formatBuyerName(record.buyer)?.includes(value),
      render: (text) => formatBuyerName(text) || "N/A",
      filterSearch: true,
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      filters: projectFilters,
      filteredValue: filteredInfo.projectNumber || null, // Connect filteredInfo to Table
      onFilter: (value, record) =>
        getProjectName(record.projectNumber, projects)?.includes(value),
      render: (text) => getProjectName(text, projects) || "N/A",
      filterSearch: true,
    },
    // {
    //   title: "Ordered",
    //   dataIndex: "qtyOrdered",
    //   key: "qtyOrdered",
    // },
    {
      title: "Received",
      dataIndex: "qtyReceived",
      key: "qtyReceived",
      render: (text, record) => {
        const { qtyReceived, qtyOrdered } = record;
        if (!qtyOrdered) {
          return <StopOutlined style={{ color: "#d3d3d3" }} />;
        }
        const receivedFraction = `${qtyReceived || 0} / ${qtyOrdered}`;
        return <span>{receivedFraction}</span>;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "itemPrice",
      key: "itemPrice",
      render: (text) => formatMoney(text),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => formatMoney(text),
    },
    {
      title: "Line Status",
      dataIndex: "lineStatus",
      key: "lineStatus",
      filters: lineStatusFilters,
      filteredValue: filteredInfo.lineStatus || null, // Connect filteredInfo to Table
      onFilter: (value, record) => record.lineStatus?.includes(value),
      render: (status) => {
        let color = "blue";
        if (status === "Closed") color = "green";
        if (status === "Partial") color = "orange";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Time Status",
      dataIndex: "timeStatus",
      key: "timeStatus",
      filters: [
        { text: "On Time", value: "On Time" },
        { text: "Late", value: "Late" },
      ],
      filteredValue: filteredInfo.timeStatus || null, // Connect filteredInfo to Table
      onFilter: (value, record) => record.timeStatus === value,
      render: (text, record) => {
        const color = record.timeStatus === "Late" ? "red" : "green";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {record.timeStatus}
          </Tag>
        );
      },
    },
    {
      title: "Date Received",
      dataIndex: "receivedDate",
      key: "receivedDate",
      sorter: (a, b) => {
        if (!a.receivedDate && !b.receivedDate) return 0;
        if (!a.receivedDate) return -1; // `a` is null, so it comes after `b`
        if (!b.receivedDate) return 1; // `b` is null, so it comes after `a`
        return dayjs(a.receivedDate).diff(dayjs(b.receivedDate));
      },
      render: (text) =>
        text ? (
          dayjs(text).format("MM/DD/YYYY")
        ) : (
          <StopOutlined style={{ color: "#d3d3d3" }} />
        ),
    },
  ];

  return (
    <>
      <TableHeader
        title="Items"
        totalResults={data.length}
        totalItems={originalData.length}
        searchValue={searchTerm}
        onSearchChange={handleSearch}
        removeFilters={removeFilters}
        showRemoveFilters={true} // Set to true or false depending on the use case
      />
      <Table
        className="small-table"
        dataSource={data}
        columns={columns}
        pagination={{
          pageSizeOptions: ["10", "25", "50", "100", "250"],
          showSizeChanger: true,
        }}
        rowKey={(record) =>
          `${record.poNumber || "no-po"}-${record.itemNumber || "no-item"}-${
            record.itemDescription || "no-desc"
          }`
        }
        loading={
          loading || purchaseOrdersLoading || vendorsLoading || projectsLoading
        }
        onChange={handleFilterChange}
        locale={{
          emptyText: searchTerm
            ? "No items match your search."
            : "No data available.",
        }}
      />
    </>
  );
};

export default ItemsTable;
