import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat"; // Import advancedFormat
import { formatCommas } from "../../Formatters/helpers";

// Extend dayjs with advancedFormat to support ordinal dates
dayjs.extend(advancedFormat);

/**
 * Generate HTML for an Outbound BOL (Bill of Lading).
 */
export default function generateHtmlOutboundBOL({
  origin,
  destination,
  items = [],
  customDescription,
  projectName,
  pickupDate,
  tripDistance,
  shipmentNumber,
}) {
  // Format today's date
  const today = dayjs().format("MM/DD/YYYY");
  const shipDate = pickupDate || null;

  // If origin ends with ", USA", remove it
  if (origin && origin.endsWith(", USA")) {
    origin = origin.replace(/,\s*USA$/, "");
  }
  // If destination ends with ", USA", remove it
  if (destination && destination.endsWith(", USA")) {
    destination = destination.replace(/,\s*USA$/, "");
  }

  // Helper to format dimension
  function formatDimension(ftVal, inVal) {
    const ftText = ftVal !== undefined ? `${ftVal}ft` : "?ft";
    const inValue = Number.isFinite(inVal) ? inVal : 0;
    const inText = inValue === 0 ? "" : ` ${inValue}in`;
    return ftText + inText;
  }

  // Freight Item Rows
  const itemRowsHtml = items
    .map((item) => {
      const {
        type,
        description = "N/A",
        weight,
        length,
        width,
        height,
        lengthIn,
        widthIn,
        heightIn,
      } = item;

      const weightString =
        weight !== undefined ? `${formatCommas(weight)} lbs` : "?";

      const dimensionString = [
        formatDimension(length, lengthIn),
        formatDimension(width, widthIn),
        formatDimension(height, heightIn),
      ].join(" x ");

      const typeString = type || "N/A";

      return `
        <tr>
          <td class="type-cell">${typeString}</td>
          <td class="description-cell">${description}</td>
          <td class="dimensions-cell">${dimensionString}</td>
          <td class="weight-cell">${weightString}</td>
        </tr>
      `;
    })
    .join("");

  // Calculate total (gross) weight
  const totalWeight = items.reduce((acc, item) => acc + (item.weight ?? 0), 0);
  const formattedGrossWeight = `${formatCommas(totalWeight)} lbs`;

  // Notes Table
  const notesTableHtml = customDescription
    ? `
      <table class="notes-table">
        <thead>
          <tr>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${customDescription}</td>
          </tr>
        </tbody>
      </table>
    `
    : "";

  return `
    <html>
      <head>
        <style>
          /* Calibri Font */
          @import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;500;600&display=swap');

          .pdf-preview {
            font-family: 'Calibri', sans-serif;
            margin: 0;
            padding: 0;
            position: relative;
          }
          .pdf-preview .rfq-body {
            font-size: 12px;
            padding: 20px;
          }
          .pdf-preview .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 16px;
          }
          .pdf-preview .divider {
            width: 100%;
            height: 2px;
            background-color: #ED8293;
            margin: 8px 0;
          }
          .pdf-preview .left-header {
            width: 50%;
          }
          .pdf-preview .right-header {
            text-align: right;
            width: 50%;
          }
          .pdf-preview .logo img {
            max-width: 280px;
            height: auto;
          }
          .pdf-preview .rfq-title {
            font-size: 22px;
            font-weight: 600;
          }
          .pdf-preview .rfq-sub-title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 8px;
          }
          .pdf-preview .details-table {
            text-align: right;
            border-collapse: collapse;
            width: 100%;
          }
          .pdf-preview .details-table td {
            font-size: 12px;
            padding: 2px 4px;
            box-sizing: border-box;
          }
          .pdf-preview .label-column {
            text-align: right;
            width: 100%;
            font-weight: 500;
            padding-right: 8px;
            box-sizing: border-box;
          }
          .pdf-preview .value-column {
            text-align: left;
            width: 70%;
            padding-left: 8px;
            box-sizing: border-box;
          }
          .pdf-preview .outer-table {
            width: 100%;
            border-collapse: collapse;
          }
          .pdf-preview .outer-table td {
            vertical-align: top;
          }
          .pdf-preview .info-table {
            width: 100%;
            border-collapse: collapse;
            font-size: 12px; 
          }
          .pdf-preview .info-table td.label {
            text-align: left;
            font-weight: 500;
            vertical-align: top;
            width: 100px;
          }
          .pdf-preview .info-table td.value {
            text-align: left;
            width: auto;
          }
          .pdf-preview .items-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
            table-layout: fixed;
          }
          .pdf-preview .items-table th,
          .pdf-preview .items-table td {
            border: 2px solid black;
            padding: 8px;
            font-size: 12px;
            word-wrap: break-word;
            box-sizing: border-box;
            text-align: center;
          }
          .pdf-preview .items-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-transform: uppercase;
          }
          .pdf-preview .items-table .type-cell {
            width: 20%;
            text-align: center;
          }
          .pdf-preview .items-table .description-cell {
            width: 20%;
            text-align: center;
          }
          .pdf-preview .items-table .dimensions-cell {
            width: 40%;
          }
          .pdf-preview .items-table .weight-cell {
            width: 20%;
            min-width: 20%;
            max-width: 20%;
            word-wrap: break-word;
            text-align: center;
          }
          /* Gross Weight Table */
          .pdf-preview .gross-weight-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
            table-layout: fixed;
          }
          .pdf-preview .gross-weight-table th,
          .pdf-preview .gross-weight-table td {
            border: 2px solid black;
            padding: 8px;
            font-size: 12px;
            word-wrap: break-word;
            box-sizing: border-box;
            text-align: center;
          }
          .pdf-preview .gross-weight-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-transform: uppercase;
          }
          /* Notes Table */
          .pdf-preview .notes-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
            table-layout: fixed;
          }
          .pdf-preview .notes-table th,
          .pdf-preview .notes-table td {
            border: 2px solid black;
            padding: 8px;
            font-size: 12px;
            word-wrap: break-word;
            box-sizing: border-box;
            text-align: left;
          }
          .pdf-preview .notes-table th {
            font-weight: 600;
            text-transform: uppercase;
          }
          /* Signature Table */
          .pdf-preview .signature-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
            table-layout: fixed;
          }
          .pdf-preview .signature-table th,
          .pdf-preview .signature-table td {
            border: 2px solid black;
            padding: 8px;
            font-size: 12px;
            word-wrap: break-word;
            box-sizing: border-box;
            vertical-align: top;
          }
          .pdf-preview .signature-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
          }
          .pdf-preview .signature-table label {
            display: inline-block;
            margin-bottom: 4px;
            font-weight: 600;
          }
        </style>
      </head>
      <body>
        <div class="pdf-preview">
          <div class="rfq-body">

            <!-- Header Section -->
            <div class="header">
              <div class="left-header">
                <div class="logo">
                  <img 
                    src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png" 
                    alt="BMarko Logo" 
                  />
                </div>
              </div>
              <div class="right-header">
                <div style="margin-bottom:0;" class="rfq-title">Freight</div>
                <div class="rfq-title" style="margin-bottom:8px">Bill of Lading</div>
                <div class="details-table">
                  <tr>
                    <td class="label-column">BOL #:</td>
                    <td class="value-column">${today}</td>
                  </tr>
                                    <tr>
                    <td class="label-column">Project:</td>
                    <td class="value-column">${projectName}</td>
                  </tr>
                </div>
              </div>
            </div>

            <div class="divider"></div>

            <!-- Project & Shipping Info -->
            <table class="outer-table">
              <tr>
                <td>
                  <table class="info-table">
                    <tr>
                      <td class="label">Shipment ID:</td>
                      <td class="value">${shipmentNumber || "N/A"}</td>
                    </tr>
                    <tr>
                      <td class="label">Project:</td>
                      <td class="value">${
                        projectName || "Untitled Project"
                      }</td>
                    </tr>

                    <tr>
                      <td class="label">Pick Up Address:</td>
                      <td class="value">${origin || "N/A"}</td>
                    </tr>
                    <tr>
                      <td class="label">Drop Off Address:</td>
                      <td class="value">${destination || "N/A"}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div class="divider"></div>

            <!-- Freight Items Table -->
            <table class="items-table">
              <thead>
                <tr>
                  <th class="type-cell">Type</th>
                  <th class="description-cell">Description</th>
                  <th class="dimensions-cell">Dimensions</th>
                  <th class="weight-cell">Weight</th>
                </tr>
              </thead>
              <tbody>
                ${
                  itemRowsHtml ||
                  `<tr><td colspan="4" style="text-align:center;">No items</td></tr>`
                }
              </tbody>
            </table>

            <!-- Gross Weight Table and Notes Table -->
            <div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start;">
              <div style="flex: .75; margin-right: 16px;">
                ${notesTableHtml}
              </div>
              <div style="width: 20%;">
                <table class="gross-weight-table">
                  <thead>
                    <tr>
                      <th>Gross Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>${formattedGrossWeight}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Signature Section -->
            <table class="signature-table" role="group" aria-labelledby="signatureSectionHeader">
              <thead>
                <tr>
                  <th colspan="2" id="signatureSectionHeader">Signatures</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label for="carrierNameCompany">Carrier Name &amp; Company</label>
                    <div 
                      id="carrierNameCompany" 
                      role="textbox" 
                      aria-label="Carrier Name and Company" 
                      style="border-bottom:1px solid black; min-height:20px;"></div>
                  </td>
                  <td>
                    <label for="carrierPhone">Carrier Phone</label>
                    <div 
                      id="carrierPhone" 
                      role="textbox" 
                      aria-label="Carrier Phone" 
                      style="border-bottom:1px solid black; min-height:20px;"></div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <label for="carrierSignature">Carrier Signature</label>
                    <div 
                      id="carrierSignature" 
                      role="textbox" 
                      aria-label="Carrier Signature" 
                      style="border-bottom:1px solid black; min-height:20px;"></div>
                  </td>

                </tr>
                <tr>
                  <td>
                    <label for="shipperSignature">Shipper Signature (BMarko)</label>
                    <div 
                      id="shipperSignature" 
                      role="textbox" 
                      aria-label="Shipper Signature" 
                      style="border-bottom:1px solid black; min-height:20px;"></div>
                  </td>
                  <td>
                    <label for="shipDate">Ship Date</label>
                    <div 
                      id="shipDate" 
                      role="textbox" 
                      aria-label="Ship Date" 
                      style="border-bottom:1px solid black; min-height:20px;"></div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </body>
    </html>
  `;
}
