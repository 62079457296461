// DimensionsCell.jsx
import React from "react";
import { StopOutlined } from "@ant-design/icons";

/**
 * Helper to format a single dimension (e.g. length or width or height).
 * @param {number|null} ft   - Feet value (can be 0, or null if missing).
 * @param {number|null} inch - Inches value (can be 0, or null if missing).
 * @returns {string|JSX.Element} - Either "22' 6"", "22'", or the <StopOutlined> icon if feet is missing.
 */
function formatDimension(ft, inch) {
  // If feet is truly missing => show the icon
  if (ft == null) {
    return <StopOutlined style={{ color: "red" }} />;
  }

  // We have a valid feet value (can be zero).
  // Always show feet as, e.g., "22'"
  const footString = `${ft}'`;

  // If inches is missing or 0 => just show the feet part
  if (inch == null || inch === 0) {
    return footString;
  }

  // Otherwise, show "ft' inch\""
  return `${footString} ${inch}"`;
}

/**
 * Component that displays "L x W x H" using the above formatDimension logic.
 * Usage: <DimensionsCell length={...} lengthIn={...} width={...} widthIn={...} height={...} heightIn={...} />
 */
function DimensionsCell({
  length,
  lengthIn,
  width,
  widthIn,
  height,
  heightIn,
}) {
  const lDim = formatDimension(length, lengthIn);
  const wDim = formatDimension(width, widthIn);
  const hDim = formatDimension(height, heightIn);

  return (
    <div>
      {lDim} x {wDim} x {hDim}
    </div>
  );
}

export default DimensionsCell;
