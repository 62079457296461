import { addItemsToTable } from "../addAria";
import { TABLES } from "../../constants";
import dayjs from "dayjs";

// Reusable function to create and add a reminder
export const createReminder = async ({
  body,
  typeId,
  type,
  notes,
date,
userName,
  module,
}) => {
  const newReminder = {
    date: dayjs(date).format("MM/DD/YYYY"), // Ensures the date is in MM/DD/YYYY format
    notes: notes,
    active: true, // Active is always true
    module: module, 
    type: type, // ex: purchaseOrder from purchasing
    typeId: typeId, // ex: purchaseOrderId from purchasing
    createdBy: userName,
    body: [
      ...body, // Spread the body passed in the argument
      { key: "Created On", value: dayjs().format("MM/DD/YYYY") },
      { key: "Created By", value: userName || "N/A" },
    ],
  };

  await addItemsToTable(TABLES.REMINDERS, newReminder);
};
