import React, { useRef, useEffect, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { Input } from "antd";

const libraries = ["places"];

const PlaceObjectAutocomplete = ({ form, onChange, value }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);

  // State to manage the displayed input value
  const [inputValue, setInputValue] = useState(value || ""); // Initialize with `value`

  // Synchronize the input value with the `value` prop (e.g., prefilled during editing)
  useEffect(() => {
    if (value && typeof value === "string") {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current.input,
        { types: ["geocode"] }
      );
      autocompleteRef.current.addListener("place_changed", onPlaceChanged);
    }

    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(
          autocompleteRef.current
        );
      }
    };
  }, [isLoaded]);

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();

    if (!place || !place.formatted_address) {
      console.error("No valid place selected");
      return;
    }

    const formattedAddress = place.formatted_address;

    // Update state with the formatted address for display
    setInputValue(formattedAddress);

    // Notify parent with the full place object
    if (onChange) {
      onChange(place);
    }

    // Update form fields if necessary
    if (form) {
      form.setFieldsValue({
        googleAddress: place,
        address: formattedAddress,
      });
    }
  };

  const handleChange = (e) => {
    const newAddress = e.target.value;

    // Update local state with typed value
    setInputValue(newAddress);

    // Clear selection on manual input change
    if (onChange) {
      onChange(null); // Notify parent that the place is now unset
    }

    if (form) {
      form.setFieldsValue({ address: newAddress });
    }
  };

  return (
    <Input
      ref={inputRef}
      value={inputValue} // Controlled input value
      onChange={handleChange}
      placeholder="Enter address"
    />
  );
};

export default PlaceObjectAutocomplete;
