// quoteHelper.js
import { v4 as uuidv4 } from "uuid";

/**
 * Processes the estimate data to collect descriptions for modules, assemblies, ariaItems, and customItems.
 *
 * @param {Object} estimate - The estimate data containing modules, assemblies, ariaItems, and customItems.
 * @returns {Array} An array of all initial line items.
 */
export function quoteHelper(estimate) {
  const initialLineItems = [];

  /**
   * Helper function to prepend the quantity in parentheses.
   *
   * @param {string} description - The original description of the item.
   * @param {number} quantity - The quantity of the item.
   * @returns {string} - The modified description with quantity prefix.
   */
  const prependQuantity = (description, quantity) =>
    `(${quantity}) ${description}`;

  // Process Modules
  if (estimate.modules) {
    estimate.modules.forEach((module, moduleIndex) => {
      const {
        id,
        description,
        selectedOptions,
        container,
        rainProtection,
        permitted,
        quantity = 1,
      } = module;

      let moduleItemId = id || uuidv4();
      if (!id) {
        console.log(
          `Module at index ${moduleIndex} is missing an id. Generated id: ${moduleItemId}`
        );
      }

      initialLineItems.push({
        id: moduleItemId,
        description: prependQuantity(
          description || "Module",
          quantity
        ),
        source: "Module",
        visible: true,
        isInitial: true,
        itemType: "Grouped",
        order: initialLineItems.length,
      });

      // Process Container
      if (container) {
        const containerId = container.id || uuidv4();
        if (!container.id) {
          console.log(
            `Container in module ${moduleItemId} is missing an id. Generated id: ${containerId}`
          );
        }

        initialLineItems.push({
          id: `${moduleItemId}-container`,
          description: "All Cutting & Welding per Plans",
          source: "Module Extra",
          visible: true,
          isInitial: true,
          itemType: "Grouped",
          order: initialLineItems.length,
        });
      }

      // Process Rain Protection
      if (rainProtection) {
        initialLineItems.push({
          id: `${moduleItemId}-rainProtection`,
          description:
            "8 mil Plastic Heat Shrink Wrap for Protection During Shipping and Quality Assurance",
          source: "Module Extra",
          visible: true,
          isInitial: true,
          itemType: "Grouped",
          order: initialLineItems.length,
        });
      }

      // Process Permitting
      if (permitted) {
        initialLineItems.push({
          id: `${moduleItemId}-permitted`,
          description: "Permitting",
          source: "Module Extra",
          visible: true,
          isInitial: true,
          itemType: "Grouped",
          order: initialLineItems.length,
        });
      }

      // Add selected options as separate items without quantity
      if (selectedOptions) {
        Object.values(selectedOptions).forEach((option, optionIndex) => {
          let optionId = option.id || uuidv4();
          if (!option.id) {
            console.log(
              `Option at index ${optionIndex} in module ${moduleItemId} is missing an id. Generated id: ${optionId}`
            );
          }

          const optionDescription = `${option.category}: ${option.moduleOptionDescription}`;
          initialLineItems.push({
            id: `${moduleItemId}-${optionId}`,
            description: optionDescription,
            source: "Module Option",
            visible: true,
            isInitial: true,
            itemType: "Grouped",
            order: initialLineItems.length,
          });
        });
      }
    });
  }

  // Process Assemblies
  if (estimate.assemblies) {
    estimate.assemblies.forEach((assembly, assemblyIndex) => {
      const { id, assemblyName, quantity = 1, description } = assembly;
      let assemblyId = id || uuidv4();
      if (!id) {
        console.log(
          `Assembly at index ${assemblyIndex} is missing an id. Generated id: ${assemblyId}`
        );
      }
      initialLineItems.push({
        id: assemblyId,
        description: prependQuantity(description ||assemblyName || "Assembly", quantity),
        source: "Assembly",
        visible: true,
        isInitial: true,
        itemType: "Grouped",
        order: initialLineItems.length,
      });

      // Optionally, process assembly items if needed
    });
  }

  // Process Aria Items
  if (estimate.ariaItems) {
    estimate.ariaItems.forEach((ariaItem, ariaIndex) => {
      const { id, itemDescription, quantity = 1 } = ariaItem;
      let ariaItemId = id || uuidv4();
      if (!id) {
        ariaItemId = uuidv4();
        console.log(
          `Aria Item at index ${ariaIndex} is missing an id. Generated id: ${ariaItemId}`
        );
      }
      initialLineItems.push({
        id: ariaItemId,
        description: prependQuantity(itemDescription || "Aria Item", quantity),
        source: "Aria Item",
        visible: true,
        isInitial: true,
        itemType: "Grouped",
        order: initialLineItems.length,
      });
    });
  }

  // Process Custom Items
  if (estimate.customItems) {
    estimate.customItems.forEach((customItem, customIndex) => {
      const { id, itemDescription, quantity = 1 } = customItem;
      let customItemId = id || uuidv4();
      if (!id) {
        customItemId = uuidv4();
        console.log(
          `Custom Item at index ${customIndex} is missing an id. Generated id: ${customItemId}`
        );
      }
      initialLineItems.push({
        id: customItemId,
        description: prependQuantity(
          itemDescription || "Custom Item",
          quantity
        ),
        source: "Custom Item",
        visible: true,
        isInitial: true,
        itemType: "Grouped",
        order: initialLineItems.length,
      });
    });
  }

  return initialLineItems;
}
