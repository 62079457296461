// src/Pages/projectmanagement/Production.jsx

import React, { useState, useMemo, useEffect } from "react";
import {
  Modal,
  Form,
  Select,
  Button,
  Typography,
  Row,
  Col,
  Spin,
  Input,
  message,
} from "antd";
import {
  WarningOutlined,
  SortAscendingOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { useProjects } from "../../Contexts/useSpecificData"; // Custom hook to fetch projects
import { updateById } from "../../Functions/updateById"; // Function to update project by ID
import {
  TABLES,
  PRODUCTION_STATUS_FLOW,
  PRODUCT_TYPES,
  COLORS,
  PRECON_STATUS_FLOW,
} from "../../constants"; // Constants including PRODUCTION_STATUS_FLOW and PRECON_STATUS_FLOW
import HeaderText from "../../Styled/HeaderText"; // Custom HeaderText component
import ProductionCard from "../../Components/Production/ProductionCard";
import { useUser } from "@clerk/clerk-react"; // User hook from Clerk
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faClipboardList,
  faHandHoldingHand,
  faTag,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import PriceTag from "../../Styled/PriceTag";
import { formatMoney } from "../../Formatters/helpers";

const { Text } = Typography;
const { Option } = Select;

// A helper to get an icon for a given production status
const statusIconMapping = {
  Fabrication: faPencil,
  "Framing & Roughin": faClipboardList,
  Painting: faHandHoldingHand,
  Outfitting: faTag,
  Completed: faCheck,
};
const getIconForStatus = (status) =>
  statusIconMapping[status] || faClipboardList;

function Production({ viewOnly = false }) {
  const { data: projects, refresh: refreshProjects } = useProjects();

  // Client Delay Modal for Projects
  const [
    isProjectClientDelayModalVisible,
    setIsProjectClientDelayModalVisible,
  ] = useState(false);
  const [projectClientDelayForm] = Form.useForm();
  const [projectClientDelayLoading, setProjectClientDelayLoading] =
    useState(false);

  // Infinite Scroll
  const [displayedGroups, setDisplayedGroups] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_LOAD = 5; // number of project groups per "scroll"

  // Ordering & Filtering
  const [order, setOrder] = useState("alphabetical"); // 'alphabetical' | 'most' | 'least'
  const [filterStatus, setFilterStatus] = useState("All Stages");
  const [filterProductType, setFilterProductType] =
    useState("All Product Types");
  const [searchQuery, setSearchQuery] = useState("");

  // Clerk user
  const { user, isLoaded: isUserLoaded } = useUser();
  const userEmail =
    isUserLoaded && user && user.primaryEmailAddress
      ? user.primaryEmailAddress.emailAddress
      : "Unknown";

  // Combine precon & production statuses
  const allStatuses = useMemo(
    () => [...PRECON_STATUS_FLOW, ...PRODUCTION_STATUS_FLOW],
    []
  );

  // 1) Unique productTypes (plus "All")
  const productTypes = useMemo(() => {
    if (!projects || projects.length === 0) {
      return ["All Product Types", ...PRODUCT_TYPES];
    }
    const allTypes = projects
      .flatMap((proj) => (proj.modules || []).map((mod) => mod.productType))
      .filter(Boolean);
    const unique = Array.from(new Set(allTypes));
    return ["All Product Types", ...unique];
  }, [projects]);

  // 2) Filter eligible projects (only those with modules)
  const eligibleProjects = useMemo(() => {
    if (!projects) return [];
    return projects.filter((p) => p.modules && p.modules.length > 0);
  }, [projects]);

  // 3) Flatten + filter
  const flattenedModules = useMemo(() => {
    if (!projects) return [];
    let modules = projects.flatMap((project) =>
      (project.modules || []).map((module) => ({
        ...module,
        projectId: project.id,
        projectName: project.projectName,
        // fallback if no production status => use precon
        projectPreconstructionStatus:
          project.preconstruction?.status || "Drawing",
      }))
    );

    // Filter by status
    if (filterStatus !== "All Stages") {
      modules = modules.filter((m) => {
        if (filterStatus === "Client Delay") {
          return m.clientDelay === true;
        }
        const s = m.production?.status || m.projectPreconstructionStatus;
        if (filterStatus === "Completed") {
          return s === "Completed";
        }
        return s === filterStatus;
      });
    }

    // Filter by productType
    if (filterProductType !== "All Product Types") {
      modules = modules.filter((m) => m.productType === filterProductType);
    }

    // Search
    if (searchQuery.trim()) {
      const q = searchQuery.trim().toLowerCase();
      modules = modules.filter(
        (m) => m.projectName && m.projectName.toLowerCase().includes(q)
      );
    }

    return modules;
  }, [projects, filterStatus, filterProductType, searchQuery]);

  // 4) Sort the flattened modules
  const sortedModules = useMemo(() => {
    // 3 possible order: alphabetical, most, least
    if (!flattenedModules) return [];

    let modulesCopy = [...flattenedModules];
    switch (order) {
      case "alphabetical":
        // Sort by projectName ascending (A->Z)
        modulesCopy.sort((a, b) =>
          (a.projectName || "").localeCompare(b.projectName || "")
        );
        break;
      case "most":
        // Most progressed
        modulesCopy.sort((a, b) => {
          const aStatus =
            a.production?.status || a.projectPreconstructionStatus;
          const bStatus =
            b.production?.status || b.projectPreconstructionStatus;
          const aIdx = allStatuses.indexOf(aStatus);
          const bIdx = allStatuses.indexOf(bStatus);
          return bIdx - aIdx; // bigger index means further along
        });
        break;
      case "least":
        // Least progressed
        modulesCopy.sort((a, b) => {
          const aStatus =
            a.production?.status || a.projectPreconstructionStatus;
          const bStatus =
            b.production?.status || b.projectPreconstructionStatus;
          const aIdx = allStatuses.indexOf(aStatus);
          const bIdx = allStatuses.indexOf(bStatus);
          return aIdx - bIdx; // smaller index means earlier stage
        });
        break;
      default:
        break;
    }

    return modulesCopy;
  }, [flattenedModules, order, allStatuses]);

  // 5) Group by projectName
  const groupedModules = useMemo(() => {
    const groups = {};
    sortedModules.forEach((mod) => {
      const name = mod.projectName || "Unnamed Project";
      if (!groups[name]) groups[name] = [];
      groups[name].push(mod);
    });
    return groups;
  }, [sortedModules]);

  // 6) groupNames array
  const groupNames = useMemo(
    () => Object.keys(groupedModules),
    [groupedModules]
  );

  // 7) Stats
  const statistics = useMemo(() => {
    const totalModules = sortedModules.length;
    const totalAmount = sortedModules.reduce(
      (acc, m) => acc + (m.amount || 0),
      0
    );
    const distinctProjectIds = new Set(sortedModules.map((m) => m.projectId));
    const totalProjects = distinctProjectIds.size;
    return { totalModules, totalAmount, totalProjects };
  }, [sortedModules]);

  // 8) projectId -> project
  const projectIdMap = useMemo(() => {
    if (!projects) return {};
    const map = {};
    projects.forEach((p) => {
      map[p.id] = p;
    });
    return map;
  }, [projects]);

  // 9) Initialize infinite scroll
  useEffect(() => {
    setDisplayedGroups(groupNames.slice(0, ITEMS_PER_LOAD));
    setHasMore(groupNames.length > ITEMS_PER_LOAD);
  }, [groupNames]);

  const fetchMoreGroups = () => {
    const currentLength = displayedGroups.length;
    const nextGroups = groupNames.slice(
      currentLength,
      currentLength + ITEMS_PER_LOAD
    );
    setDisplayedGroups((prev) => [...prev, ...nextGroups]);
    if (displayedGroups.length + nextGroups.length >= groupNames.length) {
      setHasMore(false);
    }
  };

  // 10) Handle Project-Level Client Delay
  const handleProjectClientDelaySubmit = async (vals) => {
    const selectedProjectIds = vals.projects || [];
    if (selectedProjectIds.length === 0) {
      message.warning("Please select at least one project to mark as delayed.");
      return;
    }
    setProjectClientDelayLoading(true);
    try {
      const promises = selectedProjectIds.map((projectId) => {
        const project = projects.find((p) => p.id === projectId);
        if (!project || !project.modules) return Promise.resolve();

        // Update each module's production.history
        const updatedModules = project.modules.map((mod) => ({
          ...mod,
          production: {
            ...mod.production,
            history: [
              ...(mod.production?.history || []),
              {
                action: "Client Delay",
                date: new Date().toISOString(),
                submittedBy: userEmail,
              },
            ],
          },
        }));

        const updatedProject = {
          ...project,
          clientDelay: true, // Optionally, you can set this if needed
          modules: updatedModules,
        };

        return updateById(TABLES.PROJECTS, updatedProject, project.id);
      });

      await Promise.all(promises);
      message.success("Selected projects have been marked as delayed.");
      setIsProjectClientDelayModalVisible(false);
      projectClientDelayForm.resetFields();
      refreshProjects();
    } catch (err) {
      console.error(err);
      message.error("Failed to mark selected projects as delayed.");
    } finally {
      setProjectClientDelayLoading(false);
    }
  };

  // 11) Remove Project-Level Client Delay
  const removeProjectClientDelay = async (project) => {
    Modal.confirm({
      title: "Remove Client Delay",
      icon: <WarningOutlined />,
      content:
        "Are you sure you want to remove the client delay from this project?",
      onOk: async () => {
        try {
          if (!project.modules) throw new Error("Project has no modules.");

          // Update each module's production.history
          const updatedModules = project.modules.map((mod) => ({
            ...mod,
            production: {
              ...mod.production,
              history: [
                ...(mod.production?.history || []),
                {
                  action: "Client Delay Removed",
                  date: new Date().toISOString(),
                  submittedBy: userEmail,
                },
              ],
            },
          }));

          const updatedProject = {
            ...project,
            clientDelay: false, // Optionally, you can unset this if needed
            modules: updatedModules,
          };

          await updateById(TABLES.PROJECTS, updatedProject, project.id);
          message.success("Client delay removed successfully.");
          refreshProjects();
        } catch (err) {
          console.error(err);
          message.error("Failed to remove client delay.");
        }
      },
    });
  };

  return (
    <div style={{ width: "100%" }}>
      {/* 1) Statistics Row */}
      <Row
        gutter={[0, 16]}
        style={{
          borderRadius: 8,
          marginBottom: 8,
        }}
      >
        {/* Production Title */}
        <Col span={6}>
          <HeaderText text="Production" />
        </Col>

        {/* Side-by-side stats */}
        <Col span={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <span style={{ marginRight: 8, opacity: 0.7 }}>
              Total Projects:
            </span>
            <span style={{ fontSize: 18 }}>{statistics.totalProjects}</span>
          </div>
        </Col>

        <Col span={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <span style={{ marginRight: 8, opacity: 0.7 }}>Total Modules:</span>
            <span style={{ fontSize: 18 }}>{statistics.totalModules}</span>
          </div>
        </Col>

        <Col span={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <span style={{ marginRight: 8, opacity: 0.7 }}>Total Amount:</span>
            <span
              style={{
                color: statistics.totalAmount > 0 ? "#3f8600" : "black",
                fontSize: 18,
              }}
            >
              {formatMoney(statistics.totalAmount)}
            </span>
          </div>
        </Col>

        {/* Client Delay button for Projects */}
        <Col span={6} style={{ textAlign: "right" }}>
          {!viewOnly && (
            <Button
              danger
              icon={<WarningOutlined />}
              onClick={() => setIsProjectClientDelayModalVisible(true)}
              disabled={eligibleProjects.length === 0}
            >
              Client Delay
            </Button>
          )}
        </Col>
      </Row>

      {/* 2) Ordering and Filtering Controls */}
      <div
        style={{
          position: "sticky",
          top: 16,
          zIndex: 6,
          padding: "8px 0",
          borderBottom: "1px solid #f0f0f0",
          backgroundColor: "white",
          marginBottom: 8,
          borderRadius: 8,
        }}
      >
        <Row gutter={16} style={{ paddingRight: 16, paddingLeft: 16 }}>
          {/* Search Input */}
          <Col span={6}>
            <Input
              placeholder="Search by Project Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              allowClear
              prefix={<SearchOutlined />}
            />
          </Col>

          {/* Ordering */}
          <Col span={6}>
            <Select
              value={order}
              onChange={(value) => setOrder(value)}
              style={{ width: "100%" }}
              placeholder="Order Modules"
              suffixIcon={<SortAscendingOutlined />}
            >
              <Option value="alphabetical">Alphabetical (A–Z)</Option>
              <Option value="most">Most Progressed</Option>
              <Option value="least">Least Progressed</Option>
            </Select>
          </Col>

          {/* Filter by Stage */}
          <Col span={6}>
            <Select
              value={filterStatus}
              onChange={(value) => setFilterStatus(value)}
              style={{ width: "100%" }}
              placeholder="Filter by Stage"
              suffixIcon={<FilterOutlined />}
              showSearch
              optionFilterProp="children"
              dropdownStyle={{ maxHeight: 240, overflowY: "auto" }}
              filterOption={(input, option) =>
                (option?.children?.props?.children || "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Option value="All Stages">All Stages</Option>
              <Option value="Client Delay">
                <WarningOutlined style={{ marginRight: 8, color: "red" }} />
                Client Delay
              </Option>
              {PRODUCTION_STATUS_FLOW.map((status) => (
                <Option key={status} value={status}>
                  <FontAwesomeIcon
                    icon={getIconForStatus(status)}
                    style={{ marginRight: 8, color: COLORS.PRIMARY }}
                  />
                  {status}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Filter by Product Type */}
          <Col span={6}>
            <Select
              value={filterProductType}
              onChange={(value) => setFilterProductType(value)}
              style={{ width: "100%" }}
              placeholder="Filter by Product Type"
              suffixIcon={<FilterOutlined />}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option.children || "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {productTypes.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>

      {/* 3) Infinite Scroll for grouped Projects */}
      <InfiniteScroll
        dataLength={displayedGroups.length}
        next={fetchMoreGroups}
        hasMore={hasMore}
        style={{ overflow: "visible" }}
        loader={
          <div style={{ textAlign: "center", padding: "20px 0" }}>
            <Spin tip="Loading more projects..." />
          </div>
        }
      >
        {displayedGroups.map((projectName) => {
          const modulesInGroup = groupedModules[projectName];
          if (!modulesInGroup || modulesInGroup.length === 0) return null;

          // The actual project
          const firstModule = modulesInGroup[0];
          const proj = projectIdMap[firstModule.projectId];
          if (!proj) return null;

          // Determine if the project is delayed
          const isProjectDelayed = proj.clientDelay === true;

          // Optional: Change background color based on delay status
          const rowBg = isProjectDelayed
            ? "rgba(255, 0, 0, 0.05)" // Light red for delayed
            : "white";

          // Summation
          const totalModuleCount = proj.modules?.length || 0;
          const totalAmount =
            proj.modules?.reduce((acc, mod) => acc + (mod.amount || 0), 0) || 0;

          // Sort the modules in numeric order by module.number => "01", "02", "10" => 1,2,10
          const sortedModulesInProject = [...modulesInGroup].sort((a, b) => {
            const aNum = parseInt(a.number, 10);
            const bNum = parseInt(b.number, 10);
            return aNum - bNum;
          });

          return (
            <div key={projectName}>
              <Row
                className="box-shadow"
                style={{
                  borderRadius: 8,
                  backgroundColor: rowBg,
                  marginBottom: 12,
                }}
              >
                {/* "Header" row for the project */}
                {/* <div
                  style={{
                    padding: "8px 16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <HeaderText large text={projectName} />
                  </div>
                  <div>
                    <strong>Modules:</strong> {totalModuleCount}
                    &nbsp;|&nbsp;
                    <strong>Amount:</strong>
                    <PriceTag amount={totalAmount} style={{ marginLeft: 4 }} />
                  </div>
                </div> */}

                {/* Then each ProductionCard */}
                {sortedModulesInProject.map((module) => (
                  <Col key={module.id} span={24}>
                    <ProductionCard
                      project={proj}
                      module={module}
                      viewOnly={viewOnly}
                      onClientDelayRemove={() => removeProjectClientDelay(proj)}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          );
        })}
      </InfiniteScroll>

      {/* 4) Client Delay Modal for Projects */}
      <Modal
        visible={isProjectClientDelayModalVisible}
        title="Mark Projects as Delayed"
        onCancel={() => setIsProjectClientDelayModalVisible(false)}
        onOk={() => projectClientDelayForm.submit()}
        okText="Mark as Delayed"
        cancelText="Cancel"
        confirmLoading={projectClientDelayLoading}
      >
        <Form
          form={projectClientDelayForm}
          layout="vertical"
          onFinish={handleProjectClientDelaySubmit}
        >
          <Form.Item
            name="projects"
            label="Select Projects to Delay"
            rules={[
              {
                required: true,
                message: "Please select at least one project to delay.",
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              placeholder="Search and select projects to delay"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {eligibleProjects.map((p) => (
                <Option key={p.id} value={p.id}>
                  {p.projectName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Production;
