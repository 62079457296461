// src/components/DisplayPhoneNumber.jsx

import React from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import default styles
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { StopOutlined } from "@ant-design/icons";

/**
 * DisplayPhoneNumber Component
 *
 * Props:
 * - phoneNumber (string): The phone number to display.
 * - defaultCountry (string): The default country code (e.g., 'us').
 * - containerClass (string): CSS class for the container.
 * - inputClass (string): CSS class for the input field.
 * - dropdownClass (string): CSS class for the dropdown (hidden since disabled).
 * - style (object): Inline styles for the container.
 * - inputStyle (object): Inline styles for the input field.
 *
 * Features:
 * - Displays phone numbers with country flags.
 * - Read-only display to prevent user interaction.
 * - Handles various phone number formats.
 */
const DisplayPhoneNumber = ({
  phoneNumber = "",
  defaultCountry = "us",
  containerClass = "displayOnlyPhone",
  inputClass = "displayOnlyPhoneInput",
  dropdownClass = "displayOnlyPhoneDropdown",
  style = {},
  inputStyle = {},
  ext = "",
}) => {
  /**
   * Formats the phone number to E.164 format if possible.
   * This ensures that the country flag is correctly detected and displayed.
   *
   * @param {string} number - The phone number string to format.
   * @returns {string} - The formatted phone number or the original string if invalid.
   */
  const formatPhoneNumber = (number) => {
    if (!number) return "";
    const parsedNumber = parsePhoneNumberFromString(
      number,
      defaultCountry.toUpperCase()
    );
    if (parsedNumber && parsedNumber.isValid()) {
      return parsedNumber.format("E.164"); // e.g., '+12812961570'
    }
    // If parsing fails, return the original number
    return number;
  };

    const formattedNumber = formatPhoneNumber(phoneNumber);
    
    if(!formattedNumber) return <StopOutlined style={{ color: "red" }} />;   

  return (
    <div style={{}}>
      <PhoneInput
        country={defaultCountry}
        value={formattedNumber}
        containerClass={containerClass}
        inputClass={inputClass}
        dropdownClass={dropdownClass}
        disableDropdown={true}
        disabled={true}
        containerStyle={{ width: "100%", ...style }}
        inputStyle={{
          width: "100%",
          border: "none",
          background: "none",
          cursor: "default",
          ...inputStyle,
        }}
        // Optional: Remove the flag dropdown since it's disabled
        disableCountryCode={false} // Show country code if available
        inputProps={{
          name: "displayOnlyPhone",
          required: false,
          autoFocus: false,
          // aria-label for accessibility
          "aria-label": "Phone Number",
        }}
      />
      {(ext && ext.length > 0) && <div style={{textAlign:"center", opacity:0.7, fontStyle:"italic"}}> ext. {ext}</div>}
    </div>
  );
};

DisplayPhoneNumber.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  defaultCountry: PropTypes.string,
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  dropdownClass: PropTypes.string,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
};

export default DisplayPhoneNumber;
