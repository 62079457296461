import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Button, Row, Col } from "antd";
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { TABLES, TRUCK_TYPES } from "../../constants";
import DeliveryRoute from "../Projects/DeliveryRoute";
import PlaceObjectAutocomplete from "../Google/PlaceObjectAutocomplete";
import {
  useProjects,
  useManufacturingLocations,
} from "../../Contexts/useSpecificData";

function AddOutboundShipmentModal({
  visible,
  onCancel,
  shipment, // existing shipment if editing, null if adding new
  existingShipments = [],
  onUpdateSuccess,
}) {
  const [form] = Form.useForm();
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedManufacturing, setSelectedManufacturing] = useState(null);
  const [destinationPlace, setDestinationPlace] = useState(null);
  const [tripDistance, setTripDistance] = useState(null);
  const [estimatedDays, setEstimatedDays] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: projects = [] } = useProjects();
  const { data: manufacturingLocations = [] } = useManufacturingLocations();

  // Sort projects alphabetically
  const sortedProjects = [...projects].sort((a, b) =>
    a.projectName.localeCompare(b.projectName)
  );

  useEffect(() => {
    if (shipment) {
      const {
        origin,
        destination,
        tripDistance,
        estimatedDays,
        projectId,
        truckType,
      } = shipment;

      setSelectedProject(projectId);
      setSelectedManufacturing(origin);
      setDestinationPlace({
        formatted_address: destination.address,
        geometry: {
          location: {
            lat: () => destination.latitude,
            lng: () => destination.longitude,
          },
        },
        place_id: destination.placeId,
      });
      setTripDistance(tripDistance);
      setEstimatedDays(estimatedDays);

      // Populate the form fields
      form.setFieldsValue({
        projectId,
        manufacturingLocation: origin?.id,
        destinationAddress: destination.address,
        truckType: truckType || undefined,
      });
    } else {
      // Reset for new shipment
      setSelectedProject(null);
      setSelectedManufacturing(null);
      setDestinationPlace(null);
      setTripDistance(null);
      setEstimatedDays(null);
      form.resetFields();
    }
  }, [shipment, form]);

  const handleProjectChange = (value) => {
    const selectedProject = projects.find((proj) => proj.id === value);
    setSelectedProject(value);

    // Auto-fill manufacturing location and address
    const defaultManufacturing = manufacturingLocations.find(
      (loc) => loc.id === selectedProject?.manufacturingLocation?.id
    );
    setSelectedManufacturing(defaultManufacturing);

    setDestinationPlace({
      formatted_address: selectedProject?.formattedAddress,
      geometry: {
        location: {
          lat: () => selectedProject?.latitude,
          lng: () => selectedProject?.longitude,
        },
      },
      place_id: selectedProject?.placeId,
    });

    form.setFieldsValue({
      manufacturingLocation: defaultManufacturing?.id,
      destinationAddress: selectedProject?.formattedAddress,
    });

    // Recalculate route when the project changes
    setTripDistance(null);
    setEstimatedDays(null);
  };

  const handleManufacturingChange = (value) => {
    const location = manufacturingLocations.find((loc) => loc.id === value);
    setSelectedManufacturing(location);
    setTripDistance(null);
    setEstimatedDays(null); // Force route recalculation
  };

  const handleDestinationChange = (place) => {
    if (!place) {
      setDestinationPlace(null);
      return;
    }

    if (typeof place === "object" && place.geometry) {
      setDestinationPlace(place);
    } else {
      setDestinationPlace(null);
    }
    setTripDistance(null);
    setEstimatedDays(null); // Force route recalculation
  };

  const handleRouteCalculated = (distance, days) => {
    setTripDistance(distance);
    setEstimatedDays(days);
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      // Prepare origin and destination objects
      const origin = {
        id: selectedManufacturing?.id,
        address: selectedManufacturing?.address,
        latitude: selectedManufacturing?.latitude,
        longitude: selectedManufacturing?.longitude,
        nickname: selectedManufacturing?.nickname,
        placeId: selectedManufacturing?.placeId,
      };

      const destination = {
        address: destinationPlace?.formatted_address || "",
        latitude: destinationPlace?.geometry?.location.lat(),
        longitude: destinationPlace?.geometry?.location.lng(),
        placeId: destinationPlace?.place_id,
      };

      const shipmentItem = {
        origin,
        destination,
        tripDistance,
        estimatedDays,
        manufacturingLocationId: values.manufacturingLocation,
        projectId: selectedProject,
        truckType: values.truckType, // <-- capture the truckType value
      };

      if (shipment) {
        // Update existing shipment
        await updateById(TABLES.OUTBOUND_SHIPMENTS, shipmentItem, shipment.id);
      } else {
        // Create new shipment
        const nextNumber = (existingShipments?.length || 0) + 1;
        shipmentItem.shipmentNumber = "OS-" + nextNumber;
        await addItemsToTable(TABLES.OUTBOUND_SHIPMENTS, shipmentItem);
      }

      setLoading(false);
      onCancel();
      onUpdateSuccess?.();
    } catch (error) {
      console.error("Error saving shipment:", error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={loading}
      width={800}
      title={shipment ? "Edit Outbound Shipment" : "Add Outbound Shipment"}
      footer={[
        <div style={{ marginTop: 16 }} key="footer-buttons">
          <Button
            key="cancel"
            onClick={onCancel}
            style={{ marginRight: 16 }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            key="save"
            type="primary"
            onClick={handleOk}
            loading={loading}
          >
            Save Shipment
          </Button>
        </div>,
      ]}
    >
      <Form form={form} layout="vertical">
        {/* Project Selection */}
        <Form.Item
          name="projectId"
          label="Project"
          rules={[{ required: true, message: "Select a project" }]}
        >
          <Select
            placeholder="Select a project"
            showSearch
            optionFilterProp="children"
            onChange={handleProjectChange}
            value={selectedProject}
          >
            {sortedProjects.map((project) => (
              <Select.Option key={project.id} value={project.id}>
                {project.projectName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* Row for Manufacturing Location and Truck Type */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="manufacturingLocation"
              label="Manufacturing Location"
              rules={[
                { required: true, message: "Select a manufacturing location" },
              ]}
            >
              <Select
                placeholder="Select manufacturing location"
                onChange={handleManufacturingChange}
                value={selectedManufacturing?.id}
              >
                {manufacturingLocations.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id}>
                    {loc.nickname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="truckType"
              label="Truck Type"
              rules={[{ required: true, message: "Select a truck type" }]}
            >
              <Select placeholder="Select a truck type">
                {TRUCK_TYPES.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Destination Address */}
        <Form.Item
          name="destinationAddress"
          label="Destination Address"
          rules={[{ required: true, message: "Enter a destination address" }]}
        >
          <PlaceObjectAutocomplete
            form={form}
            onChange={handleDestinationChange}
            value={destinationPlace?.formatted_address}
          />
        </Form.Item>
      </Form>

      {/* Delivery Route */}
      <DeliveryRoute
        key={`${selectedManufacturing?.id}-${destinationPlace?.place_id}`} // Force re-render
        originPlaceId={selectedManufacturing?.placeId || null}
        destinationPlaceId={destinationPlace?.place_id || null}
        onRouteCalculated={handleRouteCalculated}
      />
    </Modal>
  );
}

export default AddOutboundShipmentModal;
