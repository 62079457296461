import React, { useState, useMemo, useEffect } from "react";
import {
  List,
  Card,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Typography,
  Tag,
} from "antd";
import { StopOutlined, SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import dayjs from "dayjs";
import { getProjectName } from "../../Formatters/getProjectName";
import { Loader } from "../../Styled/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "@clerk/clerk-react";
import { COLORS } from "../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom"; // <-- Import this!


const { Search } = Input;
const { Title } = Typography;

const ITEMS_PER_LOAD = 20; // Number of items to load each time

function ReceivePurchase() {
  const [selectedPO, setSelectedPO] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [displayedPOs, setDisplayedPOs] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate(); // <-- For navigation

  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors, loading: vendorsLoading } = useVendors();
  const { data: projects } = useProjects();

  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress || "";

  // Determine if the user is a "sterling" user
  const isSterlingUser = useMemo(() => {
    return userEmail.toLowerCase().includes("sterling");
  }, [userEmail]);

  // Determine filter based on user role
  const filter = useMemo(() => {
    if (isSterlingUser) {
      return "6544 Warren";
    } else {
      return "All";
    }
  }, [isSterlingUser]);

  // Debounce the search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // 300ms debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Utility function to extract numerical part from poNumber for sorting
  const extractNumber = (poNumber) => {
    const match = poNumber.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  // Filter purchaseOrders based on filter and lines with qtyReceived < qtyOrdered
  const filteredPurchaseOrders = useMemo(() => {
    if (!purchaseOrders) return [];

    // First, filter POs that have at least one line with qtyReceived < qtyOrdered
    let filteredPOs = purchaseOrders.filter((po) =>
      po.lines.some((line) => line.qtyReceived < line.qtyOrdered)
    );

    // Then, apply shipToAddress filter if necessary
    if (filter !== "All") {
      filteredPOs = filteredPOs.filter((po) =>
        po.shipToAddress.toLowerCase().includes(filter.toLowerCase())
      );
    }

    // Apply search filter
    if (debouncedSearchTerm.trim() !== "") {
      const lowerSearch = debouncedSearchTerm.toLowerCase();
      filteredPOs = filteredPOs.filter(
        (po) =>
          po.poNumber.toLowerCase().includes(lowerSearch) ||
          getVendorName(po.vendor, vendors).toLowerCase().includes(lowerSearch) ||
          getProjectName(po.projectNumber, projects).toLowerCase().includes(lowerSearch)
      );
    }

    // Sort by poNumber descending
    filteredPOs.sort(
      (a, b) => extractNumber(b.poNumber) - extractNumber(a.poNumber)
    );

    return filteredPOs;
  }, [purchaseOrders, filter, debouncedSearchTerm, vendors]);

  // Initialize displayedPOs with the first set
  useEffect(() => {
    if (filteredPurchaseOrders.length > 0) {
      setDisplayedPOs(filteredPurchaseOrders.slice(0, ITEMS_PER_LOAD));
      setHasMore(filteredPurchaseOrders.length > ITEMS_PER_LOAD);
    } else {
      setDisplayedPOs([]);
      setHasMore(false);
    }
  }, [filteredPurchaseOrders]);

  // Function to load more POs
  const fetchMorePOs = () => {
    const currentLength = displayedPOs.length;
    const morePOs = filteredPurchaseOrders.slice(
      currentLength,
      currentLength + ITEMS_PER_LOAD
    );
    setDisplayedPOs([...displayedPOs, ...morePOs]);
    if (displayedPOs.length + morePOs.length >= filteredPurchaseOrders.length) {
      setHasMore(false);
    }
  };

  // Handle PO Selection (for Modal)
  const handleSelectPO = (po) => {
    setSelectedPO(po);
    setIsModalVisible(true);
  };

  // Handle Modal Close
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedPO(null);
  };

  // Go to Next Page, passing state
  const handleNextClick = () => {
    if (!selectedPO) return;
    // We'll navigate to the details route and pass "selectedPO" in state
    navigate(`/receiving/receive/purchase-orders/${selectedPO.id}`, {
      state: { selectedPO },
    });
  };

  // Handle Search Input Change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  if (poLoading || vendorsLoading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        boxSizing: "border-box",
      }}
    >
      {/* Sticky Search Bar */}
      <div
        style={{
          position: "sticky",
          top: 0,
          paddingBottom: "16px",
          zIndex: 1000,
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <Input
          placeholder="Search by PO Number or Vendor"
          allowClear
          prefix={<SearchOutlined />}
          size="large"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ width: "100%" }}
        />
      </div>

      {/* Infinite Scroll List */}
      <div
        id="scrollableDiv"
        style={{
          height: "calc(90vh - 120px)", // Adjust based on search bar height
          overflow: "auto",
          borderRadius: "4px",
        }}
      >
        <InfiniteScroll
          dataLength={displayedPOs.length}
          next={fetchMorePOs}
          hasMore={hasMore}
          loader={
            <div style={{ textAlign: "center", padding: "16px" }}>
              <Loader />
            </div>
          }
          endMessage={
            <div style={{ textAlign: "center", padding: "16px" }}>
              <b>No more purchase orders</b>
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={displayedPOs}
            renderItem={(po) => (
              <List.Item
                key={po.id}
                onClick={() => handleSelectPO(po)}
                style={{
                  border: "1px solid #f0f0f0",
                  borderRadius: "4px",
                  marginBottom: "8px",
                  padding: "12px",
                  cursor: "pointer",
                  background: "#ffffff",
                  transition: "background-color 0.3s, box-shadow 0.3s",

                  overflowY: "auto", // Let the List do the scrolling
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#fafafa")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#ffffff")
                }
              >
                <List.Item.Meta
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Tag style={{ fontSize: 16 }}>{po.poNumber}</Tag>
                        <Tag color="red" icon={<CalendarOutlined/>} style={{ fontSize: 14 }}>
                          REQUIRED: {po.requiredDate}
                        </Tag>
                        <Tag color="blue" style={{ fontSize: 14 }}>
                           {getProjectName(po.projectNumber, projects)}
                        </Tag>
                      </div>

                      <div
                        style={{
                          margin: 0,
                          fontWeight: 600,
                          fontSize: 14,
                          opacity: 0.8,
                          textAlign: "right",
                        }}
                      >
                        {getVendorName(po.vendor, vendors)}
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>

      {/* Modal to Display PO Details */}
      {selectedPO && (
        <Modal
          title={`Purchase Order Details - ${selectedPO.poNumber}`}
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={[
            <Button key="cancel" onClick={handleCloseModal}>
              Cancel
            </Button>,
            <Button
              key="next"
              type="primary"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              onClick={handleNextClick} // <-- Use our click handler
            >
              Next
            </Button>,
          ]}
        >
          <Card bordered={false}>
            <p>
              <strong>Address:</strong> {selectedPO.shipToAddress}
            </p>
            <p>
              <strong>Project:</strong>{" "}
              {getProjectName(selectedPO.projectNumber, projects)}
            </p>
            <p>
              <strong>Buyer:</strong> {selectedPO.buyer}
            </p>
            <p>
              <strong>Required Date:</strong>{" "}
              {dayjs(selectedPO.requiredDate).format("MM/DD/YYYY")}
            </p>
            <p>
              <strong>Notes:</strong>{" "}
              {selectedPO.notes || (
                <StopOutlined
                  style={{ color: COLORS.WARNING, marginLeft: 8 }}
                />
              )}
            </p>
          </Card>
        </Modal>
      )}
    </div>
  );
}

export default ReceivePurchase;
